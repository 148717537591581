/* eslint-disable no-console */
import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Sheet from "../components/Sheet/Sheet";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import Table from "../components/Table/Table";
import TableColumn from "../components/Table/TableColumn";
import TableRow from "../components/Table/TableRow";
import TableCell from "../components/Table/TableCell";
import Divider from "../components/Divider/Divider";
import Label from "../components/FormSet/Label";
import {
  BookTypeID,
  InterviewStatusLabels,
  MASTER_MANAGEMENT_OPTIONS,
  ModalityID,
  MriStatusID,
  MriStatusLabels,
  PatientInterviewStatusID,
  PatientTypeID,
  PetStatusID,
  PetStatusLabels,
  USER_EVALUATION,
  UserStatusID,
} from "../constants/common";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import useAdminUserDetailTabFetch from "../hooks/useAdminUserDetailTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  getInterviewTagState,
  getMriStatusTagState,
  getPetStatusTagState,
  handleSelectMainTab,
  handleSelectMasterInfo,
  useHandleSelectAdminUserDetailTab,
} from "../utils/utils";
import { ADMIN } from "../constants/pagePaths";
import useEvaluationDbActions from "../hooks/common/useEvaluationDbActions";
import usePatientInfoFetch from "../hooks/usePatientInfoFetch";
import { convertPatientInfo } from "../utils/convertDisplay";
import EventHistories from "../features/EventHistories";
import useNlc020DbActions from "../hooks/pages/NLC020/useNlc020DbActions";
import UserDetailHeader from "../features/UserDetailHeader";
import useNlc021NewDialog, {
  openNlc021NewDialog,
} from "../hooks/pages/NLC021/useNlc021NewDialog";
import NLC021 from "./NLC021";
import NLC015 from "./NLC015";
import { setInterviewId } from "../hooks/pages/NLC021/useNlc021Conditions";
import useNlc015Dialog, {
  openNlc015Dialog,
} from "../hooks/pages/NLC015/useNlc015Dialog";
import Chat from "../features/Chat";
import MedicalRecord from "../features/MedicalRecord";
import useUserDetailTabConditions from "../hooks/commonCondition/useUserDetailTabConditions";
import { openNlc028Dialog } from "../hooks/pages/NLC028/useNlc028Dialog";
import RadioButton from "../components/RadioButton/RadioButton";

// 面談の予約確認、詳細ボタン押下
const clickInterview = (id: string) => {
  setInterviewId(id);
  openNlc021NewDialog();
};

// MRIの詳細ボタン押下
const clickMriDetail = (id: string) => {
  openNlc015Dialog(id);
};

// FDG PETの詳細ボタン押下
const clickPetDetail = (id: string) => {
  openNlc028Dialog(id);
};

const _createTable = (bookInfo: PatientNlc020StateType) => {
  const { type, id, content, status, mriStatus, petStatus, hospital, date } =
    bookInfo;
  let contentStr = content;
  let statusStr = "";
  let buttonStr = "";
  let clickFunc = (_id: string) => {};
  let tagState = undefined as
    | "error"
    | "default"
    | "neutral"
    | "warning"
    | "success"
    | "information"
    | undefined;

  // 表示変換
  if (type === BookTypeID.INTERVIEW) {
    const currentStatus = status as keyof typeof InterviewStatusLabels;
    statusStr = InterviewStatusLabels[currentStatus];

    const isNewReservation =
      statusStr ===
      InterviewStatusLabels[PatientInterviewStatusID.NEW_RESERVATION];
    buttonStr = isNewReservation ? "予約確認" : "詳細";
    clickFunc = clickInterview;
    tagState = getInterviewTagState(statusStr);
  } else if (type === BookTypeID.MODALITY) {
    if (Number(content) === ModalityID.MRI) {
      const currentMriStatus = mriStatus as keyof typeof MriStatusLabels;
      const mriStatusStr = MriStatusLabels[currentMriStatus];
      statusStr = mriStatusStr;
      contentStr = "MRI検査";
      tagState = getMriStatusTagState(mriStatusStr);
      // MRIの結果が出た場合のみボタン表示
      if (
        [
          MriStatusID.DONE_AI_DIAGNOSING,
          MriStatusID.DONE_AI_DIAGNOSED,
          MriStatusID.DONE_DOCTOR_DIAGNOSED,
          MriStatusID.USER_REPORTED,
        ].includes(currentMriStatus)
      ) {
        buttonStr = "詳細";
        clickFunc = clickMriDetail;
      }
    }
    if (Number(content) === ModalityID.PET) {
      const currentPetStatus = petStatus as keyof typeof PetStatusLabels;
      const petStatusStr = PetStatusLabels[currentPetStatus];
      statusStr = petStatusStr;
      contentStr = "FDG PET検査";
      tagState = getPetStatusTagState(petStatusStr);
      // FDG PETの結果が出た場合のみボタン表示
      if (
        [PetStatusID.EXAMINED, PetStatusID.DIAGNOSED].includes(currentPetStatus)
      ) {
        buttonStr = "詳細";
        clickFunc = clickPetDetail;
      }
    }
  }

  return (
    <TableRow key={id}>
      <TableCell>{contentStr}</TableCell>
      <TableCell textAlign="left">{hospital ?? "-"}</TableCell>
      <TableCell>
        <Tag label={statusStr} showIcon={false} state={tagState} />
      </TableCell>
      <TableCell textAlign="left">{date ?? "-"}</TableCell>
      <TableCell>
        {buttonStr && (
          <Button type="sub" size="small" onClick={() => clickFunc(id)}>
            {buttonStr}
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

const NLC020 = () => {
  const activatorRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("3");

  const [isInterviewDialogOpen] = useNlc021NewDialog();
  const [isMriDialogOpen] = useNlc015Dialog();

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();
  const { userDetailTabError, userDetailTabItems } =
    useAdminUserDetailTabFetch();
  const { error: patientFetchError, patientInfo } = usePatientInfoFetch();
  const [{ userDetailTab }] = useUserDetailTabConditions();

  const {
    id,
    userId,
    name,
    birth,
    genderStr,
    tel,
    email,
    address,
    evaluation,
    patientTypeNum,
    patientTypeStr,
    statusNum,
    statusStr,
    orgName,
  } = convertPatientInfo(patientInfo);
  // ユーザ評価更新
  const { error: updateEvaluationError, handleUpdateEvaluation } =
    useEvaluationDbActions();
  const { books, fetchError } = useNlc020DbActions();

  // エラー処理
  useCheckErrorThrowError([
    fetchError,
    mainTabError,
    userDetailTabError,
    patientFetchError,
    updateEvaluationError,
  ]);

  useEffect(() => {
    setSelectedValue(evaluation);
  }, [evaluation]);

  const handleSelectAdminUserDetailTab = useHandleSelectAdminUserDetailTab();

  // ユーザ評価選択
  const handleChangeEvaluation = React.useCallback(
    (value: string) => {
      // ユーザ評価更新
      handleUpdateEvaluation(id, value);
      setSelectedValue(value);
    },
    [handleUpdateEvaluation, id],
  );

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={2}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-user__wrapper">
          <div className="admin-user__info">
            <LayoutBox direction="column" gap="2x">
              <UserDetailHeader
                patientInfo={patientInfo}
                backPath={ADMIN.NLC007}
              />
              <Sheet className="util-px-24 util-py-24">
                <LayoutBox direction="column" gap="4x">
                  <div className="admin-user__profile">
                    <LayoutBox direction="column" gap="2x">
                      <LayoutBox direction="column" fullWidth>
                        <LayoutBox align="center">
                          <span className="util-font-neutral--light font-component-chip">
                            {userId}
                          </span>
                          <Tag
                            showIcon={false}
                            label={patientTypeStr}
                            state={
                              patientTypeNum === PatientTypeID.MEMBER
                                ? "information"
                                : patientTypeNum === PatientTypeID.ORG
                                ? "success"
                                : patientTypeNum === PatientTypeID.INVITATION
                                ? "warning"
                                : "neutral"
                            }
                          />
                          <Tag
                            showIcon={false}
                            label={statusStr}
                            state={
                              statusNum === UserStatusID.ACTIVE ||
                              statusNum === UserStatusID.INVITATION
                                ? "success"
                                : statusNum === UserStatusID.CANCELLATION
                                ? "error"
                                : statusNum === UserStatusID.SUSPENDED
                                ? "warning"
                                : "neutral"
                            }
                          />
                        </LayoutBox>
                        <LayoutBox fullWidth align="center">
                          <div className="util-half-width">
                            <Text size="2xl" bold>
                              {name}
                            </Text>
                          </div>
                          {patientTypeNum !== PatientTypeID.INVITATION && (
                            <div className="util-half-width">
                              <LayoutBox align="center">
                                <Label base size="medium" width="120px">
                                  認知症リスク
                                  <br />
                                  評価
                                </Label>
                                <div className="util-flex util-flex--wrap util-flex--col-gap16">
                                  {USER_EVALUATION.map((item) => (
                                    <RadioButton
                                      key={item.value}
                                      name="userRatingRadioButton"
                                      value={item.value}
                                      checked={selectedValue === item.value}
                                      onSelect={handleChangeEvaluation}
                                    >
                                      {item.label}
                                    </RadioButton>
                                  ))}
                                </div>
                              </LayoutBox>
                            </div>
                          )}
                        </LayoutBox>
                      </LayoutBox>
                      <Divider margin="0px" />
                      <div className="util-full-width">
                        <LayoutBox direction="column">
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    生年月日
                                  </Label>
                                  {birth}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    性別
                                  </Label>
                                  {genderStr}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    電話番号
                                  </Label>
                                  {tel}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="stretch">
                                  <Label base size="medium" width="120px">
                                    メールアドレス
                                  </Label>
                                  <LayoutBox align="center" justify="center">
                                    <Text>{email}</Text>
                                  </LayoutBox>
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="stretch">
                                  <Label base size="medium" width="120px">
                                    住所1
                                  </Label>
                                  <LayoutBox align="center" justify="center">
                                    {address}
                                  </LayoutBox>
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    団体
                                  </Label>
                                  {orgName}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                  <Tabs
                    selectedTab={1}
                    tabs={userDetailTabItems}
                    size="medium"
                    bottomBorder
                    onClick={handleSelectAdminUserDetailTab}
                  />
                  <div className="util-full-width">
                    <LayoutBox direction="column" gap="3x">
                      <div className="util-full-width">
                        <LayoutBox direction="column" gap="2x">
                          <LayoutBox direction="column">
                            <Table
                              type="regular"
                              width="100%"
                              head={
                                <TableRow>
                                  <TableColumn width="300.5px" id="col-1">
                                    内容
                                  </TableColumn>
                                  <TableColumn width="300.5px" id="col-2">
                                    医療機関
                                  </TableColumn>
                                  <TableColumn width="300.5px" id="col-3">
                                    ステータス
                                  </TableColumn>
                                  <TableColumn width="300.5px" id="col-4">
                                    予定日
                                  </TableColumn>
                                  <TableColumn width="150.5px" id="col-5" />
                                </TableRow>
                              }
                              body={books.map(_createTable)}
                            />
                          </LayoutBox>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </div>
          {userDetailTab === 0 && <EventHistories />}
          {userDetailTab === 1 && <Chat />}
          {userDetailTab === 2 && <MedicalRecord />}
        </div>
      </div>
      {isInterviewDialogOpen && <NLC021 />}
      {isMriDialogOpen && <NLC015 />}
    </>
  );
};

export default NLC020;
