import React, { useEffect } from "react";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import FormSet from "../components/FormSet/FormSet";
import Divider from "../components/Divider/Divider";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import Input from "../components/Input/Input";
import DatePicker from "../components/DatePicker/DatePicker";
import DialogCustom from "../components/Dialog/DialogCustom";
import TimePicker from "../components/TimePicker/TimePicker";
import { convertMib002Table } from "../utils/convertDisplay";
import {
  BULLET_LIST_ITEMS,
  HospitalReservationStatusID,
  ModalityID,
  MRI_PRECAUTIONS_ITEMS,
  PET_PRECAUTIONS_ITEMS,
} from "../constants/common";
import useMib002DbActions from "../hooks/pages/MIB002/useMib002DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import MIB004 from "./MIB004";
import {
  formatDate,
  generateTimeOptions,
  getHospitalStatusTagEmphasis,
  getHospitalStatusTagState,
  getPrecautionsColor,
  getPrecautionsOpacity,
} from "../utils/utils";
import useHospitalTimeIntervalFetch from "../hooks/useHospitalTimeIntervalFetch";
import Dropdown from "../components/Dropdown/Dropdown";
import RadioButton from "../components/RadioButton/RadioButton";
import Accordion from "../components/Accordion/Accordion";
import Icon from "../components/Icon/Icon";
import BulletList from "../components/List/BulletList";
import useMib002DBFetch from "../hooks/pages/MIB002/useMib002DBFetch";

type MIB002Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectReservation: Mib001TableType;
  reservationArray: Mib001TableType[];
};

type RadioButtonItem = {
  label: string;
  value: string;
};

// その他の時間制御のため、セット
const initialDate = new Date(1000, 0, 1);

const MIB002: React.FC<MIB002Props> = ({
  isOpen,
  setIsOpen,
  selectReservation,
  reservationArray,
}) => {
  const [reservation, setReservation] =
    useStateCustomObj<Mib001TableType>(selectReservation);
  const [formattedCandidateBookDates, setFormattedCandidateBookDates] =
    React.useState<RadioButtonItem[]>([]);
  const [otherCandidateBookDates, setOtherCandidateBookDates] = React.useState<
    RadioButtonItem[]
  >([]);
  const [selectedValue, setSelectedValue] = React.useState(
    selectReservation.candidateBookDates.first || "",
  );
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(
    undefined,
  );
  const [timeStr, setTimeStr] = React.useState("");
  const [time, setTime] = React.useState(initialDate);
  const [note, setNote] = React.useState<string>("");
  const [isCancelDialog, setIsCancelDialog] = React.useState(false);
  const {
    handleUpdateNg,
    handleUpdateConfirm,
    handleUpdateCancel,
    actionError,
  } = useMib002DbActions();
  const { hospitalInfo, error: hospitalError } = useHospitalTimeIntervalFetch();
  const { fetchResult } = useMib002DBFetch(reservation.id);
  const [index, setIndex] = React.useState(0);
  const [timeItems, setTimeItems] = React.useState<
    { label: string; value: string }[]
  >([]);
  const [selectedTime, setSelectedTime] = React.useState("");

  useCheckErrorThrowError([actionError, hospitalError]);

  useEffect(() => {
    const convertResult = convertMib002Table(
      reservation.fixBookDate,
      reservation.statusNum,
      hospitalInfo,
      reservation.modalityNum,
      reservation.candidateBookDates,
    );
    setFormattedCandidateBookDates(convertResult.convertCandidateDates);
    setOtherCandidateBookDates([convertResult.otherCandidateDates]);

    // 内容
    if (reservation && reservation.note) {
      setNote(reservation.note);
    } else {
      setNote("");
    }

    // 時間選択肢設定
    if (reservation && reservation.candidateBookDates.first) {
      setTimeItems(
        generateTimeOptions(
          new Date(reservation.candidateBookDates.first),
          4,
          15,
        ),
      );
    }

    if (reservation && reservation.fixBookDate) {
      // 元の候補日の文字列に戻す
      const originSelectDateStr = `${reservation.fixBookDate.substring(
        0,
        reservation.fixBookDate.length - 2,
      )}00`;

      if (convertResult.isDateInCandidateDates) {
        setSelectedValue(originSelectDateStr);
        setSelectedTime(reservation.fixBookDate.split(" ")[1]);
        setTimeItems(generateTimeOptions(new Date(originSelectDateStr), 4, 15));
      } else {
        // その他の場合
        setSelectedValue("other");
        setSelectedDate(reservation.fixBookDateOrg);
        if (reservation.fixBookDateOrg) {
          const hours = reservation.fixBookDateOrg
            .getHours()
            .toString()
            .padStart(2, "0");
          const minutes = reservation.fixBookDateOrg
            .getMinutes()
            .toString()
            .padStart(2, "0");
          setTimeStr(`${hours}:${minutes}`);
        }
      }
    }
    // 何番目のデータか
    const num = reservationArray.findIndex(
      (item) => item.id === reservation.id,
    );
    setIndex(num);
  }, [reservation, hospitalInfo, reservationArray]);

  // 次へボタン
  const handleNextReservation = () => {
    setIndex((prevIndex) => prevIndex + 1);
    setReservation(reservationArray[index + 1]);
  };

  // 前へボタン
  const handleBeforeReservation = () => {
    setIndex((prevIndex) => prevIndex - 1);
    setReservation(reservationArray[index - 1]);
  };

  // 閉じる、キャンセルボタン押下
  const handleCloseButton = React.useCallback(
    () => setIsOpen(false),
    [setIsOpen],
  );

  // 内容
  const handleNoteChange = (value: string) => {
    setNote(value);
  };

  // その他 日付選択
  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  // その他 時間選択
  const onChangeTime = (date: Date) => {
    setTime(date);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    setTimeStr(`${hours}:${minutes}`);
  };

  // ラジオボタン選択
  const handleRadioChange = React.useCallback((value: string) => {
    setSelectedValue(value);
    setSelectedTime("");
    // 候補日 時間選択肢設定
    setTimeItems(generateTimeOptions(new Date(value), 4, 15));
  }, []);

  // 候補日 時間選択
  const handleSelectTime = (value: string) => {
    setSelectedTime(value);
  };

  // 全候補日NG
  const handleNg = () => {
    handleUpdateNg(reservation.id, note);
    setIsOpen(false);
  };

  // 予約確定
  const handleConfirm = () => {
    // 登録する予約時間を作成
    let fixDateStartStr = "";
    if (selectedValue === "other") {
      // その他の処理
      const formatDateStr = formatDate(selectedDate);
      fixDateStartStr = `${formatDateStr} ${timeStr}`;
    } else {
      fixDateStartStr = `${selectedValue.split(" ")[0]} ${selectedTime}`;
    }

    handleUpdateConfirm(
      reservation.id,
      note,
      fixDateStartStr,
      reservation.modalityNum,
      hospitalInfo,
      setIsOpen,
    );
  };

  // 予約取り下げ
  const handleCancel = () => {
    handleUpdateCancel(
      reservation.id,
      note,
      reservation.patientId,
      reservation.modalityNum,
      () => setIsCancelDialog(true),
    );
  };

  // 予約後のステータス
  const isAfterReservation = [
    HospitalReservationStatusID.CONFIRM,
    HospitalReservationStatusID.APPLICATION_FOR_WITHDRAWAL,
    HospitalReservationStatusID.WITHDRAWAL,
  ].includes(reservation.statusNum);

  // 予約確定ボタン非活性制御
  const isReservationButtonDisabled = () => {
    if (selectedValue === "other") {
      return !(selectedDate && timeStr);
    }

    return !(selectedValue && selectedTime);
  };

  // MRI注意事項があるか
  const hasMriPrecautions = fetchResult?.mri_precautions
    ? fetchResult?.mri_precautions?.length > 0
    : false;

  // PET注意事項があるか
  const hasPetPrecautions = fetchResult?.pet_precautions
    ? fetchResult?.pet_precautions?.length > 0
    : false;

  return (
    <DialogCustom
      title="予約確認"
      open={isOpen}
      size="large"
      height="840px"
      closeDialog={handleCloseButton}
      headerRight={
        <LayoutBox fullWidth align="center">
          <Button
            type="secondary"
            iconPosition="left"
            icon="arrow_back"
            disabled={index === 0}
            onClick={handleBeforeReservation}
          >
            前へ
          </Button>
          <Button
            type="secondary"
            iconPosition="right"
            icon="arrow_forward"
            disabled={reservationArray.length - 1 === index}
            onClick={handleNextReservation}
          >
            次へ
          </Button>
        </LayoutBox>
      }
      footerRight={
        <LayoutBox>
          <Button
            type="sub"
            color="neutral"
            size="large"
            onClick={handleCloseButton}
          >
            キャンセル
          </Button>
          {reservation.statusNum === HospitalReservationStatusID.NEW && (
            <Button
              type="secondary"
              color="danger"
              size="large"
              onClick={handleNg}
            >
              全候補日NG
            </Button>
          )}
          {reservation.statusNum === HospitalReservationStatusID.NEW && (
            <Button
              size="large"
              onClick={handleConfirm}
              disabled={isReservationButtonDisabled()}
            >
              予約確定
            </Button>
          )}
          {reservation.statusNum === HospitalReservationStatusID.CONFIRM && (
            <Button
              type="secondary"
              color="danger"
              size="large"
              onClick={handleCancel}
            >
              予約取り下げ
            </Button>
          )}
        </LayoutBox>
      }
    >
      <Sheet type="border-blue" padding="16px 24px">
        <LayoutBox direction="column" gap="2x">
          <LayoutBox direction="column" gap="1x">
            <Tag
              label={reservation.status}
              showIcon={false}
              state={getHospitalStatusTagState(reservation.statusNum)}
              emphasis={getHospitalStatusTagEmphasis(reservation.statusNum)}
            />
            <Text size="2xl" bold lineHeight="150%">
              {reservation.patientName}
            </Text>
          </LayoutBox>
          <LayoutBox direction="column" fullWidth>
            <LayoutBox fullWidth>
              <FormSet label="申込日" labelWidth="120px" base>
                <Text width="210px">{reservation.createdAt}</Text>
              </FormSet>
              <FormSet label="内容" labelWidth="120px" base>
                <Text width="210px">{reservation.modalityStr}</Text>
              </FormSet>
            </LayoutBox>
            <LayoutBox fullWidth>
              <FormSet label="生年月日" labelWidth="120px" base>
                <Text width="210px">{reservation.patientBirthday}</Text>
              </FormSet>
              <FormSet label="性別" labelWidth="120px" base>
                <Text width="210px">{reservation.patientGender}</Text>
              </FormSet>
            </LayoutBox>
            <LayoutBox fullWidth>
              <FormSet label="電話番号" labelWidth="120px" base>
                <Text width="210px">{reservation.patientTel}</Text>
              </FormSet>
              <FormSet label="メールアドレス" labelWidth="120px" base>
                <Text width="210px">{reservation.patientEmail}</Text>
              </FormSet>
            </LayoutBox>
          </LayoutBox>
        </LayoutBox>
      </Sheet>
      <div className="util-mt-32 util-full-width">
        <LayoutBox gap="3x" align="stretch">
          <LayoutBox gap="3x" direction="column">
            {formattedCandidateBookDates?.map((item, index) => (
              <LayoutBox key={index} direction="column">
                <RadioButton
                  {...item}
                  name="radio1"
                  onSelect={handleRadioChange}
                  checked={selectedValue === item.value}
                  key={`radio1-${item.value}`}
                  withBorder
                  width="367px"
                >
                  {item.label}
                </RadioButton>
                {selectedValue === item.value && (
                  <LayoutBox align="center">
                    {isAfterReservation ? (
                      <Text>{selectedTime}〜</Text>
                    ) : (
                      <>
                        <Dropdown
                          width="122px"
                          items={timeItems}
                          value={selectedTime}
                          onChange={handleSelectTime}
                        />
                        <Text>〜</Text>
                      </>
                    )}
                  </LayoutBox>
                )}
              </LayoutBox>
            ))}
            <div className="util-mt-16">
              <RadioButtonGroup
                name="radio"
                items={otherCandidateBookDates}
                onChange={handleRadioChange}
                selectedValue={selectedValue}
                withBorder
                width="367px"
              />
            </div>
            <LayoutBox direction="row" align="center" fullWidth gap="2x">
              <div>
                {isAfterReservation && selectedValue === "other" ? (
                  <Text>{formatDate(selectedDate)}</Text>
                ) : (
                  <DatePicker
                    size="default"
                    selectedDate={selectedDate}
                    onChangeDate={handleDateChange}
                    disabled={selectedValue !== "other"}
                  />
                )}
              </div>

              {isAfterReservation && selectedValue === "other" ? (
                <Text>{timeStr}〜</Text>
              ) : (
                <TimePicker
                  type="hoursOnly"
                  size="default"
                  defaultHour1="00:00"
                  value={time}
                  onChange={onChangeTime}
                  showButton={false}
                  dropdownWidth="115px"
                  disabled={selectedValue !== "other"}
                />
              )}
            </LayoutBox>
            <FormSet label="内容" vertical>
              {isAfterReservation ? (
                <Text>{note}</Text>
              ) : (
                <Input
                  multiLine
                  width="100%"
                  placeholder="内容"
                  value={note}
                  onChange={handleNoteChange}
                />
              )}
            </FormSet>
          </LayoutBox>
          <Divider vertical width="1px" />
          <LayoutBox direction="row" fullWidth>
            {reservation.modalityNum === ModalityID.MRI && (
              <LayoutBox gap="3x" direction="column" fullWidth>
                <LayoutBox gap="2x" fullWidth direction="column">
                  <Sheet
                    type="header"
                    className="util-px-12 util-py-12"
                    padding="4px 8px"
                  >
                    <Text bold size="xl">
                      MRI検査の禁忌事項について
                    </Text>
                  </Sheet>

                  <LayoutBox direction="column" gap="1x" fullWidth>
                    <div className="util-mt-10 util-mb-10">
                      <Text>
                        {fetchResult?.contraindications !== undefined
                          ? fetchResult.contraindications
                            ? "当てはまらない"
                            : "当てはまる"
                          : "当てはまらない"}
                      </Text>
                    </div>
                    <Accordion title="内容">
                      <LayoutBox direction="column" gap="2x">
                        <LayoutBox direction="column" gap="none">
                          <Text color="error">
                            以下に当てはまる方はMRI検査を受検できません。
                            <br />
                            該当する項目がないことをご確認ください。
                          </Text>
                        </LayoutBox>
                        <Sheet
                          type="border-blue"
                          borderRadius="8px"
                          padding="16px"
                          borderWidth="1px"
                        >
                          <BulletList
                            title="体内に下記の医療機器がある方"
                            items={BULLET_LIST_ITEMS}
                            color="blue"
                          />
                        </Sheet>
                      </LayoutBox>
                    </Accordion>
                  </LayoutBox>
                </LayoutBox>
                <LayoutBox gap="2x" fullWidth direction="column">
                  <Sheet
                    type="header"
                    className="util-px-12 util-py-12"
                    padding="4px 8px"
                  >
                    <Text bold size="xl">
                      MRI検査の注意事項について
                    </Text>
                  </Sheet>
                  <LayoutBox direction="column" gap="1x">
                    {MRI_PRECAUTIONS_ITEMS.map((text, index) => {
                      const [mainText, subText] = text.split(" ");

                      return (
                        <LayoutBox
                          key={index}
                          gap="1x"
                          align="start"
                          opacity={getPrecautionsOpacity(
                            index,
                            fetchResult?.mri_precautions || [],
                          )}
                        >
                          <div className="util-py-2">
                            <Icon
                              icon="check"
                              size="xs"
                              color={getPrecautionsColor(
                                index,
                                fetchResult?.mri_precautions || [],
                              )}
                            />
                          </div>
                          <LayoutBox direction="column" gap="1/2x">
                            <Text>{mainText}</Text>
                            {subText && <Text>{subText}</Text>}
                          </LayoutBox>
                        </LayoutBox>
                      );
                    })}
                    <LayoutBox
                      gap="1x"
                      align="start"
                      opacity={hasMriPrecautions ? 1 : 0.4}
                    >
                      <div className="util-py-2">
                        <Icon
                          icon="check"
                          size="xs"
                          color={hasMriPrecautions ? "#096AE2" : "#BFC6CD"}
                        />
                      </div>
                      <Text>
                        上記に当てはまる項目がある場合、医療機関の判断により受検できない可能性があることを承諾する
                      </Text>
                    </LayoutBox>
                    <LayoutBox
                      gap="1x"
                      align="start"
                      opacity={hasMriPrecautions ? 0.4 : 1}
                    >
                      <div className="util-py-2">
                        <Icon
                          icon="check"
                          size="xs"
                          color={hasMriPrecautions ? "#BFC6CD" : "#096AE2"}
                        />
                      </div>
                      <Text>上記に当てはまる項目はない</Text>
                    </LayoutBox>
                  </LayoutBox>
                </LayoutBox>
              </LayoutBox>
            )}
            {reservation.modalityNum === ModalityID.PET && (
              <LayoutBox gap="2x" fullWidth direction="column">
                <Sheet
                  type="header"
                  className="util-px-12 util-py-12"
                  padding="4px 8px"
                >
                  <Text bold size="xl">
                    PET検査の注意事項について
                  </Text>
                </Sheet>
                <LayoutBox direction="column" gap="1x">
                  {PET_PRECAUTIONS_ITEMS.map((text, index) => {
                    const [mainText, subText] = text.split(" ");

                    return (
                      <LayoutBox
                        key={index}
                        gap="1x"
                        align="start"
                        opacity={getPrecautionsOpacity(
                          index,
                          fetchResult?.pet_precautions || [],
                        )}
                      >
                        <div className="util-py-2">
                          <Icon
                            icon="check"
                            size="xs"
                            color={getPrecautionsColor(
                              index,
                              fetchResult?.pet_precautions || [],
                            )}
                          />
                        </div>
                        <LayoutBox direction="column" gap="1/2x">
                          <Text>{mainText}</Text>
                          {subText && <Text>{subText}</Text>}
                        </LayoutBox>
                      </LayoutBox>
                    );
                  })}
                  <LayoutBox
                    gap="1x"
                    align="start"
                    opacity={hasPetPrecautions ? 1 : 0.4}
                  >
                    <div className="util-py-2">
                      <Icon
                        icon="check"
                        size="xs"
                        color={hasPetPrecautions ? "#096AE2" : "#BFC6CD"}
                      />
                    </div>
                    <Text>
                      上記に当てはまる項目がある場合、医療機関の判断により受検できない可能性があることを承諾する
                    </Text>
                  </LayoutBox>
                  <LayoutBox
                    gap="1x"
                    align="start"
                    opacity={hasPetPrecautions ? 0.4 : 1}
                  >
                    <div className="util-py-2">
                      <Icon
                        icon="check"
                        size="xs"
                        color={hasPetPrecautions ? "#BFC6CD" : "#096AE2"}
                      />
                    </div>
                    <Text>上記に当てはまる項目はない</Text>
                  </LayoutBox>
                </LayoutBox>
              </LayoutBox>
            )}
          </LayoutBox>
        </LayoutBox>
      </div>
      {isCancelDialog && (
        <MIB004
          isOpen={isCancelDialog}
          setIsOpen={setIsCancelDialog}
          setIsParentOpen={setIsOpen}
          name={reservation.patientName}
          tel={reservation.patientTel}
        />
      )}
    </DialogCustom>
  );
};
export default MIB002;
