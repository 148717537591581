import React, { useEffect } from "react";
import Button from "../components/Button/Button";
import Checkbox from "../components/Checkbox/Checkbox";
import FormSet from "../components/FormSet/FormSet";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Icon from "../components/Icon/Icon";
import Input from "../components/Input/Input";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import MenuList from "../components/MenuList/MenuList";
import Pagination from "../components/Pagination/Pagination";
import Popover from "../components/Popover/Popover";
import Sheet from "../components/Sheet/Sheet";
import Tabs from "../components/Tab/Tabs";
import Table from "../components/Table/Table";
import TableCell from "../components/Table/TableCell";
import TableColumn from "../components/Table/TableColumn";
import TableRow from "../components/Table/TableRow";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import {
  downloadCsvNli021,
  handleSelectMainTab,
  handleSelectMasterInfo,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  INIT_PAGE,
  INIT_SEARCH_CRITERIA,
  MASTER_MANAGEMENT_OPTIONS,
  UserStatusID,
} from "../constants/common";
import {
  useNavigateWithBack,
  useNavigateWithUrl,
} from "../hooks/base/usePageTransitionCustom";
import { ADMIN } from "../constants/pagePaths";
import useOneOrganizationFetch from "../hooks/common/useOneOrganizationFetch";
import useNli020Dialog, {
  openNli020Dialog,
} from "../hooks/pages/NLI020/useNli020Dialog";
import NLI020 from "./NLI020";
import {
  _convertToCurrency,
  convertNli021Table,
} from "../utils/convertDisplay";
import { setPage } from "../hooks/component/pagination/usePagination";
import useNli021Conditions from "../hooks/pages/NLI021/useNli021Conditions";
import { setId } from "../hooks/common/useId";
import InformationDialog from "../components/Dialog/InformationDialog";
import useNli021Dialog, {
  closeNli021Dialog,
  openNli021Dialog,
} from "../hooks/pages/NLI021/useNli021Dialog";
import useNli021DbFetch from "../hooks/pages/NLI021/useNli021DbFetch";
import useNli021DbActions from "../hooks/pages/NLI021/useNli021DbActions";

const NLI021 = () => {
  const [isDeleteDialogOpen] = useNli021Dialog();
  const [users, setUsers] = React.useState<Nli021TableType[]>([]);
  const [conditions, setConditions] = useNli021Conditions();
  const [sortExamples, setSortExample] = React.useState(conditions.sort);
  const [searchCriteria, setSearchCriteria] = React.useState(conditions.search);
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    conditions.search,
  );
  const [isCheckAllUserIds, setIsCheckAllUserIds] = React.useState(false);
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const [isNli020Open] = useNli020Dialog();
  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();
  const { fetchResult: organization, fetchError: organizationError } =
    useOneOrganizationFetch();
  const { fetchUserResult, fetchError } = useNli021DbFetch({
    sortExamples,
    submittedSearchCriteria,
  });
  const { deleteOrganization, actionError } = useNli021DbActions();

  // エラー処理
  useCheckErrorThrowError([
    mainTabError,
    organizationError,
    fetchError,
    actionError,
  ]);

  useEffect(() => {
    // データ取得時に表示用に変換
    const convertResult = convertNli021Table(fetchUserResult);
    setUsers(convertResult);
  }, [fetchUserResult, setUsers]);

  // 戻るボタン押下
  const handleGoBack = useNavigateWithBack(ADMIN.NLI019);

  const handleChangeName = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, name: value }));
  };

  const handleChangePhoneNum = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, phoneNum: value }));
  };

  const handleChangeEmail = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, email: value }));
  };

  // 検索ボタン押下
  const handleSearchUsers = () => {
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // クリアボタン押下
  const handleResetSearchCriteria = () =>
    setSearchCriteria(INIT_SEARCH_CRITERIA.NLI021);

  const handleDownloadCSV = () => {
    downloadCsvNli021(users, window.location.pathname);
  };

  // 詳細ボタン押下
  const navigateWithUrl = useNavigateWithUrl();
  const navigateToDetail = (id: string) => {
    setConditions({
      search: submittedSearchCriteria,
      sort: sortExamples,
    });
    setId(id);
    navigateWithUrl(ADMIN.NLC008);
  };

  // 団体を削除ボタン
  const handleDeleteButton = () => {
    deleteOrganization();
  };

  const handleCheckUserId = (checked: boolean, userId: string) => {
    const newUsers = users.map((user) => {
      if (user.id === userId) {
        return { ...user, checked };
      }

      return user;
    });

    setUsers(newUsers);

    setIsCheckAllUserIds(newUsers.every((user) => user.checked));
  };

  const handleCheckIsAllUserIds = (checked: boolean) => {
    setIsCheckAllUserIds(checked);

    const newUsers = users.map((user) => ({
      ...user,
      checked,
    }));

    setUsers(newUsers);
  };

  const isCheckEachId = React.useMemo(
    () => users.some((user) => user.checked === true),
    [users],
  );

  const isCheckAllId = React.useMemo(
    () => users.every((user) => user.checked === true),
    [users],
  );

  const isIndeterminate = React.useMemo(
    () =>
      (!isCheckAllUserIds && isCheckEachId) ||
      (isCheckAllUserIds && !isCheckAllId),
    [isCheckAllUserIds, isCheckEachId, isCheckAllId],
  );

  const onClickSort = (sortDirection: "asc" | "desc" | "", id: string) => {
    const newSortExamples = sortExamples.map((sortExample) => {
      if (sortExample.id === id) {
        return { ...sortExample, id, sortDirection };
      }

      return { ...sortExample, sortDirection: "" };
    });

    setSortExample(newSortExamples);
  };

  const handleChangeCheckItem = (checked: boolean, checkedItem: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      status: {
        ...searchCriteria.status,
        [checkedItem]: checked,
      },
    }));
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab="none"
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <div className="util-mt-8 util-mb-8">
              <Text size="2xl" bold>
                マスタ管理
              </Text>
            </div>
            <Sheet className="util-px-24 util-py-24">
              <Button
                type="sub"
                icon="arrow_back"
                width="112px"
                onClick={handleGoBack}
              >
                一覧へ戻る
              </Button>
              <Sheet
                padding=""
                type="border-blue"
                className="util-mt-16 util-px-20 util-py-16"
              >
                <LayoutBox direction="column" gap="2x">
                  <LayoutBox direction="column" gap="2x" fullWidth>
                    <LayoutBox align="center" justify="between" fullWidth>
                      <Text size="2xl" bold>
                        {organization?.organization_name}
                      </Text>
                      <div className="util-mr-4">
                        <LayoutBox>
                          <Button
                            type="secondary"
                            icon="delete"
                            color="danger"
                            width="112px"
                            disabled={users.length > 0}
                            onClick={openNli021Dialog}
                          >
                            団体を削除
                          </Button>
                          <Button
                            type="secondary"
                            icon="edit"
                            width="154px"
                            onClick={openNli020Dialog}
                          >
                            団体情報を編集
                          </Button>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                    <LayoutBox direction="column" fullWidth gap="3x">
                      <LayoutBox fullWidth gap="3x">
                        <FormSet
                          label="電話番号"
                          labelWidth="120px"
                          base
                          labelSize="medium"
                          formVertical={false}
                          flex="1 0"
                          maxWidth="576px"
                        >
                          <Text>{organization?.tel}</Text>
                        </FormSet>

                        <FormSet
                          label="住所"
                          labelWidth="120px"
                          base
                          labelSize="medium"
                          formVertical={false}
                          fullWidth
                          flex="1"
                        >
                          <Text>{organization?.address}</Text>
                        </FormSet>
                      </LayoutBox>
                      <LayoutBox gap="1x" direction="column" fullWidth>
                        <Text size="large" bold>
                          キャンペーン
                        </Text>
                        <LayoutBox gap="3x" fullWidth>
                          <FormSet
                            label="キャンペーンコード"
                            labelWidth="120px"
                            base
                            labelSize="medium"
                            formVertical={false}
                            minWidth="576px"
                            flex="1 0"
                          >
                            <Text>{organization?.campaign_code}</Text>
                          </FormSet>

                          <FormSet
                            label="定率/定額"
                            labelWidth="120px"
                            base
                            labelSize="medium"
                            formVertical={false}
                          >
                            <Text>
                              {organization?.campaign_code ? "定額" : ""}
                            </Text>
                          </FormSet>
                          <FormSet
                            label="期間"
                            labelWidth="120px"
                            base
                            labelSize="medium"
                            formVertical={false}
                          >
                            <Text>
                              {organization?.term
                                ? `${organization?.term}ヶ月`
                                : ""}
                            </Text>
                          </FormSet>
                        </LayoutBox>
                        <LayoutBox gap="3x" fullWidth>
                          <FormSet
                            label="キャンペーン名"
                            labelWidth="120px"
                            base
                            labelSize="medium"
                            formVertical={false}
                            minWidth="576px"
                            flex="1 0"
                          >
                            <Text>{organization?.campaign_name}</Text>
                          </FormSet>

                          <FormSet
                            label="割引額"
                            labelWidth="120px"
                            base
                            labelSize="medium"
                            formVertical={false}
                          >
                            <Text>
                              {organization?.discount_price
                                ? _convertToCurrency(
                                    organization?.discount_price ?? 0,
                                  )
                                : ""}
                            </Text>
                          </FormSet>

                          <FormSet
                            label="利用上限"
                            labelWidth="120px"
                            base
                            labelSize="medium"
                            formVertical={false}
                          >
                            <Text>
                              {organization?.limit
                                ? `${organization?.limit ?? 0}人`
                                : ""}
                            </Text>
                          </FormSet>
                        </LayoutBox>
                      </LayoutBox>
                    </LayoutBox>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
              <Sheet type="gray" className="util-mt-32 util-px-24 util-py-24">
                <LayoutBox direction="column">
                  <LayoutBox
                    align="center"
                    justify="around"
                    gap="3x"
                    fullWidth
                    wrap
                  >
                    <FormSet
                      label="お客様名"
                      labelWidth="96px"
                      base
                      flex="1"
                      minWidth="576px"
                    >
                      <Input
                        value={searchCriteria.name}
                        width="100%"
                        placeholder="お客様名"
                        onChange={handleChangeName}
                      />
                    </FormSet>
                    <FormSet
                      label="メールアドレス"
                      labelWidth="96px"
                      base
                      flex="1"
                      maxWidth="576px"
                    >
                      <Input
                        value={searchCriteria.email}
                        placeholder="メールアドレス"
                        width="100%"
                        onChange={handleChangeEmail}
                      />
                    </FormSet>
                    <FormSet
                      label="電話番号"
                      labelWidth="96px"
                      base
                      flex="1"
                      maxWidth="576px"
                    >
                      <Input
                        value={searchCriteria.phoneNum}
                        placeholder="電話番号"
                        width="100%"
                        onChange={handleChangePhoneNum}
                      />
                    </FormSet>
                  </LayoutBox>
                  <LayoutBox align="center" justify="start" gap="3x" fullWidth>
                    <FormSet
                      label="登録状況"
                      labelWidth="96px"
                      base
                      formVertical={false}
                      flex="2 1 24px"
                    >
                      <LayoutBox align="center" justify="start" gap="2x">
                        <Checkbox
                          label="アクティブ"
                          checked={searchCriteria.status.isActive}
                          onChecked={(checked) =>
                            handleChangeCheckItem(checked, "isActive")
                          }
                        />
                        <Checkbox
                          label="解約"
                          checked={searchCriteria.status.isCancel}
                          onChecked={(checked) =>
                            handleChangeCheckItem(checked, "isCancel")
                          }
                        />
                        <Checkbox
                          label="メール認証中"
                          checked={searchCriteria.status.isVerifying}
                          onChecked={(checked) =>
                            handleChangeCheckItem(checked, "isVerifying")
                          }
                        />
                      </LayoutBox>
                    </FormSet>
                  </LayoutBox>
                </LayoutBox>
                <div className="util-mt-16">
                  <LayoutBox align="center" justify="end">
                    <Button
                      type="sub"
                      color="neutral"
                      size="large"
                      onClick={handleResetSearchCriteria}
                    >
                      クリア
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      onClick={handleSearchUsers}
                    >
                      検索
                    </Button>
                  </LayoutBox>
                </div>
              </Sheet>
              <LayoutBox direction="column" gap="3x" justify="end">
                <div className="util-full-width util-mt-24">
                  <div className="util-full-width">
                    <LayoutBox align="center" justify="end">
                      <Button
                        type="secondary"
                        size="medium"
                        icon="file_download"
                        onClick={handleDownloadCSV}
                      >
                        CSVダウンロード
                      </Button>
                    </LayoutBox>
                  </div>
                </div>
                <Table
                  type="condensed"
                  fixedLayout
                  width="100%"
                  head={
                    <TableRow>
                      <TableColumn
                        width="146px"
                        id="col-1"
                        onClickSort={onClickSort}
                      >
                        <LayoutBox align="center" justify="start">
                          <Checkbox
                            checked={isCheckAllUserIds}
                            label="ID"
                            indeterminate={isIndeterminate}
                            onChecked={(checked) =>
                              handleCheckIsAllUserIds(checked)
                            }
                          />
                        </LayoutBox>
                      </TableColumn>
                      <TableColumn
                        canSort
                        sortedDirection={sortExamples[0].sortDirection}
                        id="col-2"
                        onClickSort={onClickSort}
                      >
                        お客様名
                      </TableColumn>

                      <TableColumn
                        canSort
                        sortedDirection={sortExamples[1].sortDirection}
                        onClickSort={onClickSort}
                        width="135px"
                        id="col-3"
                      >
                        ステータス
                      </TableColumn>
                      <TableColumn
                        canSort
                        sortedDirection={sortExamples[2].sortDirection}
                        onClickSort={onClickSort}
                        id="col-4"
                      >
                        登録日
                      </TableColumn>
                      <TableColumn id="col-5">電話番号</TableColumn>
                      <TableColumn id="col-6">メールアドレス</TableColumn>
                      <TableColumn width="77px" id="col-7" />
                    </TableRow>
                  }
                  body={users.map((user) => (
                    <TableRow key={user.id} isSelected={user.checked}>
                      <TableCell>
                        <Checkbox
                          label={<Text size="xs">{user.id}</Text>}
                          checked={user.checked}
                          onChecked={(checked) =>
                            handleCheckUserId(checked, user.id)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Text size="xs">{user.name}</Text>
                      </TableCell>
                      <TableCell>
                        <Tag
                          label={user.statusStr}
                          showIcon={false}
                          state={
                            user.statusNum === UserStatusID.UNREGISTERED
                              ? "neutral"
                              : user.statusNum === UserStatusID.CANCELLATION
                              ? "error"
                              : user.statusNum === UserStatusID.ACTIVE
                              ? "success"
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Text size="xs">{user.activationDate}</Text>
                      </TableCell>
                      <TableCell>
                        <Text size="xs">{user.tel}</Text>
                      </TableCell>
                      <TableCell>
                        <Text size="xs">{user.email}</Text>
                      </TableCell>

                      <TableCell textAlign="center">
                        <Button
                          type="sub"
                          size="small"
                          onClick={() => navigateToDetail(user.id)}
                        >
                          詳細
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                />
                <LayoutBox justify="end" fullWidth>
                  <Pagination input={false} />
                </LayoutBox>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
      {isNli020Open && (
        <NLI020
          organizationData={{
            id: organization?._id,
            organizationName: organization?.organization_name,
            address: organization?.address,
            tel: organization?.tel,
            campaignCouponId: organization?.campaign_coupon_id,
            campaignName: organization?.campaign_name,
            term: organization?.term,
          }}
        />
      )}
      {isDeleteDialogOpen && (
        <InformationDialog
          alertLevel="error"
          open={isDeleteDialogOpen}
          closeDialog={closeNli021Dialog}
          title="削除してもよろしいですか？"
          footer={
            <LayoutBox>
              <Button color="danger" size="medium" onClick={handleDeleteButton}>
                削除
              </Button>
              <Button
                color="neutral"
                type="sub"
                size="medium"
                onClick={closeNli021Dialog}
              >
                キャンセル
              </Button>
            </LayoutBox>
          }
        >
          この操作は取り消せません。
        </InformationDialog>
      )}
    </>
  );
};

export default NLI021;
