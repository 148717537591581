// 表示用変換処理定義
import { format } from "date-fns";

import {
  AdminsRoleID,
  CampaignsStatusID,
  DeliveryMethodID,
  EmbeddedLocationID,
  EmbeddedPageID,
  EmbeddedStr,
  HospitalInspectionStatusID,
  HospitalReservationStatusID,
  INIT_EVALUATION,
  ItemsSkusStatusID,
  ModalityID,
  NotificationStatusID,
  OrderStatusID,
  PatientInterviewStatusID,
  PatientNouKnowStatusID,
  SEARCH_INFO,
  PatientTypeID,
  MriStatusID,
  PlanSpanID,
  EventHistoriesPriorityID,
  SalesCategoryID,
  UserStatusID,
  JST_OFFSET_HOURS,
  InterviewStatusLabels,
  PetStatusID,
  FORMAT_STYLE,
  HospitalPaymentsExaminationID,
  HOSPITAL_RESERVATION_TO_MINUTE,
  CampaignCouponsPaymentType,
  PatientBrainCheckStatusID,
  SalesStatusID,
  AfterCampaignID,
  paymentSpanID,
} from "../constants/common";
import { redirectToInvalidFunctionPage } from "../contexts/CustomErrorBoundary";
import { addHyphenToZipcode, formatDate } from "./utils";

// 性別を表示用に変換
const _convertGender = (gender: string) => {
  switch (gender) {
    case "male":
      return "男";
    case "female":
      return "女";
    default:
      return "その他";
  }
};

// Adminsの権限を表示用に変換
export const convertAdminRole = (role: number) => {
  switch (role) {
    case AdminsRoleID.ADMIN:
      return "Admin";
    case AdminsRoleID.CMC:
      return "ドクター";
    case AdminsRoleID.PUBLIC_USER:
      return "一般ユーザー";
    default:
      return "";
  }
};

// patients.patient_typeを表示用に変換
export const convertPatientType = (patientType: number) => {
  switch (patientType) {
    case PatientTypeID.MEMBER:
      return "会員";
    case PatientTypeID.INVITATION:
      return "招待者";
    case PatientTypeID.PRESENT:
      return "被招待者";
    case PatientTypeID.ORG:
      return "団体";
    default:
      return "";
  }
};

// users.statusを表示用に変換
export const convertUserStatus = (status: number) => {
  switch (status) {
    case UserStatusID.ACTIVE:
    case UserStatusID.INVITATION:
      return "アクティブ";
    case UserStatusID.CANCELLATION:
      return "解約";
    case UserStatusID.SUSPENDED:
      return "休会";
    default:
      return "";
  }
};

// フォーマットに合わせて日付を変換
export const convertDate = (dateStr: Date, formatStr = "yyyy/MM/dd") => {
  const date = new Date(dateStr);

  return format(date, formatStr);
};

// 現在の日付と引数の日付の差分変換
const _convertDateDifferenceToCurrent = (updatedAt: Date) => {
  const now = new Date();

  // JST変換
  now.setHours(now.getHours() + JST_OFFSET_HOURS);
  updatedAt.setHours(updatedAt.getHours() + JST_OFFSET_HOURS);

  // 現在の日付とupdatedAtの日付をYYYY-MM-DDの形式で比較
  const nowDateStr = now.toISOString().slice(0, 10);
  const updatedAtDateStr = updatedAt.toISOString().slice(0, 10);

  if (nowDateStr === updatedAtDateStr) {
    // 本日の場合、hh:mmの形式
    return updatedAt.toISOString().slice(11, 16);
  }
  // 前日以前の場合、〇日前
  const timeDifference = now.getTime() - updatedAt.getTime();
  const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;

  return `${dayDifference}日前`;
};

// 金額変換 ¥表示
export const _convertToCurrency = (value: number) => {
  const stringValue = value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `¥${stringValue}`;
};

// 金額変換 円表示
export const _convertToCurrencyJpnYen = (value: number) => {
  const stringValue = value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `${stringValue}円`;
};

// カンマ区切りに変換
export const _convertToCommaSeparatedString = (value: number) =>
  value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

// 配達方法変換
const _convertDeliveryMethod = (
  deliveryMethodNum: number,
  deliveryDate: string | null,
) => {
  switch (deliveryMethodNum) {
    case DeliveryMethodID.NORMAL:
      return "通常配送";
    case DeliveryMethodID.SPECIFIED:
      if (!deliveryDate) return redirectToInvalidFunctionPage();

      return `お届け日指定:${deliveryDate}`;
    default:
      return redirectToInvalidFunctionPage();
  }
};

// 注文ステータス変換
const _convertOrderStatusStr = (status: number) => {
  switch (status) {
    case OrderStatusID.PAYMENT_COMPLETED:
      return "支払完了";
    case OrderStatusID.SHIPPED:
      return "出荷済";
    case OrderStatusID.DELIVERY_COMPLETED:
      return "配送完了";
    default:
      return "";
  }
};

// 検査内容変換
const _convertModalityStr = (status: number) => {
  switch (status) {
    case ModalityID.MRI:
      return "MRI検査";
    case ModalityID.PET:
      return "FDG PET検査";
    default:
      return "";
  }
};

// 予約受付ステータス変換
const _convertHospitalReservationStatusStr = (status: number) => {
  switch (status) {
    case HospitalReservationStatusID.NEW:
      return "新規予約";
    case HospitalReservationStatusID.CONFIRM:
      return "確定";
    case HospitalReservationStatusID.NG:
      return "全候補日NG";
    case HospitalReservationStatusID.APPLICATION_FOR_WITHDRAWAL:
      return "取り下げ申請";
    case HospitalReservationStatusID.WITHDRAWAL:
      return "予約取り下げ";
    case HospitalReservationStatusID.INSPECTED:
      return "検査済";
    case HospitalReservationStatusID.ADMIN_CANCEL:
      return "管理者取消";
    case HospitalReservationStatusID.NO_EXAMINATION:
      return "受検なし";
    default:
      return "";
  }
};

// 未検査、検査変換
const _convertHospitalInspectionStatusStr = (status: number) => {
  switch (status) {
    case HospitalInspectionStatusID.NOT_INSPECTED:
      return "未検査";
    case HospitalInspectionStatusID.INSPECTED:
      return "検査済";
    default:
      return "";
  }
};

// sales category 変換
const _convertSalesCategoryStr = (
  category: number,
  subscriptionSpanNum: number,
) => {
  switch (category) {
    case SalesCategoryID.PRODUCT_PURCHASE:
      return "EC";
    case SalesCategoryID.SUBSCRIPTION:
      if (subscriptionSpanNum === PlanSpanID.MONTH) {
        return "会費(月払いコース)";
      }
      if (subscriptionSpanNum === PlanSpanID.YEAR) {
        return "会費(年払いコース)";
      }

      return "会費";
    case SalesCategoryID.NOUKNOW:
      return "のうKNOW";
    case SalesCategoryID.MRI:
      return "MRI検査";
    case SalesCategoryID.PET:
      return "PET検査";
    case SalesCategoryID.BRAIN_HEALTH_CHECK:
      return "あたまの健康チェック";
    case SalesCategoryID.INTERVIEW:
      return "面談";
    default:
      return "-";
  }
};

// Sales status 変換
const _convertSalesStatusStr = (status: number) => {
  switch (status) {
    case SalesStatusID.CREDIT_ACQUISITION:
      return "与信取得";
    case SalesStatusID.SOLD:
      return "売上済";
    case SalesStatusID.CREDIT_CANCELLATION:
      return "与信取消";
    case SalesStatusID.SALE_CANCELLATION:
      return "売上取消";
    case SalesStatusID.SALE_FAILURE:
      return "売上失敗";
    default:
      return "";
  }
};

// hospital_payments examination 変換
const _convertHospitalPaymentsExaminationStr = (examination: number) => {
  switch (examination) {
    case HospitalPaymentsExaminationID.NOUKNOW:
      return "のうKNOW";
    case HospitalPaymentsExaminationID.MRI:
      return "MRI検査";
    case HospitalPaymentsExaminationID.PET:
      return "PET検査";
    case HospitalPaymentsExaminationID.BRAIN_HEALTH_CHECK:
      return "あたまの健康チェック";
    case HospitalPaymentsExaminationID.INTERVIEW:
      return "面談";
    default:
      return "-";
  }
};

// event_histories  priority ステータス変換
const _convertEventHistoriesPriorityStr = (priority: number) => {
  switch (priority) {
    case EventHistoriesPriorityID.LOW:
      return "低";
    case EventHistoriesPriorityID.MEDIUM:
      return "中";
    case EventHistoriesPriorityID.HIGH:
      return "高";
    default:
      return "未選択";
  }
};

// 日付文字列をFROM~TOに変換(1時間)
const _convertToTimeRangeStr = (dateStr: string) => {
  const date = new Date(dateStr);
  const endDate = new Date(date);
  endDate.setHours(date.getHours() + 1);

  const formatDate = (d: Date) => {
    const yyyy = d.getFullYear();
    const mm = String(d.getMonth() + 1).padStart(2, "0");
    const dd = String(d.getDate()).padStart(2, "0");
    const hh = String(d.getHours()).padStart(2, "0");
    const min = String(d.getMinutes()).padStart(2, "0");

    return `${yyyy}/${mm}/${dd} ${hh}:${min}`;
  };

  const startTime = formatDate(date);
  const endTime = `${String(endDate.getHours()).padStart(2, "0")}:${String(
    endDate.getMinutes(),
  ).padStart(2, "0")}`;

  return `${startTime}～${endTime}`;
};

// 医療機関の時間範囲変換
const _convertHospitalTimeRange = (
  startTime: Date,
  hospitalFrameMinute: number,
): string => {
  const weekDays: Weekday[] = ["日", "月", "火", "水", "木", "金", "土"];

  // 曜日を取得
  const dayOfWeek: Weekday = weekDays[startTime.getDay()];

  // 開始時間の日付
  const year = startTime.getFullYear();
  const month = (startTime.getMonth() + 1).toString().padStart(2, "0");
  const date = startTime.getDate().toString().padStart(2, "0");
  const datePart = `${year}/${month}/${date}(${dayOfWeek})`;

  // 開始時間
  const formattedStartTime = `${startTime
    .getHours()
    .toString()
    .padStart(2, "0")}:${startTime.getMinutes().toString().padStart(2, "0")}`;

  // 継続時間を加算して終了時間を取得
  const endDate = new Date(startTime);
  endDate.setMinutes(endDate.getMinutes() + hospitalFrameMinute);
  const formattedEndTime = `${endDate
    .getHours()
    .toString()
    .padStart(2, "0")}:${endDate.getMinutes().toString().padStart(2, "0")}`;

  return `${datePart} ${formattedStartTime}～${formattedEndTime}`;
};

const _convertTask = (category: string) => {
  switch (category) {
    case "chats":
      return {
        tag: "医師チャット",
        description: "コメントが来ています。",
      };
    case "mri":
      return {
        tag: "MRI",
        description:
          "医療機関からの画像をもとに読影のうえ、会員に結果を送信してください。",
      };
    case "nouknow":
      return {
        tag: "のうKNOW",
        description: "テスト結果を確認し、会員に結果を送信してください。",
      };
    case "pet":
      return {
        tag: "PET",
        description:
          "医療機関からの画像をもとに読影のうえ、会員に結果を送信してください。",
      };
    case "interview":
      return {
        tag: "面談",
        description: "面談の予約がありました。予約を確定してください。",
      };
    case "ec":
      return {
        tag: "EC",
        description: "注文の支払いが完了しました。配送手配をしてください。",
      };

    default:
      return {
        tag: "",
        description: "",
      };
  }
};

// 時間の差分を分で返します
export const getDiffInMinutes = (start: Date, end: Date) => {
  const diffInMs = end.getTime() - start.getTime();

  return diffInMs / (1000 * 60);
};

export const convertPatientInfo = (patientInfo: PatientsType | null) => {
  if (patientInfo) {
    const {
      _id: id,
      user_id: userId,
      full_name_slash: fullNameSlash,
      birth,
      gender,
      tel,
      email,
      evaluation,
      is_enabled_modality: isEnabledModality,
      is_notification: isNotification,
      past_diagnosis: pastDiagnosis,
      patient_type: patientTypeNum,
      plan_name: planName,
      price: priceNum,
      span,
      zipcode,
      pref,
      city,
      town1,
      town2,
      name_sei: nameSei,
      name_mei: nameMei,
      name_sei_kana: nameSeiKana,
      name_mei_kana: nameMeiKana,
      birth_year: birthYear,
      birth_month: birthMonth,
      birth_day: birthDay,
      status: statusNum,
      organization_name: orgName,
      activation_date: activationDate,
      campaign_code: campaignCode,
      campaign_name: campaignName,
      discount_rate: discountRate,
      discount_price: discountPrice,
      payment_type: paymentType,
      payment_span: paymentSpan,
      term,
      cancel_period: cancelPeriod,
      canceled_after_campaign: canceledAfterCampaign,
      parent_user_id: parentUserId,
      parent_user_name: parentUserName,
    } = patientInfo;

    const patientTypeStr = convertPatientType(patientTypeNum);
    const statusStr = convertUserStatus(statusNum);
    const zipcodeHyphen = addHyphenToZipcode(zipcode) ?? "";

    return {
      id,
      userId,
      name: fullNameSlash,
      birth,
      genderStr: _convertGender(gender),
      tel,
      email,
      evaluation: evaluation ? evaluation.toString() : INIT_EVALUATION,
      planName,
      planType:
        (patientTypeNum === PatientTypeID.MEMBER ||
          patientTypeNum === PatientTypeID.ORG ||
          patientTypeNum === PatientTypeID.PRESENT) &&
        priceNum
          ? `${
              span === PlanSpanID.YEAR ? "年払いコース:" : "月払いコース:"
            }${_convertToCommaSeparatedString(priceNum)}円${
              span === PlanSpanID.YEAR ? "" : "/月"
            }`
          : "",
      mriPetConditions: isEnabledModality ? "当てはまる" : "当てはまらない",
      notification: isNotification ? "希望する" : "希望しない",
      address: zipcode
        ? `${zipcodeHyphen} ${pref}${city}${town1}${town2 || ""}`
        : "-",
      zipcode: zipcode || "",
      pref: pref || "",
      city: city || "",
      town1: town1 || "",
      town2: town2 || "",
      nameSei,
      nameMei,
      nameSeiKana,
      nameMeiKana,
      birthYear: birthYear.toString(),
      birthMonth: birthMonth.toString(),
      birthDay: birthDay.toString(),
      patientTypeNum,
      patientTypeStr,
      statusNum,
      statusStr,
      orgName: orgName || "-",
      activationDate: userId ? activationDate || "-" : "",
      campaignCode,
      campaignName,
      discountRate: discountRate ? `-${discountRate}%` : "",
      discountPrice: discountPrice
        ? `-${_convertToCommaSeparatedString(discountPrice)}円`
        : "",
      paymentTypeStr:
        paymentType === CampaignCouponsPaymentType.FIXED_AMOUNT
          ? "定額"
          : "定率",
      paymentTypeNum: paymentType,
      term: paymentSpan
        ? paymentSpan === paymentSpanID.MONTH
          ? `${term}ヶ月`
          : `${term}年`
        : "",
      parentUserId,
      parentUserName,
      pastDiagnosis:
        pastDiagnosis === undefined
          ? ""
          : pastDiagnosis
          ? "認知症 もしくはMCI（軽度認知障害） と診断されたことがない"
          : "認知症 もしくはMCI（軽度認知障害） と診断されたことがある",
      cancelPeriod,
      afterCampaignStr:
        canceledAfterCampaign === AfterCampaignID.CANCELLATION
          ? "退会"
          : "通常料金で継続",
    };
  }

  return {
    id: "",
    userId: "",
    name: "",
    birth: "",
    genderStr: "",
    tel: "",
    email: "",
    evaluation: INIT_EVALUATION,
    planName: "",
    planType: "",
    mriPetConditions: "",
    notification: "",
    address: "",
    zipcode: "",
    pref: "",
    city: "",
    town1: "",
    town2: "",
    nameSei: "",
    nameMei: "",
    nameSeiKana: "",
    nameMeiKana: "",
    birthYear: "",
    birthMonth: "",
    birthDay: "",
    patientTypeNum: 999,
    patientTypeStr: "",
    statusNum: 999,
    statusStr: "",
    orgName: "",
    activationDate: "",
    campaignCode: "",
    campaignName: "",
    discountRate: "",
    discountPrice: "",
    paymentTypeStr: "",
    paymentTypeNum: 999,
    term: "",
    parentUserId: "",
    parentUserName: "",
    pastDiagnosis: "",
    cancelPeriod: "",
    afterCampaignStr: "",
  };
};

type SearchInfo<IDType> = {
  id: IDType;
  label: string;
  flgKey: string;
};
// ステータスの番号を文字列に変換
const convertStatusStr = <IDType>(
  statusNumber: number,
  searchInfo: SearchInfo<IDType>[],
  statusIds: { [key: string]: IDType },
): string => {
  const statusNum = statusNumber as IDType;
  if (!(statusNumber in statusIds)) {
    return redirectToInvalidFunctionPage();
  }

  const foundStatus = searchInfo.find(({ id }) => id === statusNum);

  if (!foundStatus) {
    return redirectToInvalidFunctionPage();
  }

  return foundStatus.label;
};

// MRIのステータスの番号を文字列に変換
const convertMriStatusStr = <IDType>(
  statusNumber: number,
  searchInfo: SearchInfo<IDType>[],
  statusIds: { [key: string]: IDType },
): string => {
  const statusNum = statusNumber as IDType;
  if (!(statusNumber in statusIds)) {
    return "オンライン問診済";
  }

  const foundStatus = searchInfo.find(({ id }) => id === statusNum);

  if (!foundStatus) {
    return redirectToInvalidFunctionPage();
  }

  return foundStatus.label;
};

export const convertNlc004Table = (queryResults: PatientNlc004StateType[]) => {
  const convertResults: Nlc004TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      user_id: userId,
      nouknowId,
      full_name_slash: name,
      finished_at: lastInspectionDate,
      score,
      status,
      tel,
      email,
    } = queryResult;

    const reportStatus = convertStatusStr(
      status,
      SEARCH_INFO.NLC004,
      PatientNouKnowStatusID,
    );

    return {
      id,
      userId,
      nouknowId,
      name,
      email,
      phoneNum: tel,
      lastInspectionDate,
      score,
      reportStatus,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値を表示用に変換
export const convertNlc005Table = (queryResults: PatientNlc005StateType[]) => {
  const convertResults: Nlc005TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      userId,
      displayUserId,
      full_name_slash: name,
      tel,
      email,
      mriStatus,
      mriProblem,
      appointmentDateMRI,
      hospital,
    } = queryResult;

    const status = convertMriStatusStr(
      mriStatus,
      SEARCH_INFO.NLC005,
      MriStatusID,
    );

    let result: string;
    if (mriProblem === 2) {
      result = "done";
    } else if (mriProblem === 1) {
      result = "undone";
    } else {
      result = "other";
    }

    return {
      id,
      userId,
      displayUserId,
      name,
      status,
      result,
      appointmentDateMRI,
      hospital: hospital || "-",
      phoneNum: tel,
      email,
      checked: false,
    };
  });

  return convertResults;
};

export const convertNlc006Table = (queryResults: PatientNlc006StateType[]) => {
  const convertResults: Nlc006TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      userId,
      displayUserId,
      full_name_slash: name,
      tel,
      email,
      petStatus,
      appointmentDateMRI,
      hospital,
    } = queryResult;

    const status = convertStatusStr(petStatus, SEARCH_INFO.NLC006, PetStatusID);

    return {
      id,
      userId,
      displayUserId,
      name,
      status,
      appointmentDateMRI,
      hospital,
      phoneNum: tel,
      email,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値を表示用に変換
export const convertNlc007Table = (queryResults: PatientNlc007StateType[]) => {
  const convertResults: Nlc007TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      user_id: userId,
      interviewId,
      full_name_slash: name,
      email,
      tel,
      interviewDate,
      interviewStatus,
      manager = "-",
    } = queryResult;

    const status = convertStatusStr(
      interviewStatus,
      SEARCH_INFO.NLC007,
      PatientInterviewStatusID,
    );

    return {
      id,
      userId,
      interviewId,
      name,
      email,
      dateAndTime: interviewDate,
      status,
      phoneNum: tel,
      manager,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値をお知らせ一覧画面(NLG001)表示用に変換
export const convertNlg001Table = (
  queryResults: NotificationsNlg001StateType[],
) => {
  const convertResults: Nlg001TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      title,
      status,
      deliveried_at: deliveriedAt,
      last_updated_at: lastUpdatedAt,
      full_name: fullName,
    } = queryResult;

    return {
      id,
      title,
      status: status === NotificationStatusID.UNPUBLISHED ? "未公開" : "公開中",
      deliveriedAt,
      lastUpdatedAt,
      changer: fullName,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値を表示用に変換
export const convertNld001Table = (queryResults: PatientNld001StateType[]) => {
  const convertResults: Nld001TableType[] = queryResults.map((queryResult) => {
    const { patient_id: id, full_name_slash: name, email, tel } = queryResult;

    return {
      id,
      name,
      // 2次以降フェーズ対応: No162 ステータス、検査、金額、支払い期日、支払い日、PET検査日
      status: "未払い",
      test: "FDG PET検査",
      amount: "¥500,000",
      dueDate: "2023/1/1",
      paymentDate: "2022/1/1",
      petScanDate: "2022/1/1",
      phoneNum: tel,
      email,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値を表示用に変換
export const convertNld002Table = (queryResults: PatientNld002StateType[]) => {
  const convertResults: Nld002TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      hospital_id: hospitalId,
      hospital_name: hospitalName,
      name,
      amount: amountNum,
      prefecture,
      examination: examinationNum,
      exam_date: examDate,
    } = queryResult;

    const examination = _convertHospitalPaymentsExaminationStr(examinationNum);
    const examDateStr = convertDate(examDate);

    return {
      id,
      hospitalId,
      hospitalName,
      name,
      amount: _convertToCurrency(amountNum),
      prefecture,
      examination,
      examDateStr,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値を表示用に変換
export const convertNld003Table = (queryResults: PatientNld003StateType[]) => {
  const convertResults: Nld003TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      name,
      amount: amountNum,
      examination: examinationNum,
      exam_date: examDate,
    } = queryResult;

    const examination = _convertHospitalPaymentsExaminationStr(examinationNum);
    const examDateStr = convertDate(examDate);

    return {
      id,
      name,
      amount: _convertToCurrency(amountNum),
      examination,
      examDateStr,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値を表示用に変換
export const convertNld004Table = (queryResults: PatientNld004StateType[]) => {
  const convertResults: Nld004TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      user_id: userId,
      patient_id: patientId,
      category: categoryNum,
      full_name_slash: name,
      amount: amountNum,
      authorization_date: authorizationDate,
      email,
      tel,
      subscription_span: subscriptionSpanNum,
      status: statusNum,
      payment_date: paymentDate,
    } = queryResult;

    const category = _convertSalesCategoryStr(categoryNum, subscriptionSpanNum);
    const statusStr = _convertSalesStatusStr(statusNum);

    return {
      id,
      userId,
      patientId,
      name,
      category,
      amount: _convertToCurrency(amountNum),
      authorizationDate,
      paymentDate,
      tel,
      email,
      statusStr,
      statusNum,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値を表示用に変換
export const convertNld005Table = (queryResults: PatientNld005StateType[]) => {
  const convertResults: Nld005TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      user_id: userId,
      patient_id: patientId,
      category: categoryNum,
      full_name_slash: name,
      amount: amountNum,
      authorization_date: authorizationDate,
      payment_date: paymentDate,
      subscription_span: subscriptionSpanNum,
      status: statusNum,
      receipt_issue_date: receiptIssueDate,
    } = queryResult;

    const category = _convertSalesCategoryStr(categoryNum, subscriptionSpanNum);
    const statusStr = _convertSalesStatusStr(statusNum);

    return {
      id,
      userId,
      patientId,
      name,
      category,
      amount: _convertToCurrency(amountNum),
      authorizationDate,
      paymentDate,
      receiptIssueDate,
      statusStr,
      statusNum,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値をNLF001表示用に変換
export const convertNlf001Table = (queryResults: ordersNlf001StateType[]) => {
  const convertResults: Nlf001TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      patient_name: patientName,
      status: statusNum,
      delivery_date: deliveryDate,
      settlement_time: settlementTime,
      total_amount: totalAmountBigInt,
      delivery_method: deliveryMethodNum,
      updated_at: updatedAt,
      user_id: userId,
    } = queryResult;

    const renewal = _convertDateDifferenceToCurrent(updatedAt);
    const totalAmount = _convertToCurrency(totalAmountBigInt);
    const deliveryMethod = _convertDeliveryMethod(
      deliveryMethodNum,
      deliveryDate,
    );
    const status = _convertOrderStatusStr(statusNum);

    return {
      id,
      patientName,
      renewal,
      totalAmount,
      status,
      deliveryMethod,
      settlementTime,
      userId,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値をNLF005表示用に変換
export const convertNlf005 = (queryResult: Nlf005TableType | null) => {
  if (queryResult) {
    const {
      _id: id,
      status: statusNum,
      settlement_time: settlementTime,
      delivery_method: deliveryMethodNum,
      delivery_date: deliveryDate,
      delivery_fee: deliveryFeeNum,
      total_item_amount: totalItemAmountNum,
      total_amount: totalAmountNum,
      patient_name: patientName,
      patient_tel: patientTel,
      patient_email: patientEmail,
      transactions_info: transactionsInfoObj,
      total_amount_per_tax: totalAmountPerTax,
      order_zipcode: orderZipcode,
      order_address: orderAddress,
    } = queryResult;

    const transactionsInfo: TransactionInfoDisplay[] = transactionsInfoObj.map(
      (transaction) => ({
        ...transaction,
        total_amount: _convertToCurrency(transaction.total_amount),
        quantity: transaction.quantity.toString(),
        sku_amount: _convertToCurrency(
          transaction.total_amount / transaction.quantity,
        ),
        checked: true,
      }),
    );
    const deliveryFee = _convertToCommaSeparatedString(deliveryFeeNum);
    const totalItemAmount = _convertToCommaSeparatedString(totalItemAmountNum);
    const totalAmount = _convertToCurrency(totalAmountNum);
    const status = _convertOrderStatusStr(statusNum);
    const deliveryMethod = _convertDeliveryMethod(
      deliveryMethodNum,
      deliveryDate,
    );

    // 内税
    const consumptionTaxAmount = _convertToCommaSeparatedString(
      totalAmountPerTax
        .map((taxObj) => taxObj.tax)
        .reduce((sum: number, currentValue: number) => sum + currentValue),
    );

    // 税金概要
    const taxSummary = totalAmountPerTax.map((taxObj, index) => ({
      id: index.toString(),
      contents: `商品税${taxObj.rate}%対象`,
      taxRate: `${taxObj.rate}%`,
      taxableSubject: _convertToCurrency(taxObj.amount),
      totalTaxes: _convertToCurrency(taxObj.tax),
    }));

    return {
      id,
      status,
      patientName,
      patientTel,
      patientEmail,
      deliveryMethod,
      settlementTime,
      transactionsInfo,
      deliveryFee,
      totalItemAmount,
      totalAmount,
      consumptionTaxAmount,
      taxSummary,
      statusNum,
      orderZipcode,
      orderAddress,
    };
  }

  return {
    id: "",
    status: "",
    patientName: "",
    patientAddress: "",
    patientTel: "",
    patientEmail: "",
    deliveryMethod: "",
    settlementTime: "",
    transactionsInfo: [],
    deliveryFee: "",
    totalItemAmount: "",
    totalAmount: "",
    consumptionTaxAmount: "",
    taxSummary: [],
    statusNum: 99,
  };
};

// MongoDBの値をNLF006表示用に変換
export const convertNlf006Table = (queryResults: SalesNlf006StateType[]) => {
  const convertResults: Nlf006TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      payment_date: paymentDate,
      count,
      totalAmount: totalAmountNum,
    } = queryResult;

    const totalAmount = _convertToCurrency(totalAmountNum);

    return {
      id,
      paymentDate,
      count: count.toString(),
      totalAmount,
      checked: false,
    };
  });

  return convertResults;
};

export const convertNlf007Table = (queryResults: SalesNlf007StateType[]) => {
  const totalAmountNum = queryResults.reduce(
    (accum, curr) => accum + curr.amount,
    0,
  );
  const formattedTotalAmount = _convertToCurrency(totalAmountNum);

  const convertResult: Nlf007TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      order_id: orderId,
      amount: amountNum,
      patient_name: patientName,
      settlement_time: settlementTime,
    } = queryResult;

    const amount = _convertToCurrency(amountNum);

    return {
      id,
      orderId,
      amount,
      patientName,
      settlementTime,
      checked: false,
    };
  });

  return {
    convertResult,
    formattedTotalAmount,
  };
};

// MongoDBの値を表示用に変換
export const convertNlh001Table = (
  queryResults: CampaignsNlh001StateType[],
) => {
  const convertResults: Nlh001TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      embedded_page: page,
      embedded_location: location,
      title,
      url,
      pc_image: picture,
      status: statusNum,
      delivery_date: deliveryDate,
      updated_at: deliveryDateUpdate,
      is_registered: isRegistered,
    } = queryResult;
    const update = queryResult.user_info?.full_name || "";

    const status = convertStatusStr(
      statusNum,
      SEARCH_INFO.NLH001,
      CampaignsStatusID,
    );

    const pageNum = page as EmbeddedPageID;
    const locationNum = location as EmbeddedLocationID;
    const locationStr = `${EmbeddedStr.PAGE[pageNum]}${EmbeddedStr.LOCATION[locationNum]}`;

    return {
      id,
      location: locationStr,
      campaignTitle: title,
      url,
      picture,
      status,
      deliveryDate,
      deliveryDateUpdate,
      update,
      isRegistered,
    };
  });

  return convertResults;
};

// MongoDBの値を表示用に変換
export const convertNlh003Table = (
  queryResult: CampaignsNlh003StateType,
): Nlh003TableType => {
  const {
    title,
    embedded_page: page,
    embedded_location: location,
    pc_image: pcImg,
    sp_image: spImg,
    status: statusNum,
    delivery_date: deliveryDate,
    is_limited: isLimited,
    suspend_date: suspendDate,
  } = queryResult;

  const status = convertStatusStr(
    statusNum,
    SEARCH_INFO.NLH001,
    CampaignsStatusID,
  );

  const pageNum = page as EmbeddedPageID;
  const locationNum = location as EmbeddedLocationID;
  const locationStr = `${EmbeddedStr.PAGE[pageNum]}${EmbeddedStr.LOCATION[locationNum]}`;

  const suspendDateStr = isLimited ? `制限あり ${suspendDate}` : "無制限";

  const isDeliveryStopped = statusNum === CampaignsStatusID.UNPUBLISHED;

  return {
    title,
    embedded: locationStr,
    pcImg,
    spImg,
    status,
    deliveryDate,
    suspendDate: suspendDateStr,
    isDeliveryStopped,
  };
};

// MongoDBの値をお知らせ詳細画面(NLG003)表示用に変換
export const convertNlg003 = (notification: NotificationsTableType | null) => {
  if (notification) {
    const {
      _id: id,
      title,
      detail,
      image_url: imageUrl,
      status: statusNum,
      deliveried_at: deliveriedAt,
    } = notification;

    return {
      id,
      title,
      detail,
      imageUrl: imageUrl ? `${imageUrl}?timestamp=${Date.now()}` : "",
      statusStr:
        statusNum === NotificationStatusID.UNPUBLISHED ? "未公開" : "公開中",
      statusNum,
      deliveriedAt,
    };
  }

  return {
    id: "",
    title: "",
    detail: "",
    imageUrl: "",
    statusStr: "",
    statusNum: 0,
    deliveriedAt: "",
  };
};

export const convertNlg004 = (notification: NotificationsTableType | null) => {
  if (notification) {
    const {
      _id: id,
      title,
      detail,
      image_url: imageUrl,
      status,
      deliveried_at: deliveriedAt,
      last_updated_at: lastUpdatedAt,
      full_name: fullName,
      last_updated_admin_id: lastUpdatedAdminId,
      suspend,
    } = notification;

    const dateObject = new Date(deliveriedAt);
    // 日付
    const deliveriedDate = new Date(
      dateObject.getFullYear(),
      dateObject.getMonth(),
      dateObject.getDate(),
    );
    const currentDate = new Date();
    // 時間
    const deliveriedTime = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      dateObject.getHours(),
      dateObject.getMinutes(),
      dateObject.getSeconds(),
    );

    return {
      id,
      title,
      detail,
      imageUrl: imageUrl ? `${imageUrl}?timestamp=${Date.now()}` : "",
      status,
      deliveriedDate,
      deliveriedTime,
      lastUpdatedAt,
      changer: fullName,
      lastUpdatedAdminId,
      suspend,
    };
  }

  return {
    id: "",
    title: "",
    detail: "",
    imageUrl: "",
    status: 0,
    deliveriedDate: new Date(),
    deliveriedTime: new Date(),
    lastUpdatedAt: "",
    changer: "",
    lastUpdatedAdminId: "",
    suspend: false,
  };
};

export const convertNli003Table = (queryResults: Nli003StateType[]) => {
  const convertResults: Nli003TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      user_id: userId,
      full_name: fullName,
      admin_role: role,
      email,
      status: statusNum,
    } = queryResult;

    const roleStr = convertAdminRole(role);

    const statusCsv = statusNum === UserStatusID.ACTIVE ? "✓" : "";

    return {
      id,
      userId,
      fullName,
      roleStr,
      role,
      email,
      statusNum,
      statusStr: statusNum.toString(),
      statusCsv,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値を商品種別一覧画面(NLI007)表示用に変換
export const convertNli007Table = (queryResults: ItemsNli007StateType[]) => {
  const convertResults: Nli007TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      item_code: itemCode,
      item_name: itemName,
      sort,
      status,
      item_tags_info: itemTagsInfo,
      skuCount,
      image_urls: images,
    } = queryResult;

    return {
      id,
      itemCode,
      itemName,
      sort,
      status: status === ItemsSkusStatusID.VISIBLE,
      itemTagsInfo,
      checked: false,
      skuCount,
      image: images?.[0] ?? "",
    };
  });

  return convertResults;
};

// MongoDBの値をSKU一覧画面(NLI009)表示用に変換
export const convertNli009Table = (queryResults: SkusNli009StateType[]) => {
  const convertResults: Nli009TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      images,
      sku_code: code,
      sku_name: skuName,
      amount: amountNum,
      stock,
      status,
      item_tags_info: itemTagsInfo,
      sort,
    } = queryResult;
    const { item_name: name } = queryResult.items_info;

    return {
      id,
      code,
      url: images[0],
      skuName,
      name,
      amount: _convertToCurrency(amountNum),
      stock,
      itemTagsInfo,
      isShow: status === ItemsSkusStatusID.VISIBLE,
      checked: false,
      sort,
    };
  });

  return convertResults;
};

// MongoDBの値を商品詳細一覧画面(NLI011)表示用に変換
export const convertNli011Display = (queryResults: ItemsNli011StateType) => {
  const {
    // 商品種別情報
    item_code: itemCode,
    item_name: itemName,
    item_tags_info: itemTags,
    item_description: itemDesc,
    images: itemImages,
    // SKU情報
    skus_info: skusInfo,
    // オプション情報
    option_configs_info: optionConfigInfos,
  } = queryResults;

  // 商品種別情報
  const convertItemInfo: Nli011ItemInfoType = {
    itemCode,
    itemName,
    itemTags,
    itemDesc,
    itemImages: itemImages.map((url, index) => ({
      id: index.toString(),
      url,
    })),
  };

  // SKU情報
  const convertSkusInfo: Nli011SkuInfoType[] = skusInfo
    .sort((a, b) => b.sort - a.sort)
    .map(
      ({
        _id: skuId,
        sku_code: skuCode,
        sku_name: skuName,
        amount,
        stock,
        images: skuImages,
        status: skuStatus,
        option_contents_info: optionContentsInfo,
        consumption_tax_rate: tax,
      }) => ({
        skuId,
        skuCode,
        skuName,
        amount: amount.toString(),
        stock: stock.toString(),
        skuImages,
        tax,
        isShow: skuStatus === ItemsSkusStatusID.VISIBLE,
        options: optionContentsInfo.map(
          ({
            _id: optionContentsId,
            description,
            option_config_id: optionConfigId,
          }) => ({
            optionContentsId,
            optionConfigId,
            description,
          }),
        ),
      }),
    );

  const convertOptionConfigInfos = optionConfigInfos.map(
    ({ _id: optionConfigId, option_title: optionTitle }) => ({
      optionConfigId,
      optionTitle,
    }),
  );

  return { convertItemInfo, convertSkusInfo, convertOptionConfigInfos };
};

// MongoDBの値を医療機関一覧画面(NLI016)表示用に変換
export const convertNli016Table = (queryResults: hospitalNli016StateType[]) => {
  const convertResults: Nli016TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      hospital_name: hospitalName,
      tel,
      prefecture,
      address,
    } = queryResult;

    return {
      id,
      hospitalName,
      tel,
      prefecture,
      address,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値を医療機関一覧画面(NLI017)表示用に変換
export const convertNli017Hospital = (
  queryResult: hospitalNli016StateType | null,
) => {
  if (queryResult) {
    const {
      _id: id,
      hospital_name: hospitalName,
      tel,
      prefecture,
      address,
      email,
    } = queryResult;

    return {
      id,
      hospitalName,
      tel,
      prefecture,
      address,
      email,
    };
  }

  return {
    id: "",
    hospitalName: "",
    tel: "",
    prefecture: "",
    address: "",
    email: "",
  };
};

// MongoDBの値を医療機関詳細画面(NLL017)表示用に変換
export const convertNli017Table = (
  queryResults: hospitalUserNli017StateType[],
) => {
  const convertResults: Nli017TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      full_name: fullName,
      email,
      user_id: userId,
      status,
    } = queryResult;

    return {
      id,
      userId,
      fullName,
      authority: "一般ユーザー",
      email,
      checked: false,
      status,
    };
  });

  return convertResults;
};

export const convertNli021Table = (queryResults: Nli021StateType[]) => {
  const convertResults: Nli021TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      name,
      email,
      tel,
      activation_date: activationDate,
      status: statusNum,
    } = queryResult;

    return {
      id,
      name,
      email,
      tel,
      activationDate,
      statusNum,
      statusStr: convertUserStatus(statusNum),
      checked: false,
    };
  });

  return convertResults;
};

export const convertMib001Table = (queryResults: Mib001StateType[]) => {
  const convertResults: Mib001TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      patient_name: patientName,
      patient_id: patientId,
      modality: modalityNum,
      patient_tel: patientTel,
      created_at: createdAt,
      status: statusNum,
      patient_birthday: patientBirthday,
      patient_email: patientEmail,
      patient_gender: patientGenderStr,
      candidate_book_dates: candidateBookDates,
      fix_book_date_start: fixBookDate,
      fix_book_date_db: fixBookDateOrg,
      user_id: userId,
      note,
    } = queryResult;

    // 検査内容変換
    const modalityStr = _convertModalityStr(modalityNum);
    // ステータス
    const status = _convertHospitalReservationStatusStr(statusNum);
    // 性別
    const patientGender = _convertGender(patientGenderStr);

    // 予約候補日表示用
    const candidateBookDatesDisplayObj = {
      firstDisplay: candidateBookDates?.first
        ? _convertToTimeRangeStr(candidateBookDates.first)
        : "",
      secondDisplay: candidateBookDates?.second
        ? _convertToTimeRangeStr(candidateBookDates.second)
        : "",
      thirdDisplay: candidateBookDates?.third
        ? _convertToTimeRangeStr(candidateBookDates.third)
        : "",
      fourthDisplay: candidateBookDates?.fourth
        ? _convertToTimeRangeStr(candidateBookDates.fourth)
        : "",
      fifthDisplay: candidateBookDates?.fifth
        ? _convertToTimeRangeStr(candidateBookDates.fifth)
        : "",
    };

    // 元の候補日の文字列に戻す
    const fixBookDateTime00 = `${fixBookDate.substring(
      0,
      fixBookDate.length - 2,
    )}00`;

    return {
      id,
      patientName,
      patientId,
      modalityNum,
      modalityStr,
      patientTel,
      createdAt,
      status,
      statusNum,
      patientBirthday,
      patientEmail,
      patientGender,
      candidateBookDates,
      candidateBookDatesDisplayObj,
      fixBookDate,
      fixBookDateTime00,
      fixBookDateOrg,
      note: note || "",
      userId,
      checked: false,
    };
  });

  return convertResults;
};

export const convertMib002Table = (
  fixBookDate: string,
  statusNum: number,
  hospitalInfo: HospitalTimeIntervalType | null,
  modalityNum: number,
  candidateDates?: {
    first?: string;
    second?: string;
    third?: string;
    fourth?: string;
    fifth?: string;
  },
) => {
  if (!candidateDates)
    return {
      convertCandidateDates: [],
      otherCandidateDates: {
        label: "その他の時間",
        value: "other",
      },
    };

  // 医療機関の枠時間(分) 固定で1時間としているが、必要であれば、枠時間を使用
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const hospitalFrameMinute = hospitalInfo
    ? modalityNum === ModalityID.MRI
      ? hospitalInfo.mri_interval
      : modalityNum === ModalityID.PET
      ? hospitalInfo.pet_interval
      : 0
    : 0;

  const numberSymbols = ["①", "②", "③", "④", "⑤"];
  const orderKeys: (keyof typeof candidateDates)[] = [
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
  ];

  // 元の候補日の文字列に戻す
  const originSelectDateStr = `${fixBookDate.substring(
    0,
    fixBookDate.length - 2,
  )}00`;

  const convertCandidateDates = orderKeys
    .filter((key) => candidateDates[key])
    .map((key, index) => {
      const dateObj = new Date(candidateDates[key]!);
      const endTimeObj = new Date(dateObj);
      // 固定で1時間としているが、必要であれば、枠時間を使用
      endTimeObj.setMinutes(
        dateObj.getMinutes() + HOSPITAL_RESERVATION_TO_MINUTE,
      );
      const options: Intl.DateTimeFormatOptions = {
        month: "2-digit",
        day: "numeric",
        weekday: "short",
        hour: "2-digit",
        minute: "2-digit",
      };
      const formattedStartDate = dateObj.toLocaleDateString("ja-JP", options);
      const formattedEndDate = endTimeObj.toLocaleTimeString("ja-JP", {
        hour: "2-digit",
        minute: "2-digit",
      });
      if (
        originSelectDateStr &&
        statusNum &&
        (statusNum === HospitalReservationStatusID.CONFIRM ||
          statusNum ===
            HospitalReservationStatusID.APPLICATION_FOR_WITHDRAWAL ||
          statusNum === HospitalReservationStatusID.WITHDRAWAL)
      ) {
        if (originSelectDateStr !== candidateDates[key]) {
          return {
            label: `${numberSymbols[index]} ${formattedStartDate}～${formattedEndDate}`,
            value: candidateDates[key]!,
            disabled: true,
          };
        }

        return {
          label: `${numberSymbols[index]} ${formattedStartDate}～${formattedEndDate}`,
          value: candidateDates[key]!,
        };
      }

      return {
        label: `${numberSymbols[index]} ${formattedStartDate}～${formattedEndDate}`,
        value: candidateDates[key]!,
      };
    });

  // 候補日にない場合、その他
  const isDateInCandidateDates =
    Object.values(candidateDates).includes(originSelectDateStr);

  const otherCandidateDates =
    (statusNum === HospitalReservationStatusID.CONFIRM ||
      statusNum === HospitalReservationStatusID.APPLICATION_FOR_WITHDRAWAL ||
      statusNum === HospitalReservationStatusID.WITHDRAWAL) &&
    isDateInCandidateDates
      ? { label: "その他の時間", value: "other", disabled: true }
      : { label: "その他の時間", value: "other" };

  return {
    convertCandidateDates,
    otherCandidateDates,
    isDateInCandidateDates,
  };
};

export const convertMic001Table = (queryResults: Mic001StateType[]) => {
  const convertResults: Mic001TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      patient_name: patientName,
      status: statusNum,
      fix_book_date_range: fixBookDateRange,
      patient_tel: patientTel,
      patient_email: patientEmail,
      modality: modalityNum,
      patient_id: patientId,
      user_id: userId,
      fix_book_date_start: fixBookDateStart,
    } = queryResult;

    // ステータス
    const statusStr = _convertHospitalReservationStatusStr(statusNum);

    const modalityStr = _convertModalityStr(modalityNum);

    return {
      id,
      patientName,
      statusNum,
      statusStr,
      fixBookDateRange,
      patientTel,
      patientEmail,
      modalityStr,
      patientId,
      userId,
      fixBookDateStart,
    };
  });

  return convertResults;
};

export const convertMid001Table = (queryResults: Mid001StateType[]) => {
  const convertResults: Mid001TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      user_id: userId,
      patient_name: patientName,
      tel,
      email,
      lastReservationObj,
    } = queryResult;

    // 最終予約日の編集
    let fixBookDateDisplay = "-";
    if (
      lastReservationObj?.fix_book_date_start &&
      lastReservationObj?.fix_book_date_end
    ) {
      const { fix_book_date_start: start, fix_book_date_end: end } =
        lastReservationObj;
      const diffInMinutes = getDiffInMinutes(new Date(start), new Date(end));
      fixBookDateDisplay = _convertHospitalTimeRange(
        new Date(start),
        diffInMinutes,
      );
    }

    return {
      id,
      userId,
      patientName,
      fixBookDateDisplay,
      tel,
      email,
    };
  });

  return convertResults;
};

export const convertMid002User = (user: Mid002UserStateType | null) => {
  if (user) {
    const {
      _id: id,
      full_name_slash: fullNameSlash,
      user_id: userId,
      email,
      gender,
      tel,
      birthDay,
    } = user;

    return {
      id,
      fullNameSlash,
      userId,
      email,
      gender: _convertGender(gender),
      tel,
      birthDay,
    };
  }

  return {
    id: "",
    fullNameSlash: "",
    userId: "",
    email: "",
    gender: "",
    tel: "",
    birthDay: "",
  };
};

export const convertMid002Modality = (
  queryResults: Mid002ModalityStateType[],
) => {
  const convertResults: Mid002ModalityTableType[] = queryResults.map(
    (queryResult) => {
      const {
        _id: id,
        fix_book_date_range: fixBookDateRange,
        exam_date: examDate,
        hospital_name: hospitalName,
        modality: modalityNum,
        status: statusNum,
        attending_hospital_user_id: attendingHospitalUserId,
        mri_dicom_image_path: mriDicomImagePath,
        doctor_review: doctorReview,
        doctor_note: doctorNote,
        hospital_id: hospitalId,
        mri_status: mriStatus,
        pet_status: petStatus,
        contraindications,
        mri_precautions: mriPrecautions,
        pet_precautions: petPrecautions,
      } = queryResult;

      return {
        id,
        fixBookDateRange,
        examDate,
        hospitalName,
        modalityNum,
        modalityStr: _convertModalityStr(modalityNum),
        statusNum,
        statusStr: _convertHospitalInspectionStatusStr(statusNum),
        attendingHospitalUserId,
        mriDicomImagePath,
        doctorReview,
        doctorNote,
        hospitalId,
        mriStatus,
        petStatus,
        contraindications,
        mriPrecautions,
        petPrecautions,
      };
    },
  );

  return convertResults;
};

export const convertMid002HospitalUser = (
  queryResults: Mid002HospitalUserStateType[],
) => {
  const convertResults: SelectBoxType[] = queryResults.map((queryResult) => {
    const { _id: id, full_name: fullName } = queryResult;

    return {
      label: fullName,
      value: id,
    };
  });

  return convertResults;
};

export const convertNlc019Payments = (
  queryResults: paymentsNlc019StateType[],
) => {
  const convertResults: Nlc019PaymentsType[] = queryResults.map(
    (queryResult) => {
      const {
        _id: id,
        payment_date: paymentDate,
        amount: amountNum,
        category: categoryNum,
        subscription_span: subscriptionSpan,
        authorization_date: authorizationDate,
        status: statusNum,
        stripe_payment_intent: stripePaymentIntent,
      } = queryResult;

      let category = "";
      switch (categoryNum) {
        case SalesCategoryID.PET:
          category = "PET検査";
          break;
        case SalesCategoryID.INTERVIEW:
          category = "面談";
          break;
        case SalesCategoryID.PRODUCT_PURCHASE:
          category = "EC";
          break;
        default:
          category =
            subscriptionSpan === PlanSpanID.YEAR
              ? "年払いコース"
              : "月払いコース";
          break;
      }

      const statusStr = _convertSalesStatusStr(statusNum);

      return {
        id,
        paymentDate,
        amount: _convertToCurrency(amountNum),
        category,
        authorizationDate,
        stripePaymentIntent: stripePaymentIntent || "-",
        statusStr,
        statusNum,
      };
    },
  );

  return convertResults;
};

export const convertNlc023Orders = (queryResults: ordersNlf001StateType[]) => {
  const convertResults: Nlc023OrdersType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      status: statusNum,
      settlement_time: settlementTime,
      total_amount: totalAmountBigInt,
      updated_at: updatedAt,
    } = queryResult;

    const renewal = _convertDateDifferenceToCurrent(updatedAt);
    const totalAmount = _convertToCurrency(totalAmountBigInt);
    const status = _convertOrderStatusStr(statusNum);

    return {
      id,
      renewal,
      totalAmount,
      status,
      settlementTime,
    };
  });

  return convertResults;
};

export const convertEventHistories = (
  queryResults: EventHistoriesStateType[],
) => {
  const convertResults: EventHistoriesDisplayType[] = queryResults.map(
    (queryResult) => {
      const {
        _id: id,
        description,
        priority: priorityNum,
        registered_date_display: registeredDateDisplay,
        registered_user_name: registeredUserName,
      } = queryResult;

      return {
        id,
        registeredDateDisplay,
        priorityStr: _convertEventHistoriesPriorityStr(priorityNum),
        priorityNum,
        description,
        registeredUserName,
      };
    },
  );

  return convertResults;
};

export const convertMedicalRecords = (
  queryResults: MedicalRecordStateType[],
) => {
  const convertResults: MedicalRecordTableType[] = queryResults.map(
    (queryResult) => {
      const {
        _id: id,
        description,
        admin_user_name: adminUserName,
        created_at: createdAt,
      } = queryResult;

      return {
        id,
        description,
        adminUserName,
        createdAt: formatDate(createdAt, FORMAT_STYLE["YYYY/MM/DD HH:MM"]),
      };
    },
  );

  return convertResults;
};

export const convertNli001 = (queryResults: MasterRolesStateType[]) => {
  const groupedByCategory1 = queryResults.reduce<{
    [key: string]: MasterRolesStateType[];
  }>((acc, curr) => {
    (acc[curr.category1] = acc[curr.category1] || []).push(curr);

    return acc;
  }, {});

  return Object.entries(groupedByCategory1).map(([category1, items1]) => {
    const groupedByCategory2 = items1.reduce<{
      [key: string]: MasterRolesStateType[];
    }>((acc, curr) => {
      (acc[curr.category2] = acc[curr.category2] || []).push(curr);

      return acc;
    }, {});

    return {
      category1,
      category2: Object.entries(groupedByCategory2).map(
        ([category2, items2]) => ({
          value: category2,
          details: items2.map((item) => ({
            id: item._id,
            categoryFunc: item.category_func,
            hasAdmin: item.has_admin,
            hasDoctor: item.has_doctor,
            hasUser: item.has_user,
          })),
        }),
      ),
    };
  });
};

export const convertNlb001Task = (queryResults: Nlb001TaskStateType[]) => {
  const convertResults: Nlb001TaskDisplayType[] = queryResults.map(
    (queryResult) => {
      const {
        _id: id,
        patient_id: patientId,
        patient_name: patientName,
        category,
        updated_at: updatedAt,
      } = queryResult;

      // 画面表示用に変換
      const categoryObj = _convertTask(category);

      return {
        id,
        patientId,
        patientName,
        tag: categoryObj.tag,
        description: categoryObj.description,
        updatedAt,
        category,
      };
    },
  );

  return convertResults;
};

export const convertNlc021 = (interviewInfo: Nlc021StateType | null) => {
  if (interviewInfo) {
    const {
      birth,
      name,
      created_at: createdAt,
      gender,
      tel,
      email,
      status: statusNum,
      zoom_start_link: zoomStartLink,
      candidate_interview_dates: candidateInterviewDates,
      interview_fix_date_start: interviewFixDateStart,
      interview_fix_date_end: interviewFixDateEnd,
      interview_fix_date_start_date: interviewFixDateStartDate,
      interview_fix_date_end_date: interviewFixDateEndDate,
      doctor_user_id: doctorUserId,
      doctor_name: doctorName,
      doctor_zoom_user_id: doctorZoomUserId,
    } = interviewInfo;

    const currentStatus = statusNum as keyof typeof InterviewStatusLabels;
    const status = InterviewStatusLabels[currentStatus];

    return {
      birth,
      name,
      createdAt,
      gender: _convertGender(gender),
      tel,
      email,
      status,
      statusNum,
      zoomStartLink,
      candidateInterviewDates,
      interviewFixDateStart,
      interviewFixDateEnd,
      interviewFixDateStartDate,
      interviewFixDateEndDate,
      doctorUserId,
      doctorName,
      doctorZoomUserId,
    };
  }

  return {
    birth: "",
    name: "",
    createdAt: "",
    gender: "",
    tel: "",
    email: "",
    status: "",
    statusNum: 99,
    zoomStartLink: "",
    candidateInterviewDates: {},
    interviewFixDateStart: "",
    interviewFixDateEnd: "",
    interviewFixDateStartDate: undefined,
    interviewFixDateEndDate: undefined,
    doctorUserId: "",
    doctorName: "",
    doctorZoomUserId: "",
  };
};

export const convertNlc021CandidateDates = (
  status: number,
  interviewFixDateStart: string | undefined,
  candidateDates?: {
    first?: string;
    second?: string;
    third?: string;
    fourth?: string;
    fifth?: string;
  },
) => {
  if (!candidateDates) return [];

  const numberSymbols = ["①", "②", "③", "④", "⑤"];
  const orderKeys: (keyof typeof candidateDates)[] = [
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
  ];

  // 元の日付
  const originFixDateStart = interviewFixDateStart
    ? `${interviewFixDateStart.substring(
        0,
        interviewFixDateStart.length - 2,
      )}00`
    : "";

  const convertCandidateDates = orderKeys
    .filter((key) => candidateDates[key])
    .map((key, index) => {
      const dateObj = new Date(candidateDates[key]!);
      const endTimeObj = new Date(dateObj);
      endTimeObj.setMinutes(dateObj.getMinutes() + 60);
      const options: Intl.DateTimeFormatOptions = {
        month: "2-digit",
        day: "numeric",
        weekday: "short",
        hour: "2-digit",
        minute: "2-digit",
      };
      const formattedStartDate = dateObj.toLocaleDateString("ja-JP", options);
      const formattedEndDate = endTimeObj.toLocaleTimeString("ja-JP", {
        hour: "2-digit",
        minute: "2-digit",
      });

      return {
        label: `${numberSymbols[index]} ${formattedStartDate}～${formattedEndDate}`,
        value: candidateDates[key]!,
        disabled:
          (status === PatientInterviewStatusID.CONFIRMED_RESERVATION ||
            status === PatientInterviewStatusID.SETTLED) &&
          candidateDates[key] !== originFixDateStart,
      };
    });

  return convertCandidateDates;
};

export const convertNlc035Table = (queryResults: PatientNlc035StateType[]) => {
  const convertResults: Nlc035TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      full_name_slash: name,
      patient_type: patientTypeNum,
      status: statusNum,
      tel,
      email,
      user_id: userId,
    } = queryResult;

    const patientTypeStr = convertPatientType(patientTypeNum);
    const statusStr = convertUserStatus(statusNum);

    return {
      id,
      name,
      email,
      tel,
      patientTypeNum,
      patientTypeStr,
      statusNum,
      statusStr,
      userId,
      checked: false,
    };
  });

  return convertResults;
};

export const convertNlc033Table = (queryResults: PatientNlc033StateType[]) => {
  const convertResults: Nlc033TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      patient_id: patientId,
      full_name_slash: name,
      comment,
      commented_user: commentedUser,
      register_date: registerDate,
      is_read: isRead,
      check_list: checkList,
      user_id: userId,
    } = queryResult;

    return {
      id,
      patientId,
      name,
      comment,
      commentedUser,
      registerDate,
      isRead,
      checkList,
      userId,
      checked: false,
    };
  });

  return convertResults;
};

export const convertNlc029Table = (queryResults: Nlc029StateType[]) => {
  const convertResults: Nlc029TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      userId,
      displayUserId,
      name,
      status: statusNum,
      examTermStr,
      tel,
      email,
    } = queryResult;

    const statusStr = convertStatusStr(
      statusNum,
      SEARCH_INFO.NLC029,
      PatientBrainCheckStatusID,
    );

    return {
      id,
      userId,
      displayUserId,
      name,
      statusNum,
      statusStr,
      examTermStr,
      tel,
      email,
      checked: false,
    };
  });

  return convertResults;
};

export const convertChildPatients = (queryResults: ChildPatientStateType[]) => {
  const convertResults: ChildPatientDisplayType[] = queryResults.map(
    (queryResult) => {
      const {
        _id: id,
        user_id: userId,
        name,
        activationDate,
        planName,
        status: statusNum,
        patientType: patientTypeNum,
        span,
        is_enabled_modality: isEnabledModality,
        price: priceNum,
        nextBillingDateStr,
      } = queryResult;

      const statusStr = convertUserStatus(statusNum);

      return {
        id,
        name,
        userId,
        activationDate,
        planName,
        statusNum,
        statusStr,
        planType:
          patientTypeNum === PatientTypeID.MEMBER ||
          patientTypeNum === PatientTypeID.ORG ||
          patientTypeNum === PatientTypeID.PRESENT
            ? `${
                span === PlanSpanID.YEAR ? "年払いコース:" : "月払いコース:"
              }${_convertToCommaSeparatedString(priceNum)}円${
                span === PlanSpanID.YEAR ? "" : "/月"
              }`
            : "",
        mriPetConditions: isEnabledModality ? "当てはまる" : "当てはまらない",
        nextBillingDateStr,
      };
    },
  );

  return convertResults;
};
