import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Sheet from "../components/Sheet/Sheet";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import Label from "../components/FormSet/Label";
import List from "../components/List/List";
import Dropdown from "../components/Dropdown/Dropdown";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import ListItem from "../components/List/ListItem";
import Divider from "../components/Divider/Divider";
import {
  INSPECT_PET_STATUS_OPTIONS,
  MASTER_MANAGEMENT_OPTIONS,
  PatientTypeID,
  PetStatusID,
  PetStatusLabels,
  TEST_RESULT_ITEMS,
  USER_EVALUATION,
  UserStatusID,
} from "../constants/common";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import useAdminUserDetailTabFetch from "../hooks/useAdminUserDetailTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  getPetStatusTagState,
  handleSelectMainTab,
  handleSelectMasterInfo,
  useHandleSelectAdminUserDetailTab,
  useHandleTestResultSubTab,
} from "../utils/utils";
import { ADMIN } from "../constants/pagePaths";
import useEvaluationDbActions from "../hooks/common/useEvaluationDbActions";
import {
  _convertToCurrencyJpnYen,
  convertPatientInfo,
} from "../utils/convertDisplay";
import usePatientInfoFetch from "../hooks/usePatientInfoFetch";
import useNlc017Conditions, {
  setSelectPetId,
  setStatus,
} from "../hooks/pages/NLC017/useNlc017Conditions";
import Pagination from "../components/Pagination/Pagination";
import EventHistories from "../features/EventHistories";
import UserDetailHeader from "../features/UserDetailHeader";
import InformationDialog from "../components/Dialog/InformationDialog";
import useNlc017Dialog, {
  closeNlc017CancelDialog,
  closeNlc017SendDialog,
  openNlc017CancelDialog,
  openNlc017SendDialog,
} from "../hooks/pages/NLC017/useNlc017Dialog";
import useNlc017PetFetch from "../hooks/pages/NLC017/useNlc017PetFetch";
import useNlc017DBActions from "../hooks/pages/NLC017/useNlc017DBActions";
import Chat from "../features/Chat";
import MedicalRecord from "../features/MedicalRecord";
import useUserDetailTabConditions from "../hooks/commonCondition/useUserDetailTabConditions";
import useNlc028Dialog, {
  openNlc028Dialog,
} from "../hooks/pages/NLC028/useNlc028Dialog";
import NLC028 from "./NLC028";
import RadioButton from "../components/RadioButton/RadioButton";

const NLC017 = () => {
  const masterManagementButtonRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("3");

  const [{ status }] = useNlc017Conditions();
  const [isNlc028Open] = useNlc028Dialog();
  const [{ isCancelConfirm, isSendConfirm }] = useNlc017Dialog();
  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();
  const { userDetailTabError, userDetailTabItems } =
    useAdminUserDetailTabFetch();
  const { error: patientFetchError, patientInfo } = usePatientInfoFetch();
  const [{ userDetailTab }] = useUserDetailTabConditions();
  const { error: updateEvaluationError, handleUpdateEvaluation } =
    useEvaluationDbActions();
  const { petTestResult, error: fetchError } = useNlc017PetFetch();
  const { sendResult, cancelReservation, actionError } = useNlc017DBActions();

  const {
    id,
    userId,
    name,
    birth,
    genderStr,
    tel,
    email,
    address,
    evaluation,
    patientTypeNum,
    patientTypeStr,
    statusNum,
    statusStr,
    orgName,
  } = convertPatientInfo(patientInfo);

  useCheckErrorThrowError([
    mainTabError,
    userDetailTabError,
    patientFetchError,
    updateEvaluationError,
    fetchError,
    actionError,
  ]);

  useEffect(() => {
    setSelectedValue(evaluation);
  }, [evaluation]);

  const handleSendPetResult = (id: string) => {
    setSelectPetId(id);
    openNlc017SendDialog();
  };

  const handleCancelReservation = (id: string) => {
    setSelectPetId(id);
    openNlc017CancelDialog();
  };

  // ユーザ評価選択
  const handleChangeEvaluation = React.useCallback(
    (value: string) => {
      // ユーザ評価更新
      handleUpdateEvaluation(id, value);
      setSelectedValue(value);
    },
    [handleUpdateEvaluation, id],
  );

  const handleSelectAdminUserDetailTab = useHandleSelectAdminUserDetailTab();
  const handleTestResultSubTab = useHandleTestResultSubTab();

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={2}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div
                  ref={masterManagementButtonRef}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={masterManagementButtonRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-user__wrapper">
          <div className="admin-user__info">
            <LayoutBox direction="column" gap="2x">
              <UserDetailHeader
                patientInfo={patientInfo}
                backPath={ADMIN.NLC006}
              />
              <Sheet className="util-px-24 util-py-24">
                <LayoutBox direction="column" gap="4x">
                  <div className="admin-user__profile">
                    <LayoutBox direction="column" gap="2x">
                      <LayoutBox direction="column" fullWidth>
                        <LayoutBox align="center">
                          <span className="util-font-neutral--light font-component-chip">
                            {userId}
                          </span>
                          <Tag
                            showIcon={false}
                            label={patientTypeStr}
                            state={
                              patientTypeNum === PatientTypeID.MEMBER
                                ? "information"
                                : patientTypeNum === PatientTypeID.ORG
                                ? "success"
                                : patientTypeNum === PatientTypeID.INVITATION
                                ? "warning"
                                : "neutral"
                            }
                          />
                          <Tag
                            showIcon={false}
                            label={statusStr}
                            state={
                              statusNum === UserStatusID.ACTIVE ||
                              statusNum === UserStatusID.INVITATION
                                ? "success"
                                : statusNum === UserStatusID.CANCELLATION
                                ? "error"
                                : statusNum === UserStatusID.SUSPENDED
                                ? "warning"
                                : "neutral"
                            }
                          />
                        </LayoutBox>
                        <LayoutBox fullWidth align="center">
                          <div className="util-half-width">
                            <Text size="2xl" bold>
                              {name}
                            </Text>
                          </div>
                          {patientTypeNum !== PatientTypeID.INVITATION && (
                            <div className="util-half-width">
                              <LayoutBox align="center">
                                <Label base size="medium" width="120px">
                                  認知症リスク
                                  <br />
                                  評価
                                </Label>
                                <div className="util-flex util-flex--wrap util-flex--col-gap16">
                                  {USER_EVALUATION.map((item) => (
                                    <RadioButton
                                      key={item.value}
                                      name="userRatingRadioButton"
                                      value={item.value}
                                      checked={selectedValue === item.value}
                                      onSelect={handleChangeEvaluation}
                                    >
                                      {item.label}
                                    </RadioButton>
                                  ))}
                                </div>
                              </LayoutBox>
                            </div>
                          )}
                        </LayoutBox>
                      </LayoutBox>
                      <Divider margin="0px" />
                      <div className="util-full-width">
                        <LayoutBox direction="column">
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    生年月日
                                  </Label>
                                  {birth}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    性別
                                  </Label>
                                  {genderStr}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    電話番号
                                  </Label>
                                  {tel}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="stretch">
                                  <Label base size="medium" width="120px">
                                    メールアドレス
                                  </Label>
                                  <LayoutBox align="center" justify="center">
                                    <Text>{email}</Text>
                                  </LayoutBox>
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="stretch">
                                  <Label base size="medium" width="120px">
                                    住所1
                                  </Label>
                                  <LayoutBox align="center" justify="center">
                                    {address}
                                  </LayoutBox>
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    団体
                                  </Label>
                                  {orgName}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                  <div className="util-full-width">
                    <LayoutBox direction="column" gap="2x">
                      <Tabs
                        selectedTab={2}
                        tabs={userDetailTabItems}
                        size="medium"
                        bottomBorder
                        onClick={handleSelectAdminUserDetailTab}
                      />
                      <ToggleButton
                        selectedButton="3"
                        items={TEST_RESULT_ITEMS}
                        onClick={handleTestResultSubTab}
                      />
                      <LayoutBox gap="2x">
                        <div className="admin-user__info-detail--footer-select">
                          <span className="font-component-button-small">
                            ステータス
                          </span>
                          <Dropdown
                            value={status.toString()}
                            width="148px"
                            items={INSPECT_PET_STATUS_OPTIONS}
                            onChange={setStatus}
                          />
                        </div>
                      </LayoutBox>
                      <div className="admin-user__inspection-results">
                        <List height="100%">
                          {petTestResult.map((petResultInfo) => (
                            <ListItem
                              key={petResultInfo._id}
                              id={petResultInfo._id}
                              center={
                                <>
                                  <LayoutBox direction="column" fullWidth>
                                    <LayoutBox fullWidth direction="row">
                                      <Text size="small" color="neutralLight">
                                        受検日：{petResultInfo.date}{" "}
                                        {petResultInfo.hospital}
                                      </Text>
                                      {petResultInfo.servicePrice.pet && (
                                        <Text size="small">
                                          検査費用：
                                          {_convertToCurrencyJpnYen(
                                            petResultInfo.servicePrice.pet,
                                          )}
                                        </Text>
                                      )}
                                      <Tag
                                        label={
                                          PetStatusLabels[
                                            petResultInfo.tagLabel as keyof typeof PetStatusLabels
                                          ]
                                        }
                                        showIcon={false}
                                        state={getPetStatusTagState(
                                          PetStatusLabels[
                                            petResultInfo.tagLabel as keyof typeof PetStatusLabels
                                          ],
                                        )}
                                      />
                                    </LayoutBox>
                                    <Text size="large" bold>
                                      FDG PET検査
                                    </Text>
                                  </LayoutBox>
                                </>
                              }
                              right={
                                <LayoutBox
                                  align="end"
                                  fullWidth
                                  direction="row"
                                >
                                  {[
                                    PetStatusID.RESERVED,
                                    PetStatusID.RESERVATION_CONFIRMED,
                                    PetStatusID.NO_EXAMINATION,
                                  ].includes(petResultInfo.tagLabel) && (
                                    <Button
                                      type="secondary"
                                      size="medium"
                                      color="danger"
                                      onClick={() =>
                                        handleCancelReservation(
                                          petResultInfo._id,
                                        )
                                      }
                                    >
                                      予約取消
                                    </Button>
                                  )}
                                  {[PetStatusID.DIAGNOSED].includes(
                                    petResultInfo.tagLabel,
                                  ) && (
                                    <Button
                                      type="primary"
                                      size="medium"
                                      color="neutral"
                                      onClick={() =>
                                        handleSendPetResult(petResultInfo._id)
                                      }
                                    >
                                      結果送信
                                    </Button>
                                  )}
                                  {[
                                    PetStatusID.EXAMINED,
                                    PetStatusID.DIAGNOSED,
                                    PetStatusID.USER_REPORTED,
                                  ].includes(petResultInfo.tagLabel) && (
                                    <Button
                                      type="secondary"
                                      size="medium"
                                      color="neutral"
                                      onClick={() => {
                                        openNlc028Dialog(petResultInfo._id);
                                      }}
                                    >
                                      結果
                                    </Button>
                                  )}
                                </LayoutBox>
                              }
                            />
                          ))}
                        </List>
                        <div className="util-mb-16" />
                        {petTestResult.length === 0 && (
                          <div className="util-py-40">
                            <LayoutBox
                              direction="column"
                              align="center"
                              justify="center"
                            >
                              <Icon icon="inbox" />
                              <div className="util-font-neutral--light">
                                表示する結果がありません
                              </div>
                            </LayoutBox>
                          </div>
                        )}
                        <LayoutBox justify="end" fullWidth>
                          <Pagination input={false} />
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </div>
          {userDetailTab === 0 && <EventHistories />}
          {userDetailTab === 1 && <Chat />}
          {userDetailTab === 2 && <MedicalRecord />}
        </div>
      </div>
      {isSendConfirm && (
        <InformationDialog
          alertLevel="normal"
          open={isSendConfirm}
          closeDialog={() => closeNlc017SendDialog()}
          title="結果を送信しますか？"
          footer={
            <LayoutBox>
              <Button color="main" size="medium" onClick={() => sendResult()}>
                OK
              </Button>
              <Button
                color="neutral"
                type="sub"
                size="medium"
                onClick={() => closeNlc017SendDialog()}
              >
                キャンセル
              </Button>
            </LayoutBox>
          }
        >
          <></>
        </InformationDialog>
      )}
      {isCancelConfirm && (
        <InformationDialog
          alertLevel="error"
          open={isCancelConfirm}
          closeDialog={closeNlc017CancelDialog}
          title="予約を取り消してもよろしいですか？"
          footer={
            <LayoutBox>
              <Button color="danger" size="medium" onClick={cancelReservation}>
                予約取消
              </Button>
              <Button
                color="neutral"
                type="sub"
                size="medium"
                onClick={closeNlc017CancelDialog}
              >
                キャンセル
              </Button>
            </LayoutBox>
          }
        >
          <></>
        </InformationDialog>
      )}
      {isNlc028Open && <NLC028 />}
    </>
  );
};

export default NLC017;
