import { useState, useCallback } from "react";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";
import NLC025 from "../pages/NLC025";
import NLC026 from "../pages/NLC026";
import { convertPatientInfo } from "../utils/convertDisplay";
import { UserStatusID } from "../constants/common";

type UserDetailHeaderProps = {
  patientInfo: PatientsType | null;
  backPath: string;
};

const UserDetailHeader: React.FC<UserDetailHeaderProps> = ({
  patientInfo,
  backPath,
}) => {
  const {
    name,
    birth,
    genderStr,
    tel,
    email,
    planName,
    planType,
    address,
    statusNum,
  } = convertPatientInfo(patientInfo);

  const [isCancellationDialogOpen, setIsCancellationDialogOpen] =
    useState(false);
  const [isUserEditDialogOpen, setIsUserEditDialogOpen] = useState(false);

  // 戻るボタン押下
  const handleGoBack = useNavigateWithBack(backPath);
  // 解約ボタン押下
  const handleCancellation = useCallback(() => {
    setIsCancellationDialogOpen(true);
  }, []);
  // ユーザ情報を編集ボタン押下
  const handleUserEdit = useCallback(() => {
    setIsUserEditDialogOpen(true);
  }, []);

  return (
    <>
      <LayoutBox justify="between" fullWidth>
        <Button
          type="sub"
          size="large"
          icon="arrow_back"
          onClick={handleGoBack}
        >
          一覧へ戻る
        </Button>
        <LayoutBox>
          <Button
            type="secondary"
            size="large"
            color="danger"
            icon="block"
            onClick={handleCancellation}
            disabled={UserStatusID.CANCELLATION === statusNum}
          >
            解約
          </Button>
          <Button
            type="secondary"
            size="large"
            icon="edit"
            onClick={handleUserEdit}
          >
            会員情報を編集
          </Button>
        </LayoutBox>
      </LayoutBox>
      <>
        <NLC026
          isOpen={isCancellationDialogOpen}
          setIsOpen={setIsCancellationDialogOpen}
          name={name}
          genderStr={genderStr}
          birth={birth}
          tel={tel}
          email={email}
          plan={planName ? `${planName} ${planType}` : "-"}
          address={address}
        />
        {isUserEditDialogOpen && (
          <NLC025
            isOpen={isUserEditDialogOpen}
            setIsOpen={setIsUserEditDialogOpen}
            patientInfo={patientInfo}
          />
        )}
      </>
    </>
  );
};

export default UserDetailHeader;
