import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Sheet from "../components/Sheet/Sheet";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import Label from "../components/FormSet/Label";
import Input from "../components/Input/Input";
import List from "../components/List/List";
import Dropdown from "../components/Dropdown/Dropdown";
import ListItem from "../components/List/ListItem";
import FormSet from "../components/FormSet/FormSet";
import FileUpload, { FileType } from "../components/FileUpload/FileUpload";
import useHospitalMainTabFetch from "../hooks/useHospitalMainTabFetch";
import {
  getPrecautionsColor,
  getPrecautionsOpacity,
  handleSelectHospitalMainTab,
  handleSelectHospitalMasterInfo,
} from "../utils/utils";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  BULLET_LIST_ITEMS,
  HOSPITAL_MASTER_MANAGEMENT_OPTIONS,
  HospitalInspectionStatusID,
  ModalityID,
  MRI_PRECAUTIONS_ITEMS,
  MriStatusID,
  PET_PRECAUTIONS_ITEMS,
  PetStatusID,
} from "../constants/common";
import { HOSPITAL } from "../constants/pagePaths";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";
import useMid002DbActions from "../hooks/pages/MID002/useMid002DbActions";
import {
  convertMid002HospitalUser,
  convertMid002Modality,
  convertMid002User,
} from "../utils/convertDisplay";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import Accordion from "../components/Accordion/Accordion";
import BulletList from "../components/List/BulletList";
import useMid002Conditions from "../hooks/pages/MID002/useMid002Conditions";

const initUser = {
  id: "",
  fullNameSlash: "",
  userId: "",
  email: "",
  gender: "",
  tel: "",
  birthDay: "",
};

const initModality = {
  attendingHospitalUserId: "",
  fixBookDateRange: "",
  examDate: "",
  hospitalName: "",
  id: "",
  modalityStr: "",
  modalityNum: 999,
  mriDicomImagePath: "",
  doctorNote: "",
  doctorReview: "",
  statusNum: HospitalInspectionStatusID.NOT_INSPECTED,
  statusStr: "",
  hospitalId: "",
  mriStatus: 999,
  petStatus: 999,
  contraindications: false,
  mriPrecautions: [] as number[],
  petPrecautions: [] as number[],
};

const MID002 = () => {
  const [modalityId] = useMid002Conditions();
  const masterManagementButtonRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [uploadFiles, setUploadFiles] = React.useState<FileType[]>([]);
  const [fileUploadError, setFileUploadError] = React.useState("");
  const [selectedModalityId, setSelectedModalityId] =
    React.useState(modalityId);
  const [selectedModality, setSelectedModality] =
    useStateCustomObj(initModality);
  const [user, setUser] = useStateCustomObj(initUser);
  const [modalities, setModalities] = useStateCustomObj<
    Mid002ModalityTableType[]
  >([]);
  const [hospitalUserList, setHospitalUserList] = React.useState<
    SelectBoxType[]
  >([]);
  const {
    fetchUserResult,
    fetchModalityResult,
    fetchHospitalUserResult,
    updateComment,
    uploadFile,
    fetchError,
  } = useMid002DbActions();

  const [selectedTab, setSelectedTab] = React.useState(1);

  // 患者情報
  useEffect(() => {
    const convertUser = convertMid002User(fetchUserResult);
    setUser(convertUser);
  }, [fetchUserResult, setUser]);

  // 検査履歴
  useEffect(() => {
    const convertModalities = convertMid002Modality(fetchModalityResult);
    setModalities(convertModalities);

    // スケジュール画面から遷移の場合、値セット
    if (modalityId) {
      const matchingModality = convertModalities.find(
        (modality) => modality.id === modalityId,
      );
      if (matchingModality) {
        setSelectedModality(matchingModality);
      }
    }
  }, [fetchModalityResult, setModalities, modalityId, setSelectedModality]);

  // 担当医セレクトボックス
  useEffect(() => {
    const convertHospitalUserList = convertMid002HospitalUser(
      fetchHospitalUserResult,
    );
    setHospitalUserList(convertHospitalUserList);
  }, [fetchHospitalUserResult]);

  // 診断画像チェック
  useEffect(() => {
    setFileUploadError("");

    // 拡張子チェック
    uploadFiles.forEach((file) => {
      if (file.name.split(".").pop() !== "zip") {
        setFileUploadError("ファイルはzip形式でアップロードしてください");
      }
    });

    // 100MBをバイト単位で表現
    const maxFileSize = 100 * 1024 * 1024;
    // サイズチェック
    uploadFiles.forEach((file) => {
      if (file.size && file.size > maxFileSize) {
        setFileUploadError("ファイルサイズは最大100MBまでです");
      }
    });
  }, [uploadFiles]);

  const { error: mainTabError, tabItems: mainTabItems } =
    useHospitalMainTabFetch();

  useCheckErrorThrowError([mainTabError, fetchError]);

  // 検査履歴リスト押下
  const handleListItemClick = (modality: Mid002ModalityTableType) => {
    setSelectedModalityId(modality.id);
    setSelectedModality(modality);
  };

  // 担当医変更
  const handleChangeHospitalUser = (value: string) => {
    setSelectedModality((prevState) => ({
      ...prevState,
      attendingHospitalUserId: value,
    }));
  };

  // 医師所見変更
  const handleChangeReview = (value: string) => {
    setSelectedModality((prevState) => ({
      ...prevState,
      doctorReview: value,
    }));
  };

  // 備考変更
  const handleChangeDoctorNote = (value: string) => {
    setSelectedModality((prevState) => ({
      ...prevState,
      doctorNote: value,
    }));
  };

  // 一覧へ戻る
  const handleGoBack = useNavigateWithBack(HOSPITAL.MID001);

  // 登録ボタン押下
  const handleUpdateComment = () => {
    updateComment(
      selectedModalityId,
      selectedModality.attendingHospitalUserId,
      selectedModality.doctorReview,
      selectedModality.doctorNote,
    );
  };

  // アップロードボタン押下
  const handleUpload = async () => {
    await uploadFile(
      selectedModality,
      uploadFiles,
      setUploadFiles,
      setSelectedModality,
    );
  };

  // キャンセルボタン押下
  const handleInputCancel = () => {
    setSelectedModality(initModality);
    setSelectedModalityId("");
    setUploadFiles([]);
  };

  // アップロードエリア表示制御
  const isDisplayUpload = () => {
    if (
      selectedModality.modalityNum === ModalityID.MRI &&
      [
        MriStatusID.DONE_AI_DIAGNOSING,
        MriStatusID.DONE_AI_DIAGNOSED,
        MriStatusID.DONE_DOCTOR_DIAGNOSED,
        MriStatusID.USER_REPORTED,
      ].includes(selectedModality.mriStatus)
    ) {
      return false;
    }
    if (
      selectedModality.modalityNum === ModalityID.PET &&
      [PetStatusID.DIAGNOSED, PetStatusID.USER_REPORTED].includes(
        selectedModality.petStatus,
      )
    ) {
      return false;
    }

    return true;
  };

  const hasMriPrecautions = selectedModality.mriPrecautions?.length > 0;
  const hasPetPrecautions = selectedModality.petPrecautions?.length > 0;

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={2}
                tabs={mainTabItems}
                onClick={handleSelectHospitalMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div
                  ref={masterManagementButtonRef}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={masterManagementButtonRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={HOSPITAL_MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectHospitalMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-user__wrapper">
          <div className="admin-user__info">
            <LayoutBox direction="column" gap="2x">
              <Button
                type="sub"
                size="large"
                icon="arrow_back"
                onClick={handleGoBack}
              >
                一覧へ戻る
              </Button>
              <Sheet className="util-px-24 util-py-24">
                <LayoutBox direction="column" gap="4x">
                  <div className="admin-user__profile">
                    <LayoutBox direction="column" gap="2x">
                      <LayoutBox direction="column">
                        <span className="util-font-neutral--light font-component-chip">
                          {user.userId}
                        </span>
                        <Text size="2xl" bold>
                          {user.fullNameSlash}
                        </Text>
                      </LayoutBox>
                      <div className="util-full-width">
                        <LayoutBox direction="column">
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    生年月日
                                  </Label>
                                  {user.birthDay}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    性別
                                  </Label>
                                  {user.gender}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    電話番号
                                  </Label>
                                  {user.tel}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    メールアドレス
                                  </Label>
                                  {user.email}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                  <div className="util-full-width">
                    <LayoutBox direction="column" gap="2x">
                      <div className="admin-user__inspection-results">
                        <Sheet
                          type="header"
                          className="util-mb-16"
                          padding="8px"
                        >
                          <Text bold size="xl">
                            検査履歴
                          </Text>
                        </Sheet>
                        <List height="100%">
                          {modalities.map((modality) => (
                            <ListItem
                              key={modality.id}
                              id={modality.id}
                              isSelected={selectedModalityId === modality.id}
                              onClick={() => handleListItemClick(modality)}
                              center={
                                <>
                                  <LayoutBox direction="column" fullWidth>
                                    <LayoutBox fullWidth direction="row">
                                      <Text size="small" color="neutralLight">
                                        受検日：{modality.examDate}{" "}
                                        {modality.hospitalName}
                                      </Text>
                                      <Tag
                                        label={modality.statusStr}
                                        showIcon={false}
                                        state={
                                          modality.statusNum ===
                                          HospitalInspectionStatusID.NOT_INSPECTED
                                            ? "error"
                                            : "success"
                                        }
                                      />
                                    </LayoutBox>
                                    <Text size="large" bold>
                                      {modality.modalityStr}
                                    </Text>
                                  </LayoutBox>
                                </>
                              }
                            />
                          ))}
                        </List>
                      </div>
                    </LayoutBox>
                  </div>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </div>
          <div className="admin-user__info-detail  admin-user__info-detail--narrow">
            <div className="util-px-24 util-py-8 admin-user__info-detail--form">
              <LayoutBox direction="column" gap="2x" minWidth="476px">
                {selectedModality.modalityStr && (
                  <List height="100%">
                    <ListItem
                      id="1"
                      center={
                        <>
                          <LayoutBox direction="column" fullWidth>
                            <LayoutBox fullWidth direction="row">
                              <Text size="small" color="neutralLight">
                                予約日:{selectedModality.fixBookDateRange}
                              </Text>
                              {selectedModality.statusStr && (
                                <Tag
                                  label={selectedModality.statusStr}
                                  showIcon={false}
                                  state={
                                    selectedModality.statusNum ===
                                    HospitalInspectionStatusID.NOT_INSPECTED
                                      ? "error"
                                      : "success"
                                  }
                                />
                              )}
                            </LayoutBox>
                            <Text size="large" bold>
                              {selectedModality.modalityStr}
                            </Text>
                          </LayoutBox>
                        </>
                      }
                    />
                  </List>
                )}
                <Tabs
                  selectedTab={selectedTab}
                  tabs={[
                    { title: "事前ヒアリング", icon: "content_paste" },
                    { title: "診断画像", icon: "image" },
                    { title: "コメント", icon: "chat" },
                  ]}
                  size="medium"
                  bottomBorder
                  onClick={setSelectedTab}
                />
                {selectedTab === 0 &&
                  selectedModality.modalityNum === ModalityID.MRI && (
                    <LayoutBox fullHeight direction="column" fullWidth gap="4x">
                      <LayoutBox gap="2x" fullWidth direction="column">
                        <Sheet
                          type="header"
                          className="util-px-12 util-py-12"
                          padding="4px 8px"
                        >
                          <Text bold size="xl">
                            MRI検査の禁忌事項について
                          </Text>
                        </Sheet>

                        <LayoutBox direction="column" gap="1x" fullWidth>
                          <div className="util-mt-10 util-mb-10">
                            <Text>
                              {selectedModality.contraindications !== undefined
                                ? selectedModality.contraindications
                                  ? "当てはまらない"
                                  : "当てはまる"
                                : "当てはまらない"}
                            </Text>
                          </div>
                          <Accordion title="内容">
                            <LayoutBox direction="column" gap="2x">
                              <LayoutBox direction="column" gap="none">
                                <Text color="error">
                                  以下に当てはまる方はMRI検査を受検できません。
                                  <br />
                                  該当する項目がないことをご確認ください。
                                </Text>
                              </LayoutBox>
                              <Sheet
                                type="border-blue"
                                borderRadius="8px"
                                padding="16px"
                                borderWidth="1px"
                              >
                                <BulletList
                                  title="体内に下記の医療機器がある方"
                                  items={BULLET_LIST_ITEMS}
                                  color="blue"
                                />
                              </Sheet>
                            </LayoutBox>
                          </Accordion>
                        </LayoutBox>
                      </LayoutBox>
                      <LayoutBox gap="2x" fullWidth direction="column">
                        <Sheet
                          type="header"
                          className="util-px-12 util-py-12"
                          padding="4px 8px"
                        >
                          <Text bold size="xl">
                            MRI検査の注意事項について
                          </Text>
                        </Sheet>
                        {MRI_PRECAUTIONS_ITEMS.map((text, index) => {
                          const [mainText, subText] = text.split(" ");

                          return (
                            <LayoutBox
                              key={index}
                              gap="1x"
                              align="start"
                              opacity={getPrecautionsOpacity(
                                index,
                                selectedModality.mriPrecautions,
                              )}
                            >
                              <div className="util-py-2">
                                <Icon
                                  icon="check"
                                  size="xs"
                                  color={getPrecautionsColor(
                                    index,
                                    selectedModality.mriPrecautions,
                                  )}
                                />
                              </div>
                              <LayoutBox direction="column" gap="1/2x">
                                <Text>{mainText}</Text>
                                {subText && <Text>{subText}</Text>}
                              </LayoutBox>
                            </LayoutBox>
                          );
                        })}
                        <LayoutBox
                          gap="1x"
                          align="start"
                          opacity={hasMriPrecautions ? 1 : 0.4}
                        >
                          <div className="util-py-2">
                            <Icon
                              icon="check"
                              size="xs"
                              color={hasMriPrecautions ? "#096AE2" : "#BFC6CD"}
                            />
                          </div>
                          <Text>
                            上記に当てはまる項目がある場合、医療機関の判断により受検できない可能性があることを承諾する
                          </Text>
                        </LayoutBox>
                        <LayoutBox
                          gap="1x"
                          align="start"
                          opacity={hasMriPrecautions ? 0.4 : 1}
                        >
                          <div className="util-py-2">
                            <Icon
                              icon="check"
                              size="xs"
                              color={hasMriPrecautions ? "#BFC6CD" : "#096AE2"}
                            />
                          </div>
                          <Text>上記に当てはまる項目はない</Text>
                        </LayoutBox>
                      </LayoutBox>
                    </LayoutBox>
                  )}
                {selectedTab === 0 &&
                  selectedModality.modalityNum === ModalityID.PET && (
                    <LayoutBox gap="2x" fullWidth direction="column">
                      <Sheet
                        type="header"
                        className="util-px-12 util-py-12"
                        padding="4px 8px"
                      >
                        <Text bold size="xl">
                          PET検査の注意事項について
                        </Text>
                      </Sheet>
                      {PET_PRECAUTIONS_ITEMS.map((text, index) => {
                        const [mainText, subText] = text.split(" ");

                        return (
                          <LayoutBox
                            key={index}
                            gap="1x"
                            align="start"
                            opacity={getPrecautionsOpacity(
                              index,
                              selectedModality.petPrecautions,
                            )}
                          >
                            <div className="util-py-2">
                              <Icon
                                icon="check"
                                size="xs"
                                color={getPrecautionsColor(
                                  index,
                                  selectedModality.petPrecautions,
                                )}
                              />
                            </div>
                            <LayoutBox direction="column" gap="1/2x">
                              <Text>{mainText}</Text>
                              {subText && <Text>{subText}</Text>}
                            </LayoutBox>
                          </LayoutBox>
                        );
                      })}
                      <LayoutBox
                        gap="1x"
                        align="start"
                        opacity={hasPetPrecautions ? 1 : 0.4}
                      >
                        <div className="util-py-2">
                          <Icon
                            icon="check"
                            size="xs"
                            color={hasPetPrecautions ? "#096AE2" : "#BFC6CD"}
                          />
                        </div>
                        <Text>
                          上記に当てはまる項目がある場合、医療機関の判断により受検できない可能性があることを承諾する
                        </Text>
                      </LayoutBox>
                      <LayoutBox
                        gap="1x"
                        align="start"
                        opacity={hasPetPrecautions ? 0.4 : 1}
                      >
                        <div className="util-py-2">
                          <Icon
                            icon="check"
                            size="xs"
                            color={hasPetPrecautions ? "#BFC6CD" : "#096AE2"}
                          />
                        </div>
                        <Text>上記に当てはまる項目はない</Text>
                      </LayoutBox>
                    </LayoutBox>
                  )}
                {selectedTab === 1 &&
                  selectedModality.id &&
                  isDisplayUpload() && (
                    <LayoutBox fullHeight direction="column" fullWidth>
                      <FormSet label="診断画像アップロード" vertical>
                        <LayoutBox direction="column" gap="none">
                          <FileUpload
                            type="single"
                            fileType="application/zip"
                            uploadFiles={uploadFiles}
                            setUploadFiles={setUploadFiles}
                          />
                          <LayoutBox direction="column" gap="none">
                            <Text
                              lineHeight="16px"
                              size="2xs"
                              color="neutralLight"
                            >
                              最大アップロードサイズ:100MB
                            </Text>
                            <Text
                              lineHeight="16px"
                              color="neutralLight"
                              size="2xs"
                            >
                              ファイル形式:zip
                            </Text>
                          </LayoutBox>
                        </LayoutBox>
                      </FormSet>
                      <Text color="error">{fileUploadError}</Text>
                    </LayoutBox>
                  )}
                {selectedTab === 2 && (
                  <LayoutBox fullHeight direction="column" fullWidth gap="2x">
                    <FormSet label="担当スタッフ" vertical>
                      <Dropdown
                        width="180px"
                        items={hospitalUserList}
                        value={selectedModality.attendingHospitalUserId}
                        onChange={handleChangeHospitalUser}
                        disabled={!selectedModality.id}
                      />
                    </FormSet>
                    <FormSet label="医師所見" vertical>
                      <Input
                        multiLine
                        width="100%"
                        placeholder="内容"
                        value={selectedModality.doctorReview}
                        onChange={handleChangeReview}
                        disabled={!selectedModality.id}
                      />
                    </FormSet>
                    <FormSet label="備考" vertical>
                      <Input
                        multiLine
                        width="100%"
                        placeholder="内容"
                        value={selectedModality.doctorNote}
                        onChange={handleChangeDoctorNote}
                        disabled={!selectedModality.id}
                      />
                    </FormSet>
                  </LayoutBox>
                )}
              </LayoutBox>
            </div>
            {(selectedTab === 1 || selectedTab === 2) && (
              <div className="admin-user__info-detail--footer">
                <div className="admin-user__info-detail--footer-button">
                  <LayoutBox align="center" justify="center">
                    <Button
                      size="large"
                      color="neutral"
                      type="sub"
                      onClick={handleInputCancel}
                      disabled={!selectedModality.id}
                    >
                      キャンセル
                    </Button>
                    {selectedTab === 1 && (
                      <Button
                        size="large"
                        onClick={() => {
                          void handleUpload();
                        }}
                        disabled={
                          !selectedModality.id ||
                          uploadFiles.length < 1 ||
                          fileUploadError !== ""
                        }
                      >
                        アップロード
                      </Button>
                    )}
                    {selectedTab === 2 && (
                      <Button
                        size="large"
                        onClick={handleUpdateComment}
                        disabled={!selectedModality.id}
                      >
                        登録
                      </Button>
                    )}
                  </LayoutBox>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MID002;
