import React, { useEffect } from "react";

import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import Text from "../components/Text/Text";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import Checkbox from "../components/Checkbox/Checkbox";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Pagination from "../components/Pagination/Pagination";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import {
  ADMIN_PAYMENT_SUB_TAB_ITEMS,
  INIT_PAGE,
  INIT_SEARCH_CRITERIA,
  MASTER_MANAGEMENT_OPTIONS,
} from "../constants/common";
import {
  handleSelectMainTab,
  handleSelectMasterInfo,
  handleSelectPaymentSubTab,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { setPage } from "../hooks/component/pagination/usePagination";
import { convertNld005Table } from "../utils/convertDisplay";
import { useCheckErrorThrowError } from "../utils/checkError";
import DatePicker from "../components/DatePicker/DatePicker";
import useNld005DbActions from "../hooks/pages/NLD005/useNld005DbActions";
import useNld005Conditions from "../hooks/pages/NLD005/useNld005Conditions";
import commonValidation, { VALIDATION_TYPE } from "../utils/commonValidation";

const NLD005 = () => {
  const [conditions] = useNld005Conditions();
  const [users, setUsers] = React.useState<Nld005TableType[]>([]);
  const [sortExamples, setSortExample] = React.useState(conditions.sort);
  const [searchCriteria, setSearchCriteria] = React.useState(conditions.search);
  // 検索ボタンを押下したときの検索条件
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    conditions.search,
  );
  const [isCheckAllUserIds, setIsCheckAllUserIds] = React.useState(false);
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [authorizationStartDate, setAuthorizationStartDate] = React.useState<
    Date | undefined
  >(conditions.authorizationStartDate);
  const [authorizationEndDate, setAuthorizationEndDate] = React.useState<
    Date | undefined
  >(conditions.authorizationEndDate);
  const [paymentStartDate, setPaymentStartDate] = React.useState<
    Date | undefined
  >(conditions.paymentStartDate);
  const [paymentEndDate, setPaymentEndDate] = React.useState<Date | undefined>(
    conditions.paymentEndDate,
  );
  const [receiptIssueStartDate, setReceiptIssueStartDate] = React.useState<
    Date | undefined
  >(conditions.receiptIssueStartDate);
  const [receiptIssueEndDate, setReceiptIssueEndDate] = React.useState<
    Date | undefined
  >(conditions.receiptIssueEndDate);
  const [isMinAmountError, setIsMinAmountError] =
    React.useState<boolean>(false);
  const [isMaxAmountError, setIsMaxAmountError] =
    React.useState<boolean>(false);
  const [isErrorMessage, setIsErrorMessage] = React.useState<string>("");

  const { result, error: fetchError } = useNld005DbActions({
    sortExamples,
    submittedSearchCriteria,
  });

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  // エラー処理
  useCheckErrorThrowError([fetchError, mainTabError]);

  useEffect(() => {
    // データ取得時に表示用に変換
    const convertResult = convertNld005Table(result);
    setUsers(convertResult);
  }, [result]);

  const handleChangeId = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, id: value }));
  };

  const handleChangeName = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, name: value }));
  };

  const handleChangeCheckItem = (checked: boolean, checkedItem: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      status: {
        ...searchCriteria.status,
        [checkedItem]: checked,
      },
    }));
  };

  // 検索ボタン押下時のイベント
  const handleSearchUsers = () => {
    // 検索条件を確定
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // クリア
  const handleResetSearchCriteria = () => {
    setSearchCriteria(INIT_SEARCH_CRITERIA.NLD005);
    setAuthorizationStartDate(undefined);
    setAuthorizationEndDate(undefined);
    setReceiptIssueStartDate(undefined);
    setReceiptIssueEndDate(undefined);
    setIsMinAmountError(false);
    setIsMaxAmountError(false);
    setIsErrorMessage("");
  };

  // 請求日
  const handleChangeRangeAuthorizationDate = React.useCallback(
    (authorizationStartDate?: Date, authorizationEndDate?: Date) => {
      setAuthorizationStartDate(authorizationStartDate);
      setAuthorizationEndDate(authorizationEndDate);
      setSearchCriteria((prevState) => ({
        ...prevState,
        authorizationStartDate,
        authorizationEndDate,
      }));
    },
    [],
  );

  // 支払い日
  const handleChangeRangePaymentDate = React.useCallback(
    (paymentStartDate?: Date, paymentEndDate?: Date) => {
      setPaymentStartDate(paymentStartDate);
      setPaymentEndDate(paymentEndDate);
      setSearchCriteria((prevState) => ({
        ...prevState,
        paymentStartDate,
        paymentEndDate,
      }));
    },
    [],
  );

  // 支払日
  const handleChangeRangeReceiptIssueDate = React.useCallback(
    (receiptIssueStartDate?: Date, receiptIssueEndDate?: Date) => {
      setReceiptIssueStartDate(receiptIssueStartDate);
      setReceiptIssueEndDate(receiptIssueEndDate);
      setSearchCriteria((prevState) => ({
        ...prevState,
        receiptIssueStartDate,
        receiptIssueEndDate,
      }));
    },
    [],
  );

  // 合計金額バリデーション
  const handleChangeMinTotal = (value: string) => {
    const validatedValue = commonValidation(
      value,
      VALIDATION_TYPE.SEARCH_AMOUNT,
    );

    if (Number(value) > Number(searchCriteria.maxTotal)) {
      setIsMinAmountError(true);
      setIsMaxAmountError(true);
      setIsErrorMessage("金額範囲が不正です。");
      setSearchCriteria((prevState) => ({ ...prevState, minTotal: value }));
    } else {
      setIsMaxAmountError(false);
      setIsMinAmountError(validatedValue.isError);
      setIsErrorMessage(validatedValue.validationMessage);
      setSearchCriteria((prevState) => ({ ...prevState, minTotal: value }));
    }
  };
  const handleChangeMaxTotal = (value: string) => {
    const validatedValue = commonValidation(
      value,
      VALIDATION_TYPE.SEARCH_AMOUNT,
    );

    if (Number(searchCriteria.minTotal) > Number(value)) {
      setIsMinAmountError(true);
      setIsMaxAmountError(true);
      setIsErrorMessage("金額範囲が不正です。");
      setSearchCriteria((prevState) => ({ ...prevState, maxTotal: value }));
    } else {
      setIsMinAmountError(false);
      setIsMaxAmountError(validatedValue.isError);
      setIsErrorMessage(validatedValue.validationMessage);
      setSearchCriteria((prevState) => ({ ...prevState, maxTotal: value }));
    }
  };

  const handleCheckUserId = (checked: boolean, userId: string) => {
    const newUsers = users.map((user) => {
      if (user.id === userId) {
        return { ...user, checked };
      }

      return user;
    });

    setUsers(newUsers);
  };

  const handleCheckIsAllUserIds = (checked: boolean) => {
    setIsCheckAllUserIds(checked);
    const newUsers = users.map((user) => ({ ...user, checked }));
    setUsers(newUsers);
  };

  const isCheckEachId = React.useMemo(
    () => users.some((user) => user.checked === true),
    [users],
  );

  const isCheckAllId = React.useMemo(
    () => users.every((user) => user.checked === true),
    [users],
  );

  const isIndeterminate = React.useMemo(
    () =>
      (!isCheckAllUserIds && isCheckEachId) ||
      (isCheckAllUserIds && !isCheckAllId),
    [isCheckAllUserIds, isCheckEachId, isCheckAllId],
  );

  const onClickSort = (sortDirection: "asc" | "desc" | "", id: string) => {
    const newSortExamples = sortExamples.map((sortExample) => {
      if (sortExample.id === id) {
        return { ...sortExample, id, sortDirection };
      }

      return { ...sortExample, sortDirection: "" };
    });

    setSortExample(newSortExamples);
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={3}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(true)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <ToggleButton
              size="large"
              selectedButton="3"
              items={ADMIN_PAYMENT_SUB_TAB_ITEMS}
              onClick={handleSelectPaymentSubTab}
            />
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column">
                <div className="util-full-width">
                  <LayoutBox align="center" justify="between" gap="3x">
                    <FormSet label="ID" labelWidth="95px" base flex="1">
                      <Input
                        value={searchCriteria.id}
                        placeholder="ID"
                        width="100%"
                        onChange={handleChangeId}
                      />
                    </FormSet>
                    <FormSet label="お客様名" labelWidth="95px" base flex="1">
                      <Input
                        value={searchCriteria.name}
                        placeholder="お客様名"
                        width="100%"
                        onChange={handleChangeName}
                      />
                    </FormSet>
                    <FormSet
                      label="合計金額"
                      labelWidth="95px"
                      base
                      flex="1"
                      formVertical={false}
                      errorTextList={[isErrorMessage]}
                    >
                      <Input
                        value={searchCriteria.minTotal}
                        width="106px"
                        onChange={handleChangeMinTotal}
                        error={isMinAmountError}
                      />
                      <div className="util-px-8">
                        <Text>～</Text>
                      </div>
                      <Input
                        value={searchCriteria.maxTotal}
                        width="106px"
                        onChange={handleChangeMaxTotal}
                        error={isMaxAmountError}
                      />
                      <div className="util-px-8">
                        <Text>円</Text>
                      </div>
                    </FormSet>
                  </LayoutBox>
                </div>
                <div className="util-full-width">
                  <LayoutBox align="center" justify="between" gap="3x">
                    <FormSet label="請求日" labelWidth="95px" base flex="1">
                      <DatePicker
                        type="range"
                        placeholder="開始日"
                        placeholderEnd="終了日"
                        width="100%"
                        onChangeRangeDate={handleChangeRangeAuthorizationDate}
                        selectedDateRange={{
                          startDate: authorizationStartDate,
                          endDate: authorizationEndDate,
                        }}
                      />
                    </FormSet>
                    <FormSet label="支払日" labelWidth="95px" base flex="1">
                      <DatePicker
                        type="range"
                        placeholder="開始日"
                        placeholderEnd="終了日"
                        width="100%"
                        onChangeRangeDate={handleChangeRangePaymentDate}
                        selectedDateRange={{
                          startDate: paymentStartDate,
                          endDate: paymentEndDate,
                        }}
                      />
                    </FormSet>
                    <FormSet label="発行日時" labelWidth="95px" base flex="1">
                      <DatePicker
                        type="range"
                        placeholder="開始日"
                        placeholderEnd="終了日"
                        width="100%"
                        onChangeRangeDate={handleChangeRangeReceiptIssueDate}
                        selectedDateRange={{
                          startDate: receiptIssueStartDate,
                          endDate: receiptIssueEndDate,
                        }}
                      />
                    </FormSet>
                  </LayoutBox>
                </div>
                <div className="util-full-width">
                  <LayoutBox align="center" justify="between" gap="3x">
                    <FormSet label="項目" labelWidth="95px" base flex="1">
                      <LayoutBox
                        align="center"
                        justify="start"
                        gap="3x"
                        fullHeight
                      >
                        <Checkbox
                          label="会費"
                          checked={searchCriteria.status.fee}
                          onChecked={(checked) =>
                            handleChangeCheckItem(checked, "fee")
                          }
                        />
                        <Checkbox
                          label="PET検査"
                          checked={searchCriteria.status.isFDGPETScan}
                          onChecked={(checked) =>
                            handleChangeCheckItem(checked, "isFDGPETScan")
                          }
                        />
                        <Checkbox
                          label="面談"
                          checked={searchCriteria.status.interview}
                          onChecked={(checked) =>
                            handleChangeCheckItem(checked, "interview")
                          }
                        />
                        <Checkbox
                          label="EC"
                          checked={searchCriteria.status.ec}
                          onChecked={(checked) =>
                            handleChangeCheckItem(checked, "ec")
                          }
                        />
                      </LayoutBox>
                    </FormSet>
                  </LayoutBox>
                </div>
              </LayoutBox>
              <div className="util-mt-16">
                <LayoutBox align="center" justify="end">
                  <Button
                    type="sub"
                    color="neutral"
                    size="large"
                    onClick={handleResetSearchCriteria}
                  >
                    クリア
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={handleSearchUsers}
                    disabled={isMinAmountError || isMaxAmountError}
                  >
                    検索
                  </Button>
                </LayoutBox>
              </div>
            </Sheet>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column" gap="2x" justify="end">
                <Table
                  type="condensed"
                  width="100%"
                  head={
                    <TableRow>
                      <TableColumn
                        width="330px"
                        minWidth="217px"
                        canSort
                        sortedDirection={sortExamples[0].sortDirection}
                        id="col-1"
                        onClickSort={onClickSort}
                      >
                        <LayoutBox align="center" justify="start">
                          <Checkbox
                            checked={isCheckAllUserIds}
                            label="ID"
                            indeterminate={isIndeterminate}
                            onChecked={(checked) =>
                              handleCheckIsAllUserIds(checked)
                            }
                          />
                        </LayoutBox>
                      </TableColumn>
                      <TableColumn
                        width="330px"
                        minWidth="217px"
                        canSort
                        sortedDirection={sortExamples[1].sortDirection}
                        id="col-2"
                        onClickSort={onClickSort}
                      >
                        お客様名
                      </TableColumn>
                      <TableColumn
                        width="77px"
                        minWidth="170px"
                        canSort
                        sortedDirection={sortExamples[2].sortDirection}
                        id="col-4"
                        onClickSort={onClickSort}
                      >
                        項目
                      </TableColumn>
                      <TableColumn
                        width="330px"
                        id="col-5"
                        canSort
                        sortedDirection={sortExamples[3].sortDirection}
                        textAlign="right"
                        onClickSort={onClickSort}
                      >
                        合計金額
                      </TableColumn>
                      <TableColumn
                        width="330px"
                        id="col-6"
                        canSort
                        sortedDirection={sortExamples[4].sortDirection}
                        onClickSort={onClickSort}
                      >
                        請求日
                      </TableColumn>
                      <TableColumn
                        width="330px"
                        id="col-7"
                        canSort
                        sortedDirection={sortExamples[5].sortDirection}
                        onClickSort={onClickSort}
                      >
                        支払日
                      </TableColumn>
                      <TableColumn
                        width="330px"
                        id="col-8"
                        canSort
                        sortedDirection={sortExamples[6].sortDirection}
                        onClickSort={onClickSort}
                      >
                        発行日時
                      </TableColumn>
                      <TableColumn
                        width="77px"
                        minWidth="77px"
                        id="col-9"
                        textAlign="center"
                      >
                        領収書
                      </TableColumn>
                    </TableRow>
                  }
                  body={users.map((user) => (
                    <TableRow key={user.id} isSelected={user.checked}>
                      <TableCell>
                        <Checkbox
                          label={user.userId}
                          checked={user.checked}
                          onChecked={(checked) =>
                            handleCheckUserId(checked, user.id)
                          }
                        />
                      </TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.category}</TableCell>
                      <TableCell textAlign="right">{user.amount}</TableCell>
                      <TableCell>
                        {user.authorizationDate ? user.authorizationDate : "-"}
                      </TableCell>
                      <TableCell>
                        {user.paymentDate ? user.paymentDate : "-"}
                      </TableCell>
                      <TableCell>
                        {user.receiptIssueDate ? user.receiptIssueDate : "-"}{" "}
                      </TableCell>
                      <TableCell textAlign="center">
                        <Button type="sub" icon="open_in_new" size="small" />
                      </TableCell>
                    </TableRow>
                  ))}
                />
                <div className="util-full-width">
                  <LayoutBox justify="end">
                    <Pagination input={false} />
                  </LayoutBox>
                </div>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
    </>
  );
};

export default NLD005;
