import React from "react";
import DialogCustom from "../components/Dialog/DialogCustom";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import FormSet from "../components/FormSet/FormSet";
import Text from "../components/Text/Text";
import Sheet from "../components/Sheet/Sheet";
import Tag from "../components/Tag/Tag";
import FileUpload, { FileType } from "../components/FileUpload/FileUpload";
import Input from "../components/Input/Input";
import useNlc031Dialog, {
  closeNlc031Dialog,
} from "../hooks/pages/NLC031/useNlc031Dialog";
import usePatientInfoFetch from "../hooks/usePatientInfoFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import { convertPatientInfo } from "../utils/convertDisplay";
import useNlc031DbFetch from "../hooks/pages/NLC031/useNlc031DbFetch";
import useNlc031Conditions, {
  setBrainNote,
} from "../hooks/pages/NLC031/useNlc031Conditions";
import {
  PatientBrainCheckStatusID,
  PatientBrainCheckStatusLabels,
} from "../constants/common";
import { getBrainCheckTagState } from "../utils/utils";
import useNlc031DbActions from "../hooks/pages/NLC031/useNlc031DbActions";
import InformationDialog from "../components/Dialog/InformationDialog";
import useNlc031CancelDialog, {
  closeNlc031CancelDialog,
  openNlc031CancelDialog,
} from "../hooks/pages/NLC031/useNlc031CancelDialog";
import useSignedBrainCheckUrl from "../hooks/common/useSignedBrainCheckUrl";

const NLC031 = () => {
  const [uploadFiles, setUploadFiles] = React.useState<FileType[]>([]);

  const [isOpen] = useNlc031Dialog();
  const [isCancelDialogOpen] = useNlc031CancelDialog();
  const [brainCheckResult] = useNlc031Conditions();
  const { fetchError } = useNlc031DbFetch();
  const { error: patientFetchError, patientInfo } = usePatientInfoFetch();
  const { uploadFile, sendCancel, actionError } = useNlc031DbActions();
  const { fetchSignedBrainCheckUrl, signedBrainCheckError } =
    useSignedBrainCheckUrl();

  useCheckErrorThrowError([
    patientFetchError,
    fetchError,
    actionError,
    signedBrainCheckError,
  ]);

  const { id, name, birth, genderStr, tel, email } =
    convertPatientInfo(patientInfo);

  const handleConfirm = async () => {
    await uploadFile(uploadFiles, setUploadFiles);
  };

  const handleOpenReport = async () => {
    const signedUrl = await fetchSignedBrainCheckUrl(
      brainCheckResult.brainCheckId,
    );
    if (signedUrl) {
      window.open(signedUrl, "_blank");
    }
  };

  return (
    <>
      <DialogCustom
        title="結果"
        size="large"
        height="840px"
        open={isOpen}
        closeDialog={closeNlc031Dialog}
        footerRight={
          <LayoutBox gap="1x">
            <Button
              onClick={closeNlc031Dialog}
              color="neutral"
              type="sub"
              size="large"
              width="108px"
            >
              キャンセル
            </Button>
            {brainCheckResult.status ===
              PatientBrainCheckStatusID.NOT_TESTED && (
              <Button
                type="primary"
                size="large"
                width="96px"
                onClick={() => {
                  void handleConfirm();
                }}
                disabled={uploadFiles.length < 1}
              >
                確定
              </Button>
            )}
            {brainCheckResult.status === PatientBrainCheckStatusID.REPORTED && (
              <Button
                type="secondary"
                color="danger"
                size="large"
                width="128px"
                onClick={openNlc031CancelDialog}
              >
                結果送信取消
              </Button>
            )}
          </LayoutBox>
        }
      >
        <LayoutBox direction="column" gap="4x" fullWidth>
          <Sheet type="border-blue" padding="16px 24px">
            <LayoutBox direction="column" gap="2x" fullWidth>
              <LayoutBox direction="column" gap="2x" fullWidth>
                <LayoutBox align="center">
                  <Text color="neutralLight" size="small">
                    {id}
                  </Text>
                  <Tag
                    showIcon={false}
                    label={
                      PatientBrainCheckStatusLabels[
                        brainCheckResult.status as keyof typeof PatientBrainCheckStatusLabels
                      ]
                    }
                    state={getBrainCheckTagState(
                      PatientBrainCheckStatusLabels[
                        brainCheckResult.status as keyof typeof PatientBrainCheckStatusLabels
                      ],
                    )}
                  />
                </LayoutBox>
                <Text size="2xl" bold>
                  {name}
                </Text>
              </LayoutBox>
              <LayoutBox direction="column" gap="1x" fullWidth>
                <FormSet
                  labelWidth="120px"
                  labelSize="medium"
                  label="受験案内日"
                  base
                  formVertical={false}
                >
                  <LayoutBox align="center" fullWidth>
                    <Text size="small">{brainCheckResult.examStartDate}</Text>
                  </LayoutBox>
                </FormSet>
                <LayoutBox gap="3x" justify="between" fullWidth>
                  <FormSet
                    labelWidth="120px"
                    labelSize="medium"
                    label="生年月日"
                    base
                    formVertical={false}
                  >
                    <LayoutBox align="center" fullWidth>
                      <Text size="small">{birth}</Text>
                    </LayoutBox>
                  </FormSet>
                  <FormSet
                    labelWidth="120px"
                    labelSize="medium"
                    label="性別"
                    base
                    formVertical={false}
                  >
                    <LayoutBox align="center" fullWidth>
                      <Text size="small">{genderStr}</Text>
                    </LayoutBox>
                  </FormSet>
                </LayoutBox>
                <LayoutBox gap="3x" justify="between" fullWidth>
                  <FormSet
                    labelWidth="120px"
                    labelSize="medium"
                    label="電話番号"
                    base
                    formVertical={false}
                  >
                    <LayoutBox align="center" fullWidth>
                      <Text size="small">{tel}</Text>
                    </LayoutBox>
                  </FormSet>
                  <FormSet
                    labelWidth="120px"
                    labelSize="medium"
                    label="メールアドレス"
                    base
                    formVertical={false}
                  >
                    <LayoutBox align="center" fullWidth>
                      <Text size="small">{email}</Text>
                    </LayoutBox>
                  </FormSet>
                </LayoutBox>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
          <LayoutBox direction="column" gap="2x" fullWidth>
            <Sheet type="gray" padding="20px">
              <LayoutBox direction="column" gap="2x">
                <Sheet type="header" padding="4px 8px">
                  <Text size="xl" bold>
                    診断結果
                  </Text>
                </Sheet>
                {brainCheckResult.status ===
                  PatientBrainCheckStatusID.NOT_TESTED && (
                  <>
                    <FileUpload
                      setUploadFiles={setUploadFiles}
                      uploadFiles={uploadFiles}
                      draggable={false}
                      width="100%"
                      fileType="application/pdf"
                    />
                    <LayoutBox direction="column" gap="1/2x" fullWidth>
                      <Text size="xs" bold>
                        備考
                      </Text>
                      <Input
                        multiLine
                        width="100%"
                        multiLineHeight="88px"
                        placeholder="備考"
                        value={brainCheckResult.note}
                        onChange={setBrainNote}
                      />
                    </LayoutBox>
                  </>
                )}
                {[
                  PatientBrainCheckStatusID.COMPLETED,
                  PatientBrainCheckStatusID.REPORTED,
                ].includes(brainCheckResult.status) && (
                  <>
                    <Button
                      type="secondary"
                      onClick={() => {
                        void handleOpenReport();
                      }}
                    >
                      レポートを確認
                    </Button>
                    <LayoutBox direction="column" gap="1/2x" fullWidth>
                      <Text size="xs" bold>
                        備考
                      </Text>
                      <Text>{brainCheckResult.note ?? ""}</Text>
                      <Text color="neutralLight" size="small">
                        記入者：{brainCheckResult.commentedUserName}
                      </Text>
                    </LayoutBox>
                  </>
                )}
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </LayoutBox>
      </DialogCustom>
      {isCancelDialogOpen && (
        <InformationDialog
          alertLevel="error"
          open={isCancelDialogOpen}
          closeDialog={() => closeNlc031CancelDialog()}
          title="ユーザーに送信した結果を取り消しますか？"
          footer={
            <LayoutBox>
              <Button color="main" size="medium" onClick={() => sendCancel()}>
                OK
              </Button>
              <Button
                color="neutral"
                type="sub"
                size="medium"
                onClick={() => closeNlc031CancelDialog()}
              >
                キャンセル
              </Button>
            </LayoutBox>
          }
        >
          取り消したらユーザー側からも結果が表示されなくなります。
          <br />
          再度編集し、結果を送信してください。
        </InformationDialog>
      )}
    </>
  );
};

export default NLC031;
