import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Sheet from "../components/Sheet/Sheet";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import Label from "../components/FormSet/Label";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Divider from "../components/Divider/Divider";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import usePatientInfoFetch from "../hooks/usePatientInfoFetch";
import {
  convertNlc023Orders,
  convertPatientInfo,
} from "../utils/convertDisplay";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  handleSelectMainTab,
  handleSelectMasterInfo,
  useHandleSelectAdminUserDetailTab,
} from "../utils/utils";
import {
  INIT_EVALUATION,
  MASTER_MANAGEMENT_OPTIONS,
  PatientTypeID,
  ROLE_KEY,
  USER_EVALUATION,
  UserStatusID,
} from "../constants/common";
import useOrderFetch from "../hooks/useOrderFetch";
import NLC024 from "./NLC024";
import { setOrderId } from "../hooks/common/useOrderId";
import useEvaluationDbActions from "../hooks/common/useEvaluationDbActions";
import useAdminUserDetailTabFetch from "../hooks/useAdminUserDetailTabFetch";
import { ADMIN } from "../constants/pagePaths";
import EventHistories from "../features/EventHistories";
import UserDetailHeader from "../features/UserDetailHeader";
import Chat from "../features/Chat";
import MedicalRecord from "../features/MedicalRecord";
import useUserDetailTabConditions from "../hooks/commonCondition/useUserDetailTabConditions";
import RadioButton from "../components/RadioButton/RadioButton";

const NLC023 = () => {
  const masterManagementButtonRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(INIT_EVALUATION);
  const [isOrderDetailDialogOpen, setIsOrderDetailDialogOpen] =
    React.useState(false);

  // メインタブ
  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  // サブタブ
  const { userDetailTabError, userDetailTabItems } =
    useAdminUserDetailTabFetch();

  // 患者情報取得
  const { error: patientFetchError, patientInfo } = usePatientInfoFetch();
  // 注文履歴取得
  const { error: orderFetchError, ordersInfo } = useOrderFetch();

  const [{ userDetailTab }] = useUserDetailTabConditions();

  // ユーザ評価更新
  const { error: updateEvaluationError, handleUpdateEvaluation } =
    useEvaluationDbActions();

  const {
    id,
    userId,
    name,
    birth,
    genderStr,
    tel,
    email,
    address,
    evaluation,
    patientTypeNum,
    patientTypeStr,
    statusNum,
    statusStr,
    orgName,
  } = convertPatientInfo(patientInfo);
  const orders = convertNlc023Orders(ordersInfo);

  useCheckErrorThrowError([
    patientFetchError,
    orderFetchError,
    mainTabError,
    updateEvaluationError,
    userDetailTabError,
  ]);

  useEffect(() => {
    setSelectedValue(evaluation);
  }, [evaluation]);

  // ユーザ評価選択
  const handleChangeEvaluation = React.useCallback(
    (value: string) => {
      // ユーザ評価更新
      handleUpdateEvaluation(id, value);
      setSelectedValue(value);
    },
    [handleUpdateEvaluation, id],
  );

  // 詳細ボタン押下
  const handleOrderDetail = (id: string) => {
    setIsOrderDetailDialogOpen(true);
    setOrderId(id);
  };

  const handleSelectAdminUserDetailTab = useHandleSelectAdminUserDetailTab();

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={1}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div
                  ref={masterManagementButtonRef}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={masterManagementButtonRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-user__wrapper">
          <div className="admin-user__info">
            <LayoutBox direction="column" gap="2x">
              <UserDetailHeader
                patientInfo={patientInfo}
                backPath={ADMIN.NLC004}
              />
              <Sheet className="util-px-24 util-py-24">
                <LayoutBox direction="column" gap="4x">
                  <div className="admin-user__profile">
                    <LayoutBox direction="column" gap="2x">
                      <LayoutBox direction="column" fullWidth>
                        <LayoutBox align="center">
                          <span className="util-font-neutral--light font-component-chip">
                            {userId}
                          </span>
                          <Tag
                            showIcon={false}
                            label={patientTypeStr}
                            state={
                              patientTypeNum === PatientTypeID.MEMBER
                                ? "information"
                                : patientTypeNum === PatientTypeID.ORG
                                ? "success"
                                : patientTypeNum === PatientTypeID.INVITATION
                                ? "warning"
                                : "neutral"
                            }
                          />
                          <Tag
                            showIcon={false}
                            label={statusStr}
                            state={
                              statusNum === UserStatusID.ACTIVE ||
                              statusNum === UserStatusID.INVITATION
                                ? "success"
                                : statusNum === UserStatusID.CANCELLATION
                                ? "error"
                                : statusNum === UserStatusID.SUSPENDED
                                ? "warning"
                                : "neutral"
                            }
                          />
                        </LayoutBox>
                        <LayoutBox fullWidth align="center">
                          <div className="util-half-width">
                            <Text size="2xl" bold>
                              {name}
                            </Text>
                          </div>
                          {patientTypeNum !== PatientTypeID.INVITATION && (
                            <div className="util-half-width">
                              <LayoutBox align="stretch">
                                <Label base size="medium" width="120px">
                                  認知症リスク
                                  <br />
                                  評価
                                </Label>
                                <div className="util-flex util-flex--wrap util-flex--col-gap16">
                                  {USER_EVALUATION.map((item) => (
                                    <RadioButton
                                      key={item.value}
                                      name="userRatingRadioButton"
                                      value={item.value}
                                      checked={selectedValue === item.value}
                                      onSelect={handleChangeEvaluation}
                                    >
                                      {item.label}
                                    </RadioButton>
                                  ))}
                                </div>
                              </LayoutBox>
                            </div>
                          )}
                        </LayoutBox>
                      </LayoutBox>

                      <Divider margin="0px" />
                      <div className="util-full-width">
                        <LayoutBox direction="column">
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    生年月日
                                  </Label>
                                  {birth}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    性別
                                  </Label>
                                  {genderStr}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    電話番号
                                  </Label>
                                  {tel}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="stretch">
                                  <Label base size="medium" width="120px">
                                    メールアドレス
                                  </Label>
                                  <LayoutBox align="center" justify="center">
                                    <Text>{email}</Text>
                                  </LayoutBox>
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="stretch">
                                  <Label base size="medium" width="120px">
                                    住所1
                                  </Label>
                                  <LayoutBox align="center" justify="center">
                                    {address}
                                  </LayoutBox>
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    団体
                                  </Label>
                                  {orgName}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                  <div className="util-full-width">
                    <LayoutBox direction="column" gap="2x">
                      <Tabs
                        selectedTab={4}
                        tabs={userDetailTabItems}
                        size="medium"
                        bottomBorder
                        onClick={handleSelectAdminUserDetailTab}
                      />
                      <LayoutBox gap="2x">
                        <div className="admin-user__info-detail--footer-select" />
                      </LayoutBox>
                      <div className="admin-user__inspection-results">
                        <LayoutBox direction="column" gap="3x">
                          <div className="util-full-width">
                            <LayoutBox direction="column" gap="2x">
                              <LayoutBox direction="column" fullWidth>
                                <Sheet
                                  type="header"
                                  className="util-mb-16"
                                  padding="8px"
                                >
                                  <Text bold size="xl">
                                    注文
                                  </Text>
                                </Sheet>
                                <Table
                                  type="regular"
                                  width="100%"
                                  head={
                                    <TableRow>
                                      <TableColumn width="200px" id="col-1">
                                        Code
                                      </TableColumn>

                                      <TableColumn
                                        textAlign="left"
                                        width="200px"
                                        id="col-2"
                                      >
                                        ステータス
                                      </TableColumn>
                                      <TableColumn
                                        width="200px"
                                        textAlign="right"
                                        id="col-3"
                                      >
                                        合計
                                      </TableColumn>

                                      <TableColumn
                                        textAlign="left"
                                        width="200px"
                                        id="col-4"
                                      >
                                        更新
                                      </TableColumn>
                                      <TableColumn
                                        width="200px"
                                        textAlign="left"
                                        id="col-5"
                                      >
                                        注文日時
                                      </TableColumn>

                                      <TableColumn
                                        textAlign="right"
                                        width="107px"
                                        id="col-6"
                                      />
                                    </TableRow>
                                  }
                                  body={orders.map((order) => (
                                    <TableRow key={order.id}>
                                      <TableCell>{order.id}</TableCell>

                                      <TableCell textAlign="left">
                                        <Tag
                                          type="default"
                                          showIcon={false}
                                          label={order.status}
                                          state={
                                            order.status === "配送完了"
                                              ? "success"
                                              : order.status === "出荷済"
                                              ? "warning"
                                              : "error"
                                          }
                                        />
                                      </TableCell>
                                      <TableCell textAlign="right">
                                        {order.totalAmount}
                                      </TableCell>

                                      <TableCell textAlign="left">
                                        {order.renewal}
                                      </TableCell>
                                      <TableCell>
                                        {order.settlementTime}
                                      </TableCell>

                                      <TableCell textAlign="center">
                                        <Button
                                          type="sub"
                                          size="small"
                                          onClick={() =>
                                            handleOrderDetail(order.id)
                                          }
                                        >
                                          詳細
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                  roleKey={ROLE_KEY.USER_ORDER_HISTORY_BROWSING}
                                />
                              </LayoutBox>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </div>
          {userDetailTab === 0 && <EventHistories />}
          {userDetailTab === 1 && <Chat />}
          {userDetailTab === 2 && <MedicalRecord />}
        </div>
      </div>
      {isOrderDetailDialogOpen && (
        <NLC024
          isOpen={isOrderDetailDialogOpen}
          setIsOpen={setIsOrderDetailDialogOpen}
        />
      )}
    </>
  );
};

export default NLC023;
