import { useState, useEffect } from "react";

import { getMongoDb, getAggregateMic001 } from "../../../utils/query";
import { useRealmApp } from "../../../contexts/RealmApp";
import {
  Collection,
  INIT_SEARCH_CRITERIA,
  SORT_EXAMPLES,
  SEARCH_INFO,
} from "../../../constants/common";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import { useMemoStatusArr } from "../../../utils/utils";
import useStateCustomObj from "../../base/useStateCustomObj";
import {
  closeTableLoading,
  openTableLoading,
} from "../../base/useLoadingTable";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";

type Mic001FetchParams = {
  sortExamples: typeof SORT_EXAMPLES.MIC001;
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.MIC001;
};

const useMic001DbFetch = ({
  sortExamples,
  submittedSearchCriteria,
}: Mic001FetchParams) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  // 病院ID
  const hospitalId: string = currentUser
    ? (currentUser.customData.hospital_id as string)
    : "";

  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [fetchResult, setFetchResult] = useStateCustomObj<Mic001StateType[]>(
    [],
  );
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  const { userId, fixBookDate } = submittedSearchCriteria;

  // 内容のチェック状態
  const contentArray = useMemoStatusArr(
    SEARCH_INFO.MIC001_CONTENT,
    submittedSearchCriteria.content,
  );

  // ステータスのチェック状態
  const statusArray = useMemoStatusArr(
    SEARCH_INFO.MIB001_STATUS,
    submittedSearchCriteria.status,
  );

  // sortを取得 未選択の場合は最終更新日の降順
  const currentSort = sortExamples.find(
    (column) => column.sortDirection !== "",
  );
  const { colName = "fix_book_date_start", sortDirection = "desc" } =
    currentSort || {};

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const mongoDb = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );
        // 1ページ分のデータとデータ総数取得条件
        const aggregate = getAggregateMic001(
          hospitalId,
          userId,
          fixBookDate,
          statusArray,
          contentArray,
          skip,
          perPage,
          colName,
          sortDirection,
        );
        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as AggregateResultAllDataType<Mic001StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setFetchResult(result);
      } catch (err) {
        setTotal(0);
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [
    currentUser,
    skip,
    perPage,
    colName,
    sortDirection,
    setFetchResult,
    fixBookDate,
    statusArray,
    contentArray,
    hospitalId,
    userId,
    forceUpdate.forceUpdateCount,
  ]);

  return {
    fetchResult,
    fetchError,
  };
};

export default useMic001DbFetch;
