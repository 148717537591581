// 共通処理

import { useMemo } from "react";
import {
  MASTER_MANAGEMENT_OPTIONS,
  ADMIN_MAIN_TAB_ITEMS,
  ADMIN_USER_MGMT_SUB_TAB_ITEMS,
  ADMIN_PAYMENT_SUB_TAB_ITEMS,
  MASTER_ITEMS_SUB_TAB_ITEMS,
  ADMIN_ORDERS_TOP_TAB_ITEMS,
  JST_OFFSET_HOURS,
  HOSPITAL_MAIN_TAB_ITEMS,
  HOSPITAL_MASTER_MANAGEMENT_OPTIONS,
  ADMIN_USER_DETAIL_TAB_ITEMS,
  TEST_RESULT_ITEMS,
  MriStatusID,
  MriStatusLabels,
  PetStatusID,
  PetStatusLabels,
  PatientNouKnowStatusLabels,
  PatientNouKnowStatusID,
  InterviewStatusLabels,
  PatientInterviewStatusID,
  ModalityStatusID,
  ModalityStatusLabels,
  AdminsRoleID,
  UserTypeID,
  FORMAT_STYLE,
  YOSE_STATUS,
  YOSE_STATUS_TEXT,
  PatientBrainCheckStatusLabels,
  PatientBrainCheckStatusID,
  HospitalReservationStatusID,
} from "../constants/common";
import {
  navigateRefresh,
  useNavigateWithCurrentState,
} from "../hooks/base/usePageTransitionCustom";
import {
  redirectToInvalidAccessPage,
  redirectToInvalidCsvFormatPage,
  redirectToInvalidFunctionPage,
} from "../contexts/CustomErrorBoundary";

type Checked = {
  checked: boolean;
};

type CsvDataType<T> = {
  headerValue: string; // ヘッダーに表示したい文字列
  valueKey: keyof T; // 表示するデータオブジェクトのkey
};

type Transformer<SourceType, TargetType> = (source: SourceType) => TargetType;

type SelectYearType = {
  value: string;
  label: string;
};
const CSV_DATA_SETTING = {
  NLC002: [
    { headerValue: "ID", valueKey: "id" },
    { headerValue: "ステータス", valueKey: "status" },
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "電話番号", valueKey: "phoneNum" },
    { headerValue: "メールアドレス", valueKey: "email" },
    { headerValue: "申込日", valueKey: "applicationDate" },
  ],
  NLC004: [
    { headerValue: "ID", valueKey: "id" },
    { headerValue: "お客様名", valueKey: "name" },
    {
      headerValue: "最終検査日",
      valueKey: "lastInspectionDate",
      isZeroBase: false,
    },
    { headerValue: "総合点", valueKey: "score" },
    { headerValue: "レポート", valueKey: "reportStatus" },
    { headerValue: "電話番号", valueKey: "phoneNum" },
    { headerValue: "メールアドレス", valueKey: "email" },
  ],
  NLC005: [
    { headerValue: "ID", valueKey: "id" },
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "ステータス", valueKey: "status" },
    { headerValue: "結果", valueKey: "result" },
    {
      headerValue: "MRI予約日",
      valueKey: "appointmentDateMRI",
      isZeroBase: false,
    },
    { headerValue: "医療機関", valueKey: "hospital" },
    { headerValue: "電話番号", valueKey: "phoneNum" },
    { headerValue: "メールアドレス", valueKey: "email" },
  ],
  NLC007: [
    { headerValue: "ID", valueKey: "id" },
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "ステータス", valueKey: "status" },
    { headerValue: "面談予約日", valueKey: "dateAndTime" },
    { headerValue: "電話番号", valueKey: "phoneNum" },
    { headerValue: "メールアドレス", valueKey: "email" },
  ],
  NLC029: [
    { headerValue: "ID", valueKey: "displayUserId" },
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "ステータス", valueKey: "statusStr" },
    { headerValue: "受検期間", valueKey: "examTermStr" },
    { headerValue: "電話番号", valueKey: "tel" },
    { headerValue: "メールアドレス", valueKey: "email" },
  ],
  NLC035: [
    { headerValue: "ID", valueKey: "userId" },
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "タイプ", valueKey: "patientTypeStr" },
    { headerValue: "ステータス", valueKey: "statusStr" },
    { headerValue: "電話番号", valueKey: "tel" },
    { headerValue: "メールアドレス", valueKey: "email" },
  ],
  NLD001: [
    { headerValue: "ID", valueKey: "id" },
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "ステータス", valueKey: "status" },
    { headerValue: "検査", valueKey: "test" },
    { headerValue: "金額", valueKey: "amount" },
    { headerValue: "支払い期日", valueKey: "dueDate" },
    { headerValue: "支払い日", valueKey: "paymentDate" },
    { headerValue: "FDG PET検査日", valueKey: "petScanDate" },
    { headerValue: "電話番号", valueKey: "phoneNum" },
    { headerValue: "メールアドレス", valueKey: "email" },
  ],
  NLD002: [
    { headerValue: "医療機関", valueKey: "hospitalName" },
    { headerValue: "都道府県", valueKey: "prefecture" },
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "項目", valueKey: "examination" },
    { headerValue: "金額", valueKey: "amount" },
    { headerValue: "実施日", valueKey: "examDateStr" },
  ],
  NLD003: [
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "金額", valueKey: "amount" },
    { headerValue: "項目", valueKey: "examination" },
    { headerValue: "実施日", valueKey: "examDateStr" },
  ],
  NLD004: [
    { headerValue: "ID", valueKey: "patientId" },
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "項目", valueKey: "category" },
    { headerValue: "金額", valueKey: "amount" },
    { headerValue: "請求日", valueKey: "authorizationDate" },
    { headerValue: "支払日", valueKey: "paymentDate" },
    { headerValue: "支払状況", valueKey: "statusStr" },
    { headerValue: "電話番号", valueKey: "tel" },
    { headerValue: "メールアドレス", valueKey: "email" },
  ],
  NLD005: [
    { headerValue: "ID", valueKey: "patientId" },
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "項目", valueKey: "category" },
    { headerValue: "合計金額", valueKey: "amount" },
    { headerValue: "請求日", valueKey: "authorizationDate" },
    { headerValue: "発行日時", valueKey: "receiptIssueDate" },
    { headerValue: "発行回数", valueKey: "statusStr" },
  ],
  NLF001: [
    { headerValue: "コード", valueKey: "id" },
    { headerValue: "お客様名", valueKey: "patientName" },
    { headerValue: "ステータス", valueKey: "status" },
    { headerValue: "合計", valueKey: "totalAmount" },
    { headerValue: "更新", valueKey: "renewal" },
    { headerValue: "注文日時", valueKey: "settlementTime" },
    { headerValue: "配送", valueKey: "deliveryMethod" },
  ],
  NLF005: [
    { headerValue: "SKU名", valueKey: "sku_name" },
    { headerValue: "商品名", valueKey: "item_name" },
    { headerValue: "数量", valueKey: "quantity" },
    { headerValue: "金額", valueKey: "sku_amount" },
    { headerValue: "合計", valueKey: "total_amount" },
  ],
  NLF006: [
    { headerValue: "売り上月", valueKey: "paymentDate" },
    { headerValue: "件数", valueKey: "count" },
    { headerValue: "売り上げ", valueKey: "totalAmount" },
  ],
  NLF007: [
    { headerValue: "コード", valueKey: "orderId" },
    { headerValue: "お客様名", valueKey: "patientName" },
    { headerValue: "合計", valueKey: "amount" },
    { headerValue: "注文日時", valueKey: "settlementTime" },
  ],
  NLI003: [
    { headerValue: "ID", valueKey: "userId" },
    { headerValue: "ユーザー名", valueKey: "fullName" },
    { headerValue: "権限", valueKey: "roleStr" },
    { headerValue: "メールアドレス", valueKey: "email" },
    { headerValue: "ステータス", valueKey: "statusCsv" },
  ],
  NLI016: [
    { headerValue: "ID", valueKey: "id" },
    { headerValue: "医療機関", valueKey: "hospitalName" },
    { headerValue: "電話番号", valueKey: "tel" },
    { headerValue: "住所", valueKey: "address" },
    { headerValue: "月", valueKey: "monday" },
    { headerValue: "火", valueKey: "tuesday" },
    { headerValue: "水", valueKey: "wednesday" },
    { headerValue: "木", valueKey: "thursday" },
    { headerValue: "金", valueKey: "friday" },
    { headerValue: "土", valueKey: "saturday" },
    { headerValue: "日", valueKey: "sunday" },
  ],
  NLI021: [
    { headerValue: "ID", valueKey: "id" },
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "ステータス", valueKey: "statusStr" },
    { headerValue: "登録日", valueKey: "activationDate" },
    { headerValue: "電話番号", valueKey: "tel" },
    { headerValue: "メールアドレス", valueKey: "email" },
  ],
};

// 表示値からCSVのデータに変換
const _convertDisplayToCsv = <T extends Checked>(
  userInfoArr: T[],
  csvDataArr: CsvDataType<T>[],
): string[][] => {
  // ヘッダー作成
  const headerArr = csvDataArr.map(({ headerValue }) => headerValue);

  // 表示値作成
  const contentArr = userInfoArr
    .map((userInfo) => {
      const { checked } = userInfo;
      // チェックの付いたもののみCSVに変換
      if (checked) {
        return csvDataArr.map(({ valueKey }) => {
          // 空文字の場合‐を表示
          const contentValue = userInfo[valueKey] || "-";

          if (typeof contentValue === "string") {
            // 先頭0始まりの値は式化して0の削除を回避
            if (contentValue.charAt(0) === "0") return `="${contentValue}"`;
            // ,が含まれる場合は文字列化
            if (contentValue.includes(",")) return `"${contentValue}"`;

            return contentValue;
          }

          return redirectToInvalidFunctionPage();
        });
      }

      return [];
    })
    .filter((data) => data.length);

  return [headerArr, ...contentArr];
};

// csvダウンロード処理
const _downloadCsv = (data: string[][], fileName: string) => {
  const csvContent = `\uFEFF${data.map((row) => row.join(",")).join("\n")}`;

  // Blobオブジェクトを作成
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // ダウンロード可能なURLを作成
  const url = URL.createObjectURL(blob);

  // ダウンロードリンクを作成
  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = `${fileName}.csv`;

  // ダウンロードリンクをクリックしてダウンロードを開始
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

// 配列オブジェクトを引数の型に沿ってフラットにして返す
const _transformArray = <SourceType, TargetType>(
  arr: SourceType[],
  transformer: Transformer<SourceType, TargetType>,
): TargetType[] => arr.map(transformer);

// csvアップロード用エクスプローラ表示 引数のloadFuncにファイル読み取り時のイベントを返す
export const fileUpload = (
  e: React.ChangeEvent<HTMLInputElement>,
  loadFunc: (event: ProgressEvent<FileReader>) => void,
) => {
  const { files } = e.target;
  if (files && files[0]) {
    const reader = new FileReader();

    reader.onload = (event) => {
      loadFunc(event);
    };

    reader.readAsText(files[0]);
  }
};

// ファイルアップロード時のファイルデータをCSVファイル形式に変換
export const convertFileToCsv = (event: ProgressEvent<FileReader>) => {
  const csvStr = event.target?.result;
  if (csvStr && typeof csvStr === "string") {
    return csvStr.split("\n").map((csvDataRowStr) => csvDataRowStr.split(","));
  }
  // csvファイルが不正の場合
  redirectToInvalidCsvFormatPage();

  return "";
};

// ユーザ管理、本人確認(NLC002)画面のcsvダウンロード処理 NLC003でも使用可
export const downloadCsvNlc002 = (
  userInfoArr: UserNlc002TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<UserNlc002TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLC002 as CsvDataType<UserNlc002TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNlc004 = (
  userInfoArr: Nlc004TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nlc004TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLC004 as CsvDataType<Nlc004TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNlc005 = (
  userInfoArr: Nlc005TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nlc005TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLC005 as CsvDataType<Nlc005TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNlc007 = (
  userInfoArr: Nlc007TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nlc007TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLC007 as CsvDataType<Nlc007TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNlc029 = (
  userInfoArr: Nlc029TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nlc029TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLC029 as CsvDataType<Nlc029TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNlc035 = (
  userInfoArr: Nlc035TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nlc035TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLC035 as CsvDataType<Nlc035TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNld001 = (
  userInfoArr: Nld001TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nld001TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLD001 as CsvDataType<Nld001TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNld002 = (
  userInfoArr: Nld002TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nld002TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLD002 as CsvDataType<Nld002TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNld003 = (
  userInfoArr: Nld003TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nld003TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLD003 as CsvDataType<Nld003TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNld004 = (
  userInfoArr: Nld004TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nld004TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLD004 as CsvDataType<Nld004TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNld005 = (
  userInfoArr: Nld005TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nld005TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLD005 as CsvDataType<Nld005TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNlf001 = (
  orderArr: Nlf001TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nlf001TableType>(
    orderArr,
    CSV_DATA_SETTING.NLF001 as CsvDataType<Nlf001TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNlf005 = (
  transactions: TransactionInfoDisplay[],
  fileName: string,
) => {
  // 配列内のオブジェクトをフラットにする関数
  const toFlatFormat: Transformer<
    TransactionInfoDisplay,
    TransactionInfoCsv
  > = (item) => ({
    _id: item._id,
    sku_name: item.sku_name,
    sku_amount: item.sku_amount,
    item_name: item.item_name,
    quantity: item.quantity,
    total_amount: item.total_amount,
    checked: item.checked,
  });
  // toFlatFormatに沿って変換
  const flatTransactions = _transformArray(transactions, toFlatFormat);

  const csvDataArr = _convertDisplayToCsv<TransactionInfoCsv>(
    flatTransactions,
    CSV_DATA_SETTING.NLF005 as CsvDataType<TransactionInfoCsv>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNlf006 = (
  salesArr: Nlf006TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nlf006TableType>(
    salesArr,
    CSV_DATA_SETTING.NLF006 as CsvDataType<Nlf006TableType>[],
  );
  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNlf007 = (
  salesArr: Nlf007TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nlf007TableType>(
    salesArr,
    CSV_DATA_SETTING.NLF007 as CsvDataType<Nlf007TableType>[],
  );
  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNli003 = (
  userArr: Nli003TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nli003TableType>(
    userArr,
    CSV_DATA_SETTING.NLI003 as CsvDataType<Nli003TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNli016 = (
  hospitalArr: Nli016TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nli016TableType>(
    hospitalArr,
    CSV_DATA_SETTING.NLI016 as CsvDataType<Nli016TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNli021 = (
  userInfoArr: Nli021TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nli021TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLI021 as CsvDataType<Nli021TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

// マスタ管理の選択肢クリック時のイベント
export const handleSelectMasterInfo = (selectValue: string) => {
  const selectInfo = MASTER_MANAGEMENT_OPTIONS.find(
    ({ value }) => selectValue === value,
  );
  navigateRefresh(selectInfo?.url);
};

// 医療機関マスタ管理の選択肢クリック時のイベント
export const handleSelectHospitalMasterInfo = (selectValue: string) => {
  const selectInfo = HOSPITAL_MASTER_MANAGEMENT_OPTIONS.find(
    ({ value }) => selectValue === value,
  );
  navigateRefresh(selectInfo?.url);
};

// メインタブクリック時のイベント
export const handleSelectMainTab = (index: number) => {
  const selectInfo = ADMIN_MAIN_TAB_ITEMS[index];
  navigateRefresh(selectInfo?.url);
};

// Adminユーザ詳細のタブクリック時のイベント
export const useHandleSelectAdminUserDetailTab = () => {
  const navigate = useNavigateWithCurrentState();
  const navigateTo = (index: number) => {
    const selectInfo = ADMIN_USER_DETAIL_TAB_ITEMS[index];
    const path = selectInfo?.url;
    if (path) navigate(path);
  };

  return navigateTo;
};

// 医療機関側メインタブクリック時のイベント
export const handleSelectHospitalMainTab = (index: number) => {
  const selectInfo = HOSPITAL_MAIN_TAB_ITEMS[index];
  navigateRefresh(selectInfo?.url);
};

// 医療機関マスタタブクリック時のイベント
export const handleSelectHospitalMasterTab = (index: number) => {
  const selectInfo = HOSPITAL_MASTER_MANAGEMENT_OPTIONS[index];
  navigateRefresh(selectInfo?.url);
};

// タブクリック時の遷移イベント
export const handleSelectTab = (
  selectId: string,
  subTabItems: { id: string; url: string }[],
) => {
  const selectInfo = subTabItems.find(({ id }) => id === selectId);
  navigateRefresh(selectInfo?.url);
};
// ユーザ詳細のタブクリック時の遷移イベント
export const useHandleSelectUserInfoTab = () => {
  const navigate = useNavigateWithCurrentState();
  const navigateTo = (
    selectId: string,
    subTabItems: { id: string; url: string }[],
  ) => {
    const selectInfo = subTabItems.find(({ id }) => id === selectId);
    const path = selectInfo?.url;
    if (path) navigate(path);
  };

  return navigateTo;
};

// ユーザ管理のサブタブクリック時のイベント
export const handleSelectUserMgmtSubTab = (selectId: string) =>
  handleSelectTab(selectId, ADMIN_USER_MGMT_SUB_TAB_ITEMS);
// 支払・請求のサブタブクリック時のイベント
export const handleSelectPaymentSubTab = (selectId: string) =>
  handleSelectTab(selectId, ADMIN_PAYMENT_SUB_TAB_ITEMS);
// マスタ管理のサブタブクリック時のイベント
export const handleSelectMasterMgmtSubTab = (selectId: string) =>
  handleSelectTab(selectId, MASTER_MANAGEMENT_OPTIONS);
// マスタ管理の商品管理のサブタブクリック時のイベント
export const handleSelectMasterMgmtItemsSubTab = (index: number) => {
  const selectInfo = MASTER_ITEMS_SUB_TAB_ITEMS[index];
  navigateRefresh(selectInfo?.url);
};
// EC管理のサブタブクリック時のイベント
export const handleSelectECMgmtSubTab = (selectId: string) =>
  handleSelectTab(selectId, ADMIN_ORDERS_TOP_TAB_ITEMS);

// ユーザ詳細時テスト結果のサブタブクリック時のイベント
export const useHandleTestResultSubTab = () => {
  const navigate = useHandleSelectUserInfoTab();
  const navigateTo = (selectId: string) => {
    navigate(selectId, TEST_RESULT_ITEMS);
  };

  return navigateTo;
};

// 名前検索用に変換 スペース、/を削除
// eslint-disable-next-line no-irregular-whitespace
export const convertSearchName = (name: string) => name.replace(/[ 　/]/g, "");

// 検索欄のチェック状態から選択中のステータスIDを取得
export const useMemoStatusArr = (
  searchInfo: { id: number; flgKey: string }[],
  status: { [x: string]: boolean },
) =>
  useMemo(
    () =>
      searchInfo.flatMap(({ id, flgKey }) =>
        status[flgKey as keyof typeof status] ? [id] : [],
      ),
    [status, searchInfo],
  );

// 検索欄のチェック状態から選択中のステータスIDを取得
export const useMemoUserTypeArr = (
  searchInfo: { id: string; flgKey: string }[],
  status: { [x: string]: boolean },
) =>
  useMemo(
    () =>
      searchInfo.flatMap(({ id, flgKey }) =>
        status[flgKey as keyof typeof status] ? [id] : [],
      ),
    [status, searchInfo],
  );

// クリップボードにテキストをコピー
export const copyClipboard = (text: string) => {
  if (!navigator.clipboard) {
    // eslint-disable-next-line no-alert
    alert("このブラウザは対応していません");

    return;
  }

  navigator.clipboard.writeText(text).then(
    () => {
      // eslint-disable-next-line no-alert
      alert("文章をコピーしました。");
    },
    () => {
      // eslint-disable-next-line no-alert
      alert("コピーに失敗しました。");
    },
  );
};

// URLのパラメータ取得
export const getUrlParams = (target: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  const param = urlParams.get(target);
  if (!param) redirectToInvalidAccessPage();

  return param;
};

// Date型からhh:mmの時間を返す
export const formatHourTime = (date: Date) => {
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
};

// 全角を半角に変換
export const toHalfWidth = (str: string) =>
  str.replace(/[！-～]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0));

// 文字列を数値に変換 全角の数値も適用するため半角に変換後数値化
export const convertStrToNum = (str: string) =>
  Number(toHalfWidth(str).replace(/,/g, ""));

// 入力された文字列が数値化をチェック
export const checkStrArrIsNum = (strArr: string[]) =>
  strArr.every((str) => !Number.isNaN(convertStrToNum(str)));

// 年のセレクトボックスの値をデータから作成
export const extractUniqueYearsFromData = <T>({
  key,
  data,
}: {
  key: keyof T;
  data: T[];
}): SelectYearType[] => {
  const yearsSet = new Set(
    data
      .filter((item) => item[key])
      .map((item) => {
        const jstDate = new Date(item[key] as unknown as string);
        jstDate.setHours(jstDate.getHours() + JST_OFFSET_HOURS);

        return jstDate.getUTCFullYear().toString();
      }),
  );
  // ソート
  const sortedYears = [...yearsSet].sort((a, b) => parseInt(a) - parseInt(b));

  return sortedYears.map((year) => ({
    value: year,
    label: year,
  }));
};

// YYYYMMを+1、-1にしたYYYYMMを返す
export const adjustMonth = (
  yearMonth: string,
  operation: "before" | "after",
): string => {
  let year = parseInt(yearMonth.substring(0, 4), 10);
  let month = parseInt(yearMonth.substring(4, 6), 10);

  switch (operation) {
    case "before":
      month -= 1;
      if (month === 0) {
        year -= 1;
        month = 12;
      }
      break;

    case "after":
      month += 1;
      if (month === 13) {
        year += 1;
        month = 1;
      }
      break;

    default:
      return redirectToInvalidFunctionPage();
  }

  return `${year}${month.toString().padStart(2, "0")}`;
};

// 文字列YYYY/MM/DD HH:MMをDate型に変換
export const parseDateString = (dateString: string): Date | null => {
  const match = /(\d{4})\/(\d{2})\/(\d{2}) (\d{2}):(\d{2})/.exec(dateString);

  if (!match) {
    return null;
  }

  const [_, year, month, day, hour, minute] = match.map(Number);

  return new Date(year, month - 1, day, hour, minute);
};

// 画像URLをbase64に変換する処理
export const convertUrlToBase64 = async (url: string): Promise<string> => {
  const response = await fetch(url);
  const blob = await response.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};
// 画像URL一覧をbase64に変換する処理
export const convertUrlsToBase64 = async (
  urls: string[],
): Promise<string[]> => {
  const base64Promises = urls.map((url) => convertUrlToBase64(url));

  return Promise.all(base64Promises);
};

// 日付と時間を結合する処理
export const combineDateAndTime = (date: Date, time: Date) => {
  const combined = new Date(date);
  combined.setHours(time.getHours());
  combined.setMinutes(time.getMinutes());
  combined.setSeconds(time.getSeconds());
  combined.setMilliseconds(time.getMilliseconds());

  return combined;
};

export const getMriStatusTagState = (status: string) => {
  switch (status) {
    case MriStatusLabels[MriStatusID.DONE_AI_DIAGNOSING]:
      return "default";
    case MriStatusLabels[MriStatusID.RESERVED]:
    case MriStatusLabels[MriStatusID.DONE_AI_DIAGNOSED]:
    case MriStatusLabels[MriStatusID.NO_EXAMINATION]:
      return "error";
    case MriStatusLabels[MriStatusID.DONE_DOCTOR_DIAGNOSED]:
      return "warning";
    default:
      return "neutral";
  }
};

export const getPetStatusTagState = (status: string) => {
  switch (status) {
    case PetStatusLabels[PetStatusID.RESERVED]:
    case PetStatusLabels[PetStatusID.NO_EXAMINATION]:
      return "error";
    case PetStatusLabels[PetStatusID.RESERVATION_CONFIRMED]:
      return "success";
    case PetStatusLabels[PetStatusID.EXAMINED]:
      return "warning";
    default:
      return "neutral";
  }
};

export const getHospitalStatusTagState = (statusNum: number) => {
  switch (statusNum) {
    case HospitalReservationStatusID.NEW:
      return "warning";
    case HospitalReservationStatusID.CONFIRM:
      return "success";
    case HospitalReservationStatusID.WITHDRAWAL:
    case HospitalReservationStatusID.APPLICATION_FOR_WITHDRAWAL:
      return "neutral";
    case HospitalReservationStatusID.INSPECTED:
      return "information";
    case HospitalReservationStatusID.ADMIN_CANCEL:
    case HospitalReservationStatusID.NG:
    case HospitalReservationStatusID.NO_EXAMINATION:
      return "error";
    default:
      return "error";
  }
};

export const getHospitalStatusTagEmphasis = (statusNum: number) => {
  switch (statusNum) {
    case HospitalReservationStatusID.NG:
    case HospitalReservationStatusID.APPLICATION_FOR_WITHDRAWAL:
      return true;
    default:
      return undefined;
  }
};

export const getNouKnowTagState = (status: string) => {
  switch (status) {
    case PatientNouKnowStatusLabels[PatientNouKnowStatusID.PRETEST]:
    case PatientNouKnowStatusLabels[PatientNouKnowStatusID.UPLOADED]:
      return "default";
    case PatientNouKnowStatusLabels[PatientNouKnowStatusID.CONFIRMED]:
      return "success";
    default:
      return "neutral";
  }
};

export const getInterviewTagState = (status: string) => {
  switch (status) {
    case InterviewStatusLabels[PatientInterviewStatusID.NEW_RESERVATION]:
    case ModalityStatusLabels[ModalityStatusID.NEW_RESERVATION]:
      return "error";
    default:
      return "neutral";
  }
};

export const getBrainCheckTagState = (status: string) => {
  switch (status) {
    case PatientBrainCheckStatusLabels[PatientBrainCheckStatusID.NOT_TESTED]:
      return "error";
    case PatientBrainCheckStatusLabels[PatientBrainCheckStatusID.COMPLETED]:
      return "warning";
    case PatientBrainCheckStatusLabels[PatientBrainCheckStatusID.REPORTED]:
      return "success";
    default:
      return "neutral";
  }
};

export const getNouknowColor = (
  rank: string,
): {
  badge: "primary" | "red" | "warning";
  other: "primary" | "error" | "warning";
} => {
  if (rank === "A") {
    return { badge: "primary", other: "primary" };
  }
  if (rank === "B") {
    return { badge: "warning", other: "warning" };
  }
  if (rank === "C") {
    return { badge: "red", other: "error" };
  }

  return { badge: "primary", other: "primary" };
};

export const getAdminRole = (currentUser: Realm.User | null) => {
  const adminRoleObject = currentUser?.customData
    .admin_role as usersNumberIntType;
  const adminRole = adminRoleObject
    ? (Number(adminRoleObject.$numberInt) as AdminsRoleID)
    : null;

  return adminRole;
};

export const getUserType = (currentUser: Realm.User | null) => {
  const userTypeObject = currentUser?.customData
    .user_type as usersNumberIntType;
  const userType = userTypeObject
    ? (Number(userTypeObject.$numberInt) as UserTypeID)
    : null;

  return userType;
};

// 第2引数に3を入力すると3ヶ月後, -2で２か月前のdateを返します。
export const calcMonth = (date = new Date(), number = 0) => {
  const _date = new Date(date.getTime());
  _date.setMonth(date.getMonth() + number);

  return _date;
};

// 第2引数に3を入力すると3日後, -2で2日前のdateを返します。
export const calcDay = (date = new Date(), number = 0) => {
  const _date = new Date(date.getTime());
  _date.setDate(date.getDate() + number);

  return _date;
};

// filledWith0(23, 3)で023, filledWith0(111, 5)で00111を返します。
export const filledWith0 = (targetNumber: number, digit: number) =>
  `${0 * digit}${targetNumber}`.slice(-digit);

// 日付を指定のフォーマットで返します。デフォルトは、YYYY/MM/DDの形
export const formatDate = (
  date = new Date(),
  selectedFormatStyle: ValueOf<typeof FORMAT_STYLE> | undefined = undefined,
) => {
  const month = filledWith0(date.getMonth() + 1, 2);
  const day = filledWith0(date.getDate(), 2);
  const hours = filledWith0(date.getHours(), 2);
  const minutes = filledWith0(date.getMinutes(), 2);

  if (selectedFormatStyle === FORMAT_STYLE.YYYYMMDD) {
    return `${date.getFullYear()}${month}${day}`;
  }

  if (selectedFormatStyle === FORMAT_STYLE["YYYY/MM/DD HH:MM"]) {
    return `${date.getFullYear()}/${month}/${day} ${hours}:${minutes}`;
  }
  if (selectedFormatStyle === FORMAT_STYLE["YYYY-MM-DD"]) {
    return `${date.getFullYear()}-${month}-${day}`;
  }

  return `${date.getFullYear()}/${month}/${day}`;
};

// 時刻基準で年齢を返します。4月2日生まれの人は、4月2日の0時ちょうどに歳をとります。
export const calcAge = (birthDay: Date, baseDate: Date = new Date()) => {
  const age = Math.floor(
    (Number(formatDate(baseDate, FORMAT_STYLE.YYYYMMDD)) -
      Number(formatDate(birthDay, FORMAT_STYLE.YYYYMMDD))) /
      10000,
  );

  return age;
};

/**
 *  yoseのステータスを日付から計算して返します。
 *  実装の都合で、getAggregateNLE001にも同様のロジックがあります。
 */
export const getYoseStatus = (data: {
  streamingDate: Date;
  streamingEndDate: Date;
  newPeriod: Date;
  isSuspend: boolean;
}) => {
  const { streamingDate, streamingEndDate, newPeriod, isSuspend } = data;
  const toDay = new Date();

  // 比較する時間を揃える
  toDay.setHours(0, 0, 0, 0);

  if (isSuspend) return YOSE_STATUS.suspend;

  if (streamingEndDate.getTime() < toDay.getTime())
    return YOSE_STATUS.publicClose;

  if (toDay.getTime() < streamingDate.getTime()) return YOSE_STATUS.preDelivery;

  if (newPeriod.getTime() < toDay.getTime()) return YOSE_STATUS.publicOpen;

  return YOSE_STATUS.newPeriod;
};

/**
 *  寄席のステータスラベルの表示に必要な情報を取得します。
 */
export const getYoseLabelInfo = (status: ValueOf<typeof YOSE_STATUS>) => {
  if (status === YOSE_STATUS.preDelivery) {
    return {
      text: YOSE_STATUS_TEXT.preDelivery,
      btnState: "information",
    } as const;
  }

  if (status === YOSE_STATUS.newPeriod) {
    return { text: YOSE_STATUS_TEXT.newPeriod, btnState: "success" } as const;
  }

  if (status === YOSE_STATUS.publicOpen) {
    return {
      text: YOSE_STATUS_TEXT.publicOpen,
      btnState: "warning",
    } as const;
  }

  if (status === YOSE_STATUS.publicClose) {
    return { text: YOSE_STATUS_TEXT.publicClose, btnState: "error" } as const;
  }

  return { text: YOSE_STATUS_TEXT.suspend, btnState: "neutral" } as const;
};

// 金額変換 ¥表示
export const convertToCurrency = (value: number) => {
  const stringValue = value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `¥${stringValue}`;
};

// 時間から指定した分単位のアイテムを返す
export const generateTimeOptions = (
  startDateTime: Date,
  numberOfOptions: number,
  interval: number,
) => {
  const options = [];
  const currentDate = new Date(startDateTime);

  while (options.length < numberOfOptions) {
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const label = `${hours}:${minutes}`;

    options.push({
      label,
      value: label,
    });

    // 次を追加
    currentDate.setMinutes(currentDate.getMinutes() + interval);
  }

  return options;
};

export const addHyphenToZipcode = (zipcode: string | undefined) => {
  if (!zipcode || zipcode.length !== 7) {
    return zipcode;
  }

  return `${zipcode.slice(0, 3)}-${zipcode.slice(3)}`;
};

export const generateDays = (year: string, month: string) => {
  const daysInMonth = new Date(Number(year), Number(month), 0).getDate();

  return Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString());
};

// MRI,PETの注意事項を含んでいるか
export const isPrecautionsChecked = (index: number, precautions: number[]) => {
  // 過去データに対応
  if (!precautions) return false;

  return precautions.includes(index + 1);
};

// MRI,PETの注意事項の文字カラー
export const getPrecautionsColor = (index: number, precautions: number[]) =>
  isPrecautionsChecked(index, precautions) ? "#096AE2" : "#BFC6CD";

// MRI,PETの注意事項の透過
export const getPrecautionsOpacity = (index: number, precautions: number[]) =>
  isPrecautionsChecked(index, precautions) ? 1 : 0.4;
