import React, { useEffect } from "react";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import FormSet from "../components/FormSet/FormSet";
import Divider from "../components/Divider/Divider";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import useNlc021NewDialog, {
  closeNlc021NewDialog,
} from "../hooks/pages/NLC021/useNlc021NewDialog";
import { useCheckErrorThrowError } from "../utils/checkError";
import useNlc021Conditions from "../hooks/pages/NLC021/useNlc021Conditions";
import {
  convertNlc021,
  convertNlc021CandidateDates,
} from "../utils/convertDisplay";
import {
  formatDate,
  generateTimeOptions,
  getInterviewTagState,
} from "../utils/utils";
import DatePicker from "../components/DatePicker/DatePicker";
import TimePicker from "../components/TimePicker/TimePicker";
import useNlc021UpdateStatus from "../hooks/pages/NLC021/useNlc021UpdateStatus";
import { PatientInterviewStatusID } from "../constants/common";
import useNlc021DoctorFetch from "../hooks/pages/NLC021/useNlc021DoctorFetch";
import Notification from "../components/Notification/Notification";
import useNlc021InterviewFetch from "../hooks/pages/NLC021/useNlc021InterviewFetch";
import useNlc021UpdateReservationConfirmed from "../hooks/pages/NLC021/useNlc021UpdateReservationConfirmed";
import DialogCustom from "../components/Dialog/DialogCustom";
import Dropdown from "../components/Dropdown/Dropdown";
import RadioButton from "../components/RadioButton/RadioButton";
import useNlc021ReissueZoomLink from "../hooks/pages/NLC021/useNlc021ReissueZoomLink";
import useNlc021CancelReservation from "../hooks/pages/NLC021/useNlc021CancelReservation";

// その他の時間制御のため、セット
const initialDate = new Date(1000, 0, 1);

const initSelectDoctorObj = {
  title: "",
  value: "",
  email: "",
  disabled: false,
};

const NLC021 = () => {
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(
    undefined,
  );
  const [timeStr, setTimeStr] = React.useState("");
  const [time, setTime] = React.useState(initialDate);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [selectedTime, setSelectedTime] = React.useState("");
  const [timeItems, setTimeItems] = React.useState<
    { label: string; value: string }[]
  >([]);
  const [selectedDoctorObj, setSelectedDoctorObj] =
    React.useState<AdminDoctorReservation>(initSelectDoctorObj);

  const [isOpen] = useNlc021NewDialog();
  const { fetchError } = useNlc021InterviewFetch();
  const [interviewInfo] = useNlc021Conditions();
  const { handleUpdateStatus, actionError: updateStatusError } =
    useNlc021UpdateStatus();
  const {
    updateReservationConfirmed,
    actionError: reservationConfirmedError,
    fetchSettingsError,
  } = useNlc021UpdateReservationConfirmed();

  const { doctors, doctorFetchError } = useNlc021DoctorFetch(
    selectedValue,
    selectedDate,
    timeStr,
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { reissueZoomLink, actionError: zoomLinkError } =
    useNlc021ReissueZoomLink();

  const { cancelReservation, actionError: cancelError } =
    useNlc021CancelReservation();

  const {
    birth,
    name,
    createdAt,
    gender,
    tel,
    email,
    status,
    statusNum,
    zoomStartLink,
    candidateInterviewDates,
    interviewFixDateStart,
    interviewFixDateStartDate,
    interviewFixDateEndDate,
    doctorUserId,
    doctorName,
    doctorZoomUserId,
  } = convertNlc021(interviewInfo);

  const displayCandidateInterviewDates = convertNlc021CandidateDates(
    interviewInfo.status,
    interviewFixDateStart,
    interviewInfo.candidate_interview_dates,
  );
  const tagState = getInterviewTagState(status);

  // その他の時間
  const otherCandidateDates = [
    {
      label: "その他の時間",
      value: "other",
      disabled:
        (interviewInfo.status ===
          PatientInterviewStatusID.CONFIRMED_RESERVATION ||
          interviewInfo.status === PatientInterviewStatusID.SETTLED) &&
        selectedValue !== "other",
    },
  ];

  // エラー処理
  useCheckErrorThrowError([
    fetchError,
    doctorFetchError,
    reservationConfirmedError,
    updateStatusError,
    fetchSettingsError,
    zoomLinkError,
    cancelError,
  ]);

  useEffect(() => {
    // ラジオボタン、その他の日付の初期設定
    if (
      ![
        PatientInterviewStatusID.CONFIRMED_RESERVATION,
        PatientInterviewStatusID.SETTLED,
      ].includes(statusNum) ||
      !interviewFixDateStart
    ) {
      setSelectedValue(candidateInterviewDates.first || "");

      // 時間選択肢設定
      if (candidateInterviewDates.first) {
        setTimeItems(
          generateTimeOptions(new Date(candidateInterviewDates.first), 4, 15),
        );
      }

      return;
    }

    // 元の候補日の文字列に戻す
    const originSelectDateStr = `${interviewFixDateStart.substring(
      0,
      interviewFixDateStart.length - 2,
    )}00`;
    const isDateInCandidateDates = Object.values(
      candidateInterviewDates,
    ).includes(originSelectDateStr);

    if (isDateInCandidateDates) {
      setSelectedValue(originSelectDateStr);
      setTimeItems(generateTimeOptions(new Date(originSelectDateStr), 4, 15));
      setSelectedTime(interviewFixDateStart.split(" ")[1]);
    } else {
      const [dateStr, timeStr] = interviewFixDateStart.split(" ");
      setSelectedValue("other");
      setSelectedDate(new Date(dateStr));
      setTimeStr(timeStr);
    }
  }, [candidateInterviewDates, interviewFixDateStart, statusNum]);

  // 候補日 ラジオボタン選択
  const handleRadioDateChange = React.useCallback((value: string) => {
    setSelectedValue(value);
    setSelectedDoctorObj(initSelectDoctorObj);
    setSelectedTime("");
    // 候補日 時間選択肢設定
    setTimeItems(generateTimeOptions(new Date(value), 4, 15));
  }, []);

  // 候補日 時間選択
  const handleSelectTime = (value: string) => {
    setSelectedTime(value);
  };

  // その他 日付選択
  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  // キャンセルボタン
  const handleCancel = React.useCallback(() => {
    setSelectedValue("");
    closeNlc021NewDialog();
  }, []);

  // その他 時間選択
  const handleChangeTime = React.useCallback((date: Date) => {
    setTime(date);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    setTimeStr(`${hours}:${minutes}`);
  }, []);

  // 医師ラジオボタン選択
  const handleChangeDoctor = React.useCallback(
    (value: string) => {
      const foundDoctor = doctors.find((doctor) => doctor.value === value);
      if (foundDoctor) {
        setSelectedDoctorObj(foundDoctor);
      }
    },
    [doctors],
  );

  // 予約確定ボタン
  const handleReservationConfirmed = React.useCallback(() => {
    // 登録する予約時間を作成
    let fixDateStartStr = "";
    if (selectedValue === "other") {
      // その他の時間
      const formatDateStr = formatDate(selectedDate);
      fixDateStartStr = `${formatDateStr} ${timeStr}`;
    } else {
      fixDateStartStr = `${selectedValue.split(" ")[0]} ${selectedTime}`;
    }

    updateReservationConfirmed(
      interviewInfo.interviewId,
      selectedDoctorObj,
      fixDateStartStr,
    );
  }, [
    interviewInfo.interviewId,
    updateReservationConfirmed,
    selectedDoctorObj,
    selectedValue,
    selectedDate,
    selectedTime,
    timeStr,
  ]);

  // 予約取消ボタン
  const handleReservationCancel = () => {
    cancelReservation(interviewInfo.interviewId);
  };

  // 面談完了ボタン
  const handleInterviewCompleted = () => {
    handleUpdateStatus(
      interviewInfo.interviewId,
      PatientInterviewStatusID.SETTLED,
    );
  };

  // 予約確定ボタン非活性制御
  const isReservationButtonDisabled = () => {
    if (selectedValue === "other") {
      return !(selectedDate && timeStr && selectedDoctorObj.value);
    }

    return !(selectedValue && selectedTime && selectedDoctorObj.value);
  };

  // 予約前のステータス
  const isBeforeReservation = [
    PatientInterviewStatusID.NEW_RESERVATION,
    PatientInterviewStatusID.CANCELED,
  ].includes(interviewInfo.status);

  // 予約後のステータス
  const isAfterReservation = [
    PatientInterviewStatusID.CONFIRMED_RESERVATION,
  ].includes(interviewInfo.status);

  // 面談済のステータス
  const isInterviewed = [PatientInterviewStatusID.SETTLED].includes(
    interviewInfo.status,
  );

  // ZOOMを開く
  const openZoomMeeting = () => {
    window.open(zoomStartLink, "_blank");
  };

  // ZOOMリンク再発行
  const reissueZoomLinkButton = () => {
    if (
      interviewFixDateStartDate &&
      interviewFixDateEndDate &&
      doctorUserId &&
      doctorName &&
      doctorZoomUserId
    ) {
      reissueZoomLink(
        interviewInfo.interviewId,
        interviewFixDateStartDate,
        interviewFixDateEndDate,
        doctorUserId,
        doctorName,
        doctorZoomUserId,
      );
    }
  };

  return (
    <div className="admin-area">
      <div className="admin-inner">
        <DialogCustom
          title="予約確認"
          open={isOpen}
          size="fullscreen"
          height="920px"
          closeDialog={handleCancel}
          footerRight={
            <LayoutBox>
              <Button
                type="sub"
                color="neutral"
                size="large"
                onClick={handleCancel}
              >
                キャンセル
              </Button>
              {isBeforeReservation && (
                <Button
                  size="large"
                  onClick={handleReservationConfirmed}
                  disabled={isReservationButtonDisabled()}
                >
                  予約確定
                </Button>
              )}
              {isAfterReservation && (
                <Button size="large" onClick={handleInterviewCompleted}>
                  面談完了
                </Button>
              )}
            </LayoutBox>
          }
          footerLeft={
            isBeforeReservation || isAfterReservation ? (
              <Button
                color="danger"
                size="large"
                type="secondary"
                onClick={handleReservationCancel}
              >
                予約取消
              </Button>
            ) : undefined
          }
        >
          <Sheet
            type="border-blue"
            className="util-px-24 util-py-16"
            minWidth="1198px"
          >
            <LayoutBox direction="column" gap="2x">
              <LayoutBox direction="column" gap="1x">
                <Tag state={tagState} label={status} showIcon={false} />
                <Text size="2xl" bold lineHeight="150%">
                  {name}
                </Text>
              </LayoutBox>
              <LayoutBox direction="column" fullWidth>
                <LayoutBox fullWidth>
                  <FormSet label="申込日" labelWidth="120px" base>
                    <Text width="210px">{createdAt}</Text>
                  </FormSet>
                  <FormSet label="内容" labelWidth="120px" base>
                    <Text width="210px">面談</Text>
                  </FormSet>
                </LayoutBox>
                <LayoutBox fullWidth>
                  <FormSet label="生年月日" labelWidth="120px" base>
                    <Text width="210px">{birth}</Text>
                  </FormSet>
                  <FormSet label="性別" labelWidth="120px" base>
                    <Text width="210px">{gender}</Text>
                  </FormSet>
                </LayoutBox>
                <LayoutBox fullWidth>
                  <FormSet label="電話番号" labelWidth="120px" base>
                    <Text width="210px">{tel}</Text>
                  </FormSet>
                  <FormSet label="メールアドレス" labelWidth="120px" base>
                    <Text>{email}</Text>
                  </FormSet>
                </LayoutBox>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
          <div className="util-mt-32 util-full-width">
            <LayoutBox gap="3x">
              <LayoutBox direction="column">
                {displayCandidateInterviewDates?.map((item, index) => (
                  <LayoutBox key={index} direction="column">
                    <RadioButton
                      {...item}
                      name="radio1"
                      onSelect={handleRadioDateChange}
                      checked={selectedValue === item.value}
                      key={`radio1-${item.value}`}
                      withBorder
                      width="367px"
                    >
                      {item.label}
                    </RadioButton>
                    {selectedValue === item.value && (
                      <LayoutBox align="center">
                        {isAfterReservation || isInterviewed ? (
                          <Text>{selectedTime}〜</Text>
                        ) : (
                          <>
                            <Dropdown
                              width="122px"
                              items={timeItems}
                              value={selectedTime}
                              onChange={handleSelectTime}
                            />
                            <Text>〜</Text>
                          </>
                        )}
                      </LayoutBox>
                    )}
                  </LayoutBox>
                ))}
                <div className="util-mt-16">
                  <RadioButtonGroup
                    name="radio"
                    items={otherCandidateDates}
                    onChange={handleRadioDateChange}
                    selectedValue={selectedValue}
                    withBorder
                    width="367px"
                  />
                </div>

                <LayoutBox direction="row" align="center" fullWidth gap="2x">
                  <div>
                    {(isAfterReservation || isInterviewed) &&
                    selectedValue === "other" ? (
                      <Text>{formatDate(selectedDate)}</Text>
                    ) : (
                      <DatePicker
                        size="default"
                        selectedDate={selectedDate}
                        onChangeDate={handleDateChange}
                        disabled={
                          selectedValue !== "other" || isAfterReservation
                        }
                      />
                    )}
                  </div>

                  {(isAfterReservation || isInterviewed) &&
                  selectedValue === "other" ? (
                    <Text>{timeStr}〜</Text>
                  ) : (
                    <TimePicker
                      type="hoursOnly"
                      size="default"
                      defaultHour1="00:00"
                      value={time}
                      onChange={handleChangeTime}
                      showButton={false}
                      dropdownWidth="115px"
                      disabled={selectedValue !== "other" || isAfterReservation}
                    />
                  )}
                </LayoutBox>
              </LayoutBox>
              <Divider vertical width="1px" height="346px" />
              {isBeforeReservation ? (
                <LayoutBox direction="row" fullWidth>
                  <RadioButtonGroup
                    name="radio2"
                    iconItems={doctors}
                    onChange={handleChangeDoctor}
                    selectedValue={selectedDoctorObj.value}
                    withBorder
                    column={5}
                    radioGap="16px"
                    width="100%"
                  />
                </LayoutBox>
              ) : (
                <LayoutBox direction="column" fullWidth gap="3x">
                  <LayoutBox direction="column">
                    <Button
                      icon="open_in_new"
                      iconPosition="right"
                      size="large"
                      onClick={openZoomMeeting}
                    >
                      zoomを開く
                    </Button>
                    <Text>{zoomStartLink}</Text>
                  </LayoutBox>
                  <LayoutBox direction="column">
                    <Notification
                      text="zoomのURLが利用できない場合、リンクを再発行してください。"
                      colorType="warning"
                    />
                    <Button type="sub" onClick={reissueZoomLinkButton}>
                      zoomリンク再発行
                    </Button>
                  </LayoutBox>
                </LayoutBox>
              )}
            </LayoutBox>
          </div>
        </DialogCustom>
      </div>
    </div>
  );
};
export default NLC021;
